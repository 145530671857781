import React from 'react';
import userDefaultAvatar from '../assets/img/placeholder-user.png';
import universityDefaultAvatar from '../assets/img/university_default_avatar.jpeg';
import { ReactComponent as ChevronCheck } from '../assets/svg/icons/check.svg';
import store from '../redux/store';
import { isSsoProspectAllowed } from './SsoHelper';
import { localStorageWrapper, sessionStorageWrapper } from './storageFactory';

import { GROUP_IDS, STR_UNIVERSITY_IDS } from '../common/accountIds';

import { isMobile as isMobileDevice } from '../helpers/DeviceDetect';

export const getStudentYear = (tags) => {
    let year;
    if (!tags.student) return '';
    tags.student.map((tag) => {
        if (tag.name.includes('year')) {
            year = tag;
        }
    });

    return year;
};

export const shuffle = (array) => {
    var currentIndex = array.length;
    var temporaryValue;
    var randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
};

export const generateLink = (type, id, universityOrGroupId, name) => {
    let link = '';
    try {
        const uniGroup = isUniGroup();
        if (sessionStorageWrapper.getItem('parentRef') && window.top !== window.self) {
            const parentRef = sessionStorageWrapper.getItem('parentRef').split('?')[0];
            switch (type) {
                case 'profile':
                    link = parentRef + '?' + 'user_id=' + getIndividualNamePath(name, id);
                    break;
                case 'universityProfile':
                    link =
                        parentRef + '?' + (uniGroup ? 'user_id=university&university_id=' + id : 'user_id=university');
                    break;
                case 'post':
                    link = parentRef + '?' + 'post_id=' + getIndividualNamePath(name, id);
                    break;
                case 'faq':
                    link = parentRef + '?' + 'faq_id=' + getIndividualNamePath(name, id);
                    break;
                case 'chat':
                    link = parentRef + '?' + 'dialog_id=' + id;
                    break;
            }
        } else {
            link = window.location.origin + (uniGroup ? '/group/' : '/') + universityOrGroupId;
            switch (type) {
                case 'profile':
                    link += '/chat-to-us/' + getIndividualNamePath(name, id);
                    break;
                case 'universityProfile':
                    link += uniGroup ? '/chat-to-us/university-data/' + id : '/chat-to-us/university-data';
                    break;
                case 'post':
                    link += '/posts/' + getIndividualNamePath(name, id);
                    break;
                case 'faq':
                    link += '/faq/' + getIndividualNamePath(name, id);
                    break;
                case 'chat':
                    link += '/interaction/dialogs/' + id;
                    break;
            }
        }
    } catch (err) {
        console.log(err);
    }
    return link;
};

export const copyLink = (type, id, universityId, name) => {
    const link = generateLink(type, id, universityId, name);
    try {
        const tempInput = document.createElement('input');
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = link;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        setTimeout(() => {
            const copyLinkPopupElement = document.getElementById('copy-link-popup-block' + id);
            if (copyLinkPopupElement) copyLinkPopupElement.style.display = 'none';
        }, 4000);
        document.getElementById('copy-link-popup-block' + id).style.display = 'flex';
    } catch (err) {
        console.log(err);
    }
};

export const getLinkCopyPopup = (id = '') => {
    return (
        <div id={'copy-link-popup-block' + id} className="copy-link-popup-block">
            <div className="copy-link-popup">
                <ChevronCheck className="chevron-circle-down" />
                <span aria-live="polite">Link copied to clipboard</span>
            </div>
        </div>
    );
};

export const getUniversityIdNew = () => {
    if (window.globalUniversityId) {
        return window.globalUniversityId;
    }

    const universityId = window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1].split('?')[0]
        : '';

    return universityId === 'group' ? null : universityId;
};

export const getUniversityGroupId = () => {
    if (window.globalGroupId) {
        return window.globalGroupId;
    }

    const secondPathName = window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1].split('?')[0]
        : '';

    if (secondPathName !== 'group') {
        return null;
    }

    const uniGroupId = window.location.pathname.split('/')[2]
        ? window.location.pathname.split('/')[2].split('?')[0]
        : '';

    return uniGroupId;
};

export const getUniversityId = () => {
    const isNumeric = /^\d+$/;
    if (window.globalUniversityId) {
        return window.globalUniversityId;
    } else if (window.globalGroupId) {
        return window.globalGroupId;
    }
    const university = window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1].split('?')[0]
        : '';
    let universityId = university;
    if (university === 'group') {
        universityId = window.location.pathname.split('/')[2]
            ? window.location.pathname.split('/')[2].split('?')[0]
            : '';
    }

    return isNumeric.test(universityId) ? universityId : null;
};

export const getGroupUniNameById = (id) => {
    const { universities } = store.getState().widget;

    let name = '';
    for (const uni of universities) {
        if (uni.id === id) {
            name = uni.name;
        }
    }
    return name;
};

export const getGroupUniById = (id) => {
    const { universities } = store.getState().widget;
    return universities.find((item) => item.id === id);
};

export const isUniGroup = () => {
    if (window.globalGroupId) {
        return true;
    }
    const university = window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1].split('?')[0]
        : '';
    return university === 'group';
};

export const getCommonRoutePath = (withGoBack = false) => {
    const { institution } = store.getState().widget;
    const isIframeless = isIframelessVersion();

    const university = window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1].split('?')[0]
        : '';

    let path = '/';
    if (window.globalUniversityId || window.globalGroupId) {
        path = '';
    } else if (university === 'group') {
        path +=
            'group/' +
            (window.location.pathname.split('/')[2] ? window.location.pathname.split('/')[2].split('?')[0] : '');
    } else if (isIframeless) {
        path += `${university}/${institution.slug}`;
    } else {
        path += university;
    }
    // two choices here: 1) to get as above window.location.pathname.split("/")[3].split("?")[0] or through router-dom
    // session will be removed coz not reliable
    path += sessionStorageWrapper.getItem('isCarousel') ? '/carousel' : '';

    if (withGoBack && sessionStorageWrapper.getItem('goBack')) {
        path += sessionStorageWrapper.getItem('goBack');
    }

    return path;
};

export const getIndividualNamePath = (name, id) => {
    let path = id;
    try {
        const nameOf = name
            ? name
                  .replaceAll(/[^'\p{L}\p{N}\p{Zs}\p{Pc}-]+/gu, '')
                  .trim()
                  .split(/(.{37}\S*)\s/)
                  .filter(function (e) {
                      return e;
                  })[0]
                  .replaceAll(/\s+/g, '-')
                  .toLowerCase()
            : '';
        if (nameOf && nameOf.length) {
            path = nameOf + '-' + path;
        }
    } catch (err) {
        // TODO: handle error?
    }
    return path;
};

export const onUserImgError = (e) => {
    e.target.onerror = null;
    e.target.src = userDefaultAvatar;
};
export const onUniImgError = (e) => {
    e.target.onerror = null;
    e.target.src = universityDefaultAvatar;
};

export const isInIframe = window.top !== window.self;

export const isIframelessVersion = () => {
    const { institution } = store.getState().widget;
    return !!(!isInIframe && institution && institution.slug);
};

export const randomAvatarColors = ['#464d60', '#4f608f', '#c44c4c', '#1fa14f', '#824ca1'];

export const getCurrentColor = (publisher, index, userId) => {
    const usersIds = [];
    let currentUserColor = '#4f608f';
    let usersColors = [];
    if (localStorageWrapper.getItem('usersColors')) {
        usersColors = JSON.parse(localStorageWrapper.getItem('usersColors'));
        for (const user of usersColors) {
            usersIds.push(user.user_id);
        }
    }
    if (!publisher && !userId) {
        return currentUserColor;
    }
    const user = userId ? { id: userId } : publisher;
    if (!user.avatar && !usersIds.includes(user.id)) {
        currentUserColor =
            randomAvatarColors.length - 1 > index
                ? randomAvatarColors[index]
                : randomAvatarColors[Math.floor(Math.random() * randomAvatarColors.length)];
        usersColors.push({ user_id: user.id, color: currentUserColor });
        localStorageWrapper.setItem('usersColors', JSON.stringify(usersColors));
    } else if (!user.avatar) {
        for (const color of usersColors) {
            if (color.user_id === user.id) {
                currentUserColor = color.color;
                break;
            }
        }
    }
    return currentUserColor;
};

export const isSafariBrowser = () => {
    return (
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1
    );
};

export const isChromeBrowser = () => {
    return navigator.userAgent.match(/chrome|chromium|crios/i);
};

export const isIos = () => {
    return navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
};

export const isIosOrAndroid = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') return false;

    return /Android|webOS|iPhone|iPad|iPod|Opera Mini|windows phone/i.test(
        navigator.userAgent || navigator.vendor || (window.opera && window.opera.toString() === '[object Opera]')
    );
};

export const trackGetStarted = (university, startedWith, isGroupChat) => {
    try {
        const props = {
            university_id: university,
            'Get started from': startedWith,
        };
        if (isGroupChat) {
            props['Get started place'] = 'Group chat';
            props.THE = isGlobalVersionTwo;
        } else if (localStorageWrapper.getItem('cameFromData')) {
            const cameFromData = JSON.parse(localStorageWrapper.getItem('cameFromData'));
            let place = '';
            if (cameFromData.type === 'profile') {
                place = cameFromData.from === 'page' ? 'Profile page' : 'Profile card';
            } else if (cameFromData.type === 'post') {
                place = 'Posts';
            } else if (cameFromData.type === 'faq') {
                place = 'FAQs';
            }

            props['Get started place'] = place;
            props.THE = isGlobalVersionTwo;
        }
    } catch (err) {
        console.log(err);
    }
};

export const showFlag = () => {
    return isAllowed('display_ambassador_country_flag');
};

export const isEqualArrays = (a, b) => {
    if (a.length !== b.length) {
        return false;
    } else {
        for (var i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }
};

export const statuses = {
    speakersChat: 5,
    liveChat: 6,
    eventInProgress: 'inProgress',
};

export const isGlobalVersionTwo = window.globalTapVersion === 2;

// @todo https://taplatform.atlassian.net/browse/TAP-4908 - need to remove check for prospect role, as we don't have this props in messaeg
// or just add role prop on Back-end side
export const prepareRoleName = (user) => {
    if (user?.role === 'prospect') {
        return `Participant ${
            user.nationality && user.nationality.country ? 'from ' + user.nationality.country.name : ''
        }`;
    }
    return user.profileType ? user.profileType : user.roles?.[0]?.name;
};

export const getABVersion = () => {
    const abTests = store.getState().widget.ABTesting;
    return abTests.ABTEST_1 && abTests.ABTEST_1.bucket ? 'B' : 'A';
};

export const IsMobile = () => {
    const { isMobile } = store.getState().widget;
    return isMobile;
};

export const getUniversityData = (universityId) => {
    const { institution, universitiesById } = store.getState().widget;

    return institution ? institution : universitiesById ? universitiesById[universityId] : null;
};

export const isAllowed = (type) => {
    try {
        const { widget } = store.getState();
        const permission = widget.permissions.find((item) => item.key === type);
        return permission && permission.isAllowed;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const isAllowedAuthType = (type) => {
    try {
        const { widget } = store.getState();
        const permission = widget.permissions.find((item) => item.key === type);
        return permission && permission.isAllowed;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export const redirectToAmbassadorProfile = ({ userId, navigate }) => {
    const isIframeless = isIframelessVersion();
    const isMobile = IsMobile();

    sessionStorageWrapper.removeItem('activePage');

    if (!isIframeless || (isMobile && !isIframeless)) {
        window.open(`${getCommonRoutePath()}/chat-to-us/${userId}`, '_blank');
    } else {
        navigate(`${getCommonRoutePath()}/chat-to-us/${userId}`);
    }
};

export const prepareAuthRadioButtons = () => {
    const isMobile = IsMobile();
    const showFacebook = isAllowedAuthType('facebook');
    const showGoogle = isAllowedAuthType('google');
    const showTiktok = isAllowedAuthType('tiktok');
    const showSso = isSsoProspectAllowed();

    if (!isMobile) {
        return 5;
    }

    if (showGoogle) {
        return 1;
    } else if (showFacebook) {
        return 2;
    } else if (showSso) {
        return 3;
    } else if (showTiktok) {
        return 4;
    } else if (!showSso && !showFacebook && !showGoogle && !showTiktok) {
        return 5;
    }
    return 1;
};

export const isLoginButtonHidden = () => {
    const universityId = getUniversityIdNew();
    switch (universityId) {
        case STR_UNIVERSITY_IDS.CYCLOPES: // Cyclopes
        case STR_UNIVERSITY_IDS.TEST_UNI: // Test Uni
        case STR_UNIVERSITY_IDS.USYD: // USYD Uni
            return true;
        default:
            return false;
    }
};

export const isHiddenGroupCarouselUniLogo = () => {
    const groupId = getUniversityGroupId();
    const isCarousel = window.location.href.includes('carousel');
    if (isCarousel) {
        switch (groupId) {
            case GROUP_IDS.KAPLAN: // Kaplan
                return true;
            default:
                return false;
        }
    }
    return false;
};

export const useChristmasMessage = () => {
    const universityId = getUniversityIdNew();
    switch (universityId) {
        case STR_UNIVERSITY_IDS.VUW:
        case STR_UNIVERSITY_IDS.TEST_UNI:
            return false;
        default:
            return true;
    }
};

export const extractIdFromUrlState = (paramName) => {
    const urlParams = new URL(window.location.href).searchParams;
    const state = urlParams.get('state');
    const paramValue = state && state.includes(`${paramName}=`) ? state.split(`${paramName}=`)[1].split('__')[0] : null;
    return paramValue || urlParams.get(paramName) || null;
};

export const isSASCGroupAccount = () => {
    const groupId = getUniversityGroupId();
    switch (groupId) {
        case GROUP_IDS.SASC: // SASC group
        case GROUP_IDS.TEST_GROUP: // Test group
            return true;
        default:
            return false;
    }
};

export const generatePassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=<>?';
    let password = '';
    for (let i = 0; i < 7; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
    }
    return password;
};

export const isMobileExperiment = () => {
    const groupId = getUniversityGroupId();
    const universityId = getUniversityIdNew();

    const isNotCarousel = !window.location.href.includes('carousel');
    const isNotExcludedGroup = ![GROUP_IDS.SUTTON_TRUST, GROUP_IDS.THE].includes(groupId);
    const isNotExcludedUniversity = ![
        STR_UNIVERSITY_IDS.CYCLOPES,
        STR_UNIVERSITY_IDS.USYD,
        STR_UNIVERSITY_IDS.TEST_UNI,
    ].includes(universityId);

    return isMobileDevice && isNotCarousel && isNotExcludedGroup && isNotExcludedUniversity;
};
