export const FAQS_INFO = 'FAQS_INFO';
export const FAQS_INFO_SUCCESS = 'FAQS_INFO_SUCCESS';
export const FAQS_INFO_FAIL = 'FAQS_INFO_FAIL';

export const REFRESH_FAQS_DATA = 'REFRESH_FAQS_DATA';

export const FAQS_ANSWER_LIKED = 'FAQS_ANSWER_LIKED';
export const FAQS_ANSWER_LIKED_SUCCESS = 'FAQS_ANSWER_LIKED_SUCCESS';

export const FAQS_SHARED = 'FAQS_SHARED';

export const LOAD_NEXT_FAQS = 'LOAD_NEXT_FAQS';
export const LOAD_NEXT_FAQS_SUCCESS = 'LOAD_NEXT_FAQS_SUCCESS';
