import { EVENT_STREAM } from '../../actions/event-stream/event-stream-action-types';

const initialState = {
    joined: false,
    joinInProgress: false,
    joinModalOpen: false,
    audioDevices: [],
    videoDevices: [],
    settings: {
        audioMuted: false,
        videoMuted: false,
        streamAudioMuted: false,
    },
    currentParticipant: {
        id: undefined,
        name: undefined,
        avatar: null,
        isAdminSpeaker: false,
    },
    participants: [],
    localScreenShared: false,
    currentPresenterId: undefined,
    chatViewDisplayed: false,
};

const muteLocalMedia = (state, payload, { audio, video }) => {
    const { muted } = payload;
    return {
        ...state,
        settings: {
            ...state.settings,
            audioMuted: audio ? muted : state.settings.audioMuted,
            videoMuted: video ? muted : state.settings.videoMuted,
        },
        participants: state.participants.map((participant) => {
            if (participant.user.id === state.currentParticipant.id) {
                return {
                    ...participant,
                    audioMuted: audio ? muted : participant.audioMuted,
                    videoMuted: video ? muted : participant.videoMuted,
                };
            }
            return participant;
        }),
    };
};

const addParticipant = (state, payload) => {
    if (state.participants.some(({ id }) => id === payload.id)) {
        return state.participants;
    }
    return [...state.participants, payload];
};

const setMedia = (state, payload) => {
    const {
        participantId,
        media,
        config: { videoMuted, audioMuted, loading },
    } = payload;
    return state.participants.map((participant) => {
        if (participant.id === participantId) {
            return {
                ...participant,
                media,
                view: media.getView(),
                videoMuted,
                audioMuted,
                loading,
            };
        }
        return participant;
    });
};

const setScreenMedia = (state, payload) => {
    const { participantId, media, config } = payload;
    const { remove } = config;
    return {
        ...state,
        participants: state.participants.map((participant) => {
            if (participant.id === participantId) {
                return {
                    ...participant,
                    screenMedia: remove ? null : media,
                    screenView: remove ? null : media.getView(),
                };
            }
            return participant;
        }),
        currentPresenterId: remove ? undefined : participantId,
    };
};

const removeParticipant = (state, payload) => {
    const { participantId, isScreenShare } = payload;
    return isScreenShare
        ? state.participants.map((participant) => {
              if (participant.id === participantId) {
                  return {
                      ...participant,
                      screenMedia: null,
                      screenView: null,
                  };
              }
              return participant;
          })
        : state.participants.filter((participant) => {
              return participant.id !== participantId;
          });
};

const setRemoteMuted = (state, payload, { video, audio }) => {
    const { participantId, isMuted } = payload;
    return state.participants.map((participant) => {
        if (participant.id === participantId) {
            return {
                ...participant,
                audioMuted: audio ? isMuted : participant.audioMuted,
                videoMuted: video ? isMuted : participant.videoMuted,
            };
        }
        return participant;
    });
};

export const eventStreamReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EVENT_STREAM.OPEN_JOIN_MODAL: {
            return {
                ...state,
                joinModalOpen: true,
            };
        }
        case EVENT_STREAM.CLOSE_JOIN_MODAL: {
            return {
                ...state,
                joinModalOpen: false,
            };
        }
        case EVENT_STREAM.MUTE_LOCAL_AUDIO: {
            return muteLocalMedia(state, payload, { audio: true });
        }
        case EVENT_STREAM.MUTE_LOCAL_VIDEO: {
            return muteLocalMedia(state, payload, { video: true });
        }
        case EVENT_STREAM.SET_AUDIO_DEVICES: {
            return {
                ...state,
                audioDevices: payload.devices,
            };
        }
        case EVENT_STREAM.SET_VIDEO_DEVICES: {
            return {
                ...state,
                videoDevices: payload.devices,
            };
        }
        case EVENT_STREAM.SET_JOIN_IN_PROGRESS: {
            return {
                ...state,
                joinInProgress: payload.joinInProgress,
            };
        }
        case EVENT_STREAM.SET_CURRENT_PARTICIPANT: {
            return {
                ...state,
                currentParticipant: payload,
            };
        }
        case EVENT_STREAM.ADD_PARTICIPANT: {
            return {
                ...state,
                participants: addParticipant(state, payload),
            };
        }
        case EVENT_STREAM.SET_JOINED: {
            return {
                ...state,
                joined: payload.joined,
            };
        }
        case EVENT_STREAM.SET_MEDIA: {
            return {
                ...state,
                participants: setMedia(state, payload),
            };
        }
        case EVENT_STREAM.REMOVE_PARTICIPANT: {
            const updatedParticipants = removeParticipant(state, payload);
            return {
                ...state,
                participants: updatedParticipants,
                currentPresenterId: payload.isScreenShare ? undefined : state.currentPresenterId,
            };
        }
        case EVENT_STREAM.SET_REMOTE_VIDEO_MUTED: {
            return {
                ...state,
                participants: setRemoteMuted(state, payload, { video: true }),
            };
        }
        case EVENT_STREAM.SET_REMOTE_AUDIO_MUTED: {
            return {
                ...state,
                participants: setRemoteMuted(state, payload, { audio: true }),
            };
        }
        case EVENT_STREAM.RESET: {
            return {
                ...initialState,
                currentParticipant: state.currentParticipant,
            };
        }
        case EVENT_STREAM.SET_SCREEN_MEDIA: {
            return setScreenMedia(state, payload);
        }
        case EVENT_STREAM.SET_LOCAL_SCREEN_SHARED: {
            return {
                ...state,
                localScreenShared: payload.isShared,
            };
        }
        case EVENT_STREAM.SET_STREAM_AUDIO_MUTED: {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    streamAudioMuted: payload.isMuted,
                },
            };
        }
        case EVENT_STREAM.SET_CHAT_VIEW_DISPLAYED: {
            return {
                ...state,
                chatViewDisplayed: payload.isDisplayed,
            };
        }

        default:
            return state;
    }
};
