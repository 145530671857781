import API from '../../../api';
import { call, put, select, delay } from 'redux-saga/effects';
import {
    FORUM_CHATS_INFO_SUCCESS,
    GET_FORUM_MESSAGES_SUCCESS,
    LOAD_NEXT_FORUM_CHATS_SUCCESS,
    REFRESH_FORUM_CHATS_DATA,
} from '../../actions/forumChatsActions';
import { SHOW_DATA } from '../../actions/mainActions';
import {
    LOAD_NEW_MESSAGES_SUCCESS,
    LOAD_OLD_MESSAGES_SUCCESS,
    MESSAGES_INFO_FAILED,
    MESSAGES_INFO_SUCCESS,
} from '../../actions/messagesActions';
import { CHAT_INFO } from '../../actions/currentChatActions';
import { RESET_DIALOG_NEW_MESSAGES } from '../../actions/dialogsActions';
import { NEW_MESSAGES_COUNTER } from '../../actions/chatBoxActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getSearchType = (state) => state.widget.searchType;

export function* forumChatsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.groupChats.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                [],
                payload.limit,
                payload.offset,
                true,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: FORUM_CHATS_INFO_SUCCESS,
                payload: {
                    forumChats: response.data.groupChats,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });

            yield put({
                type: REFRESH_FORUM_CHATS_DATA,
                payload: false,
            });

            yield delay(60000);

            yield put({
                type: REFRESH_FORUM_CHATS_DATA,
                payload: true,
            });

            if (response.data.groupChats.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showForum',
                    },
                });
            }
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* loadNextForumChatsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.groupChats.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                payload.ids,
                payload.limit,
                payload.offset,
                true,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_FORUM_CHATS_SUCCESS,
                payload: {
                    forumChats: response.data.groupChats,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* getForumMessages({ payload }) {
    try {
        let response = yield call(() => {
            return API.messages.getGroupMessages(payload.dialog_id, null, null, payload.limit);
        });

        if (response.success && response.data && response.data.messages) {
            yield put({
                type: GET_FORUM_MESSAGES_SUCCESS,
                payload: {
                    forumChatId: payload.dialog_id,
                    messages: response.data.messages,
                    currentChatOpenedId: payload.currentChatOpenedId,
                },
            });

            // here for now, we must renew our counter in the header, which available only for prospects for now
            /* if (accountInfo && accountInfo.key === "prospect" && !payload.noResetForNewMessages) {
                yield put({
                    type: NEW_MESSAGES_COUNTER,
                });
            } */
        }
    } catch (e) {
        console.log(e);
    }
}
