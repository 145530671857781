import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    CLOSE_ACTION_MODAL_CHILD,
    OPEN_ACTION_MODAL_CHILD,
} from '../../actions/componentsActions';

const initialState = {
    title: null,
    body: null,
    children: null,
    isOpened: false,
    withChild: {
        children: null,
        isOpened: false,
    },
    styles: {
        top: 0,
    },
    bodyStyles: {},
    positionAbsolute: false,
    class: null,
    openOnMaskClick: true,
    closable: false,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case CLOSE_ACTION_MODAL: {
            return initialState;
        }

        case CLOSE_ACTION_MODAL_CHILD: {
            return {
                ...initialState,
                withChild: {
                    ...initialState.withChild,
                    isOpened: false,
                    children: null,
                },
            };
        }

        case OPEN_ACTION_MODAL: {
            let new_state = Object.assign({}, state);
            new_state.isOpened = true;
            new_state.title = payload.title;
            new_state.body = payload.body;
            new_state.children = payload.children;
            new_state.class = payload.class || null;
            return new_state;
        }

        case OPEN_ACTION_MODAL_CHILD: {
            const {
                children,
                styles,
                bodyStyles,
                class: className,
                positionAbsolute,
                openOnMaskClick,
                closable,
            } = payload;

            return {
                ...initialState,
                withChild: {
                    ...initialState.withChild,
                    isOpened: true,
                    children: children,
                },
                styles: {
                    ...initialState.styles,
                    top: styles && styles.top ? styles.top : 0,
                },
                bodyStyles: bodyStyles || {},
                class: className || null,
                positionAbsolute: !!positionAbsolute,
                openOnMaskClick: !openOnMaskClick,
                closable: !!closable,
            };
        }

        default: {
            return state;
        }
    }
}
