import API from '../../../api';
import { call, put } from 'redux-saga/effects';
import { USERS_INFO_SUCCESS, USERS_INFO_FAILED } from '../../actions/usersActions';

export function* usersInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            if (payload.type === 'admins') {
                return API.users.admins(payload.search, payload.dialogId);
            } else {
                return API.users.ambassadors(payload.search);
            }
        });

        if (response.success) {
            yield put({
                type: USERS_INFO_SUCCESS,
                payload: {
                    items: response.data.users,
                },
            });
        } else {
            yield put({
                type: USERS_INFO_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: USERS_INFO_FAILED,
        });
    }
}
