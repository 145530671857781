import {
    CLEAR_FILTER_TERMS,
    REMOVE_FILTER_TERM,
    RETURN_PREV_FILTERS,
    SET_FILTER_TERM,
    MATCH_ME_FILTERS,
} from '../actions/filtersActions';

const initialState = {
    fixed: [],
    custom: [],
    customFilters: [], // Ready for search
    preparedFilters: [], // Without group_universities and university_countries (group_universities filters by sending universityIds, university_countries by sending groupCountryIds)
    preparedFiltersIds: [],
    groupCountryId: null,
    matchMe: false,
    previousData: {
        fixed: [],
        custom: [],
        customFilters: [],
        preparedFilters: [],
        preparedFiltersIds: [],
        groupCountryId: null,
    },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_TERM: {
            const { fixed, custom } = { ...state };
            const customFilters = [];
            let payloadItem = payload.item;

            if (payloadItem.type === 'fixed') {
                if (payloadItem.type && payloadItem.key && payloadItem.id && payloadItem.name) {
                    let found = false;
                    for (const term in fixed) {
                        if (fixed[term].key === payloadItem.key) {
                            found = true;
                            fixed[term].id = payloadItem.id;
                            fixed[term].name = payloadItem.name;
                            fixed[term].partnerUniIds = payloadItem.partnerUniIds;
                        }
                    }
                    if (!found) {
                        fixed.push({
                            key: payloadItem.key,
                            id: payloadItem.id,
                            name: payloadItem.name,
                            partnerUniIds: payloadItem.partnerUniIds,
                        });
                    }
                }
            } else if (payloadItem.type && payloadItem.key && payloadItem.name) {
                let found = false;
                for (const term in custom) {
                    if (custom[term].key === payloadItem.key) {
                        found = true;
                        custom[term].name = payloadItem.name;
                    }
                }
                if (!found) {
                    custom.push({
                        key: payloadItem.key,
                        name: payloadItem.name,
                    });
                }
            }
            for (const key of custom) {
                customFilters.push(key.name);
            }

            const preparedFilters = [];
            const preparedFiltersIds = [];
            let groupCountryId = null;
            const countryIds = [];
            const profileTypeIds = [];
            const subjectIds = [];

            for (const filterTerm of fixed) {
                if (filterTerm.key === 'university_countries') {
                    groupCountryId = filterTerm.id;
                } else if (filterTerm.key === '_countries') {
                    countryIds.push(filterTerm.id);
                } else if (filterTerm.key === 'account_type') {
                    profileTypeIds.push(filterTerm.id);
                } else if (filterTerm.key === '_subject') {
                    subjectIds.push(filterTerm.id);
                } else if (filterTerm.key !== 'group_universities') {
                    preparedFilters.push(filterTerm);
                    preparedFiltersIds.push(filterTerm.id);
                }
            }

            return {
                ...state,
                fixed,
                custom,
                customFilters,
                preparedFilters,
                preparedFiltersIds,
                groupCountryId,
                countryIds,
                profileTypeIds,
                subjectIds,
            };
        }

        case REMOVE_FILTER_TERM: {
            const filterTerms = { ...state };
            const name = payload.name;

            const fixedTerms = [];
            for (const term of filterTerms.fixed) {
                if (name.toLowerCase() !== term.name.toLowerCase()) {
                    fixedTerms.push(term);
                }
            }

            const customTerms = [];
            for (const term of filterTerms.custom) {
                if (name.toLowerCase() !== term.name.toLowerCase()) {
                    customTerms.push(term);
                }
            }

            const customSearchTerms = [];
            for (let term of customTerms) {
                customSearchTerms.push(term.name);
            }

            const preparedToFilters = [];
            const preparedFilterIds = [];
            let groupCountryId = null;
            const countryIds = [];
            const profileTypeIds = [];
            const subjectIds = [];
            for (const filterTerm of fixedTerms) {
                if (filterTerm.key === 'university_countries') {
                    groupCountryId = filterTerm.id;
                } else if (filterTerm.key === '_countries') {
                    countryIds.push(filterTerm.id);
                } else if (filterTerm.key === 'account_type') {
                    profileTypeIds.push(filterTerm.id);
                } else if (filterTerm.key === '_subject') {
                    subjectIds.push(filterTerm.id);
                } else if (filterTerm.key !== 'group_universities') {
                    preparedToFilters.push(filterTerm);
                    preparedFilterIds.push(filterTerm.id);
                }
            }

            return {
                ...state,
                fixed: fixedTerms,
                custom: customTerms,
                customFilters: customSearchTerms,
                preparedFilters: preparedToFilters,
                preparedFiltersIds: preparedFilterIds,
                groupCountryId,
                countryIds,
                profileTypeIds,
                subjectIds,
            };
        }

        case MATCH_ME_FILTERS: {
            const fixed = [...state.fixed];
            const custom = [...state.custom];
            const customFilters = [...state.customFilters];
            const preparedFilters = [...state.preparedFilters];
            const preparedFiltersIds = [...state.preparedFiltersIds];
            const { groupCountryId } = { ...state };
            return {
                ...state,
                matchMe: !payload || !payload.matchMeOpened,
                previousData: {
                    fixed,
                    custom,
                    customFilters,
                    preparedFilters,
                    preparedFiltersIds,
                    groupCountryId,
                },
            };
        }

        case RETURN_PREV_FILTERS: {
            const fixed = [...state.previousData.fixed];
            const custom = [...state.previousData.custom];
            const customFilters = [...state.previousData.customFilters];
            const preparedFilters = [...state.previousData.preparedFilters];
            const preparedFiltersIds = [...state.previousData.preparedFiltersIds];
            const { groupCountryId } = { ...state.previousData };

            return {
                ...state,
                fixed,
                custom,
                customFilters,
                preparedFilters,
                preparedFiltersIds,
                groupCountryId,
                matchMe: false,
            };
        }

        case CLEAR_FILTER_TERMS:
            return {
                ...state,
                fixed: [],
                custom: [],
                customFilters: [],
                preparedFilters: [],
                preparedFiltersIds: [],
                countryIds: [],
                profileTypeIds: [],
                subjectIds: [],
                groupCountryId: null,
                matchMe: false,
                previousData: {
                    fixed: [],
                    custom: [],
                    customFilters: [],
                    preparedFilters: [],
                    preparedFiltersIds: [],
                    groupCountryId: null,
                },
            };

        default:
            return state;
    }
}
