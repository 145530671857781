import HttpService from '../../system/HttpServices';

export default {
    userInfo: (student_id) => {
        return HttpService.get({ path: '/users/' + student_id });
    },
    ambassadors: (search) => {
        return HttpService.get({
            path: '/users/type/ambassadors',
            data: { search },
            currentToken: true,
            requestType: 'interaction',
        });
    },
    admins: (search, dialogId) => {
        return HttpService.get({
            path: '/users/type/admins',
            data: { search, dialogId },
            currentToken: true,
            requestType: 'interaction',
        });
    },
};
