export const GROUP_IDS = {
    KAPLAN: '59',
    SASC: '132',
    TEST_GROUP: '301',
    QEEENS_UNI_BELFAST: '37',
    SUTTON_TRUST: '2',
    THE: '40',
};

export const STR_UNIVERSITY_IDS = {
    CYCLOPES: '1474',
    VUW: '545',
    USYD: '528',
    TEST_UNI: '100061', // Staging test university (Man City)
};

export const UNIVERSITY_IDS = {
    CYCLOPES: 1474,
    TEST_UNI: 100061,
    TEST_UNI_2: 104759,
    TEST_UNI_3: 103859,
    INTAKE: 404,
    GRIFFITH: 161,
    UST_SYDNEY: 189,
    DOWNING: 388,
    DEAKIN: 896,
    SHEFELD_HALLAM: 1976,
};
