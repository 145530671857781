var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { tryParseJSON } from '../common/utils/jsonUtils';
import { getCommonRoutePath, getUniversityGroupId, getUniversityIdNew } from './Helpers';
import { openWindow } from './SsoHelper';
import { NavigateWrapper } from './navigateWrapper';
import { REGISTRATION, getUniversitySsoData } from '../redux/actions/accountActions';
import { OPEN_ACTION_MODAL } from '../redux/actions/componentsActions';
import store from '../redux/store';
import { localStorageWrapper } from './storageFactory';
export var isSingleSsoAuth = function () {
    var universityGroupId = getUniversityGroupId();
    var SINGLE_AUTH_GROUP_IDS = new Set([
        '2', // Sutton Trust Group
    ]);
    return SINGLE_AUTH_GROUP_IDS.has(universityGroupId);
};
export var openSingleSsoWindow = function (universitySsoData) {
    var universityId = getUniversityIdNew();
    var universityGroupId = getUniversityGroupId();
    var ssoConfig = {
        isSsoNew: true,
        redirectToUniversity: false,
    };
    if (universityId && !isNaN(universityId)) {
        ssoConfig.universityId = universityId;
    }
    else if (universityGroupId && !isNaN(universityId)) {
        ssoConfig.universityGroupId = universityGroupId;
    }
    localStorageWrapper.setItem('ssoSignInData', JSON.stringify(ssoConfig));
    var onSuccess = function (appDomain, appClientId, isEncryptionOn) {
        if (!appClientId) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'No SSO provider found for your university',
                },
            });
        }
        else {
            openWindow(appDomain, appClientId, registerSsoUser, ssoConfig.redirectToUniversity, handleLoginSuccess, isEncryptionOn, universitySsoData);
        }
    };
    store.dispatch(getUniversitySsoData(ssoConfig.email, ssoConfig.universityId, onSuccess));
    return false;
};
var registerSsoUser = function () {
    // Get universitySsoData data
    var universitySsoData = tryParseJSON(localStorageWrapper.getItem('universitySsoData'));
    if (universitySsoData) {
        var id = universitySsoData.id, type = universitySsoData.type, university = universitySsoData.university, ssoData = __rest(universitySsoData, ["id", "type", "university"]);
        var universityGroupId = getUniversityGroupId();
        var universityId = getUniversityGroupId();
        var uniOrGroupId = universityId || universityGroupId;
        var utmData = {
            university_id: university.id,
        };
        if (localStorageWrapper.getItem('utmData_' + uniOrGroupId)) {
            var storedUtmData = tryParseJSON(localStorageWrapper.getItem('utmData_' + uniOrGroupId));
            if (storedUtmData) {
                utmData = storedUtmData;
            }
        }
        store.dispatch({
            type: REGISTRATION,
            payload: __assign(__assign(__assign({}, ssoData), utmData), { university_id: university.id, group_id: universityGroupId, nationality_id: 41, engagementId: id, engagementType: type }),
            onLoginSuccess: handleLoginSuccess,
        });
    }
};
var handleLoginSuccess = function () {
    // Get universitySsoData data
    var universitySsoData = tryParseJSON(localStorageWrapper.getItem('universitySsoData'));
    if (universitySsoData) {
        var id = universitySsoData.id, type = universitySsoData.type, slug = universitySsoData.slug, userId = universitySsoData.userId;
        if (type === 'live-stream') {
            NavigateWrapper.navigate("".concat(getCommonRoutePath(), "/live-streams/").concat(slug));
        }
        else if (type === 'group-chat') {
            NavigateWrapper.navigate("".concat(getCommonRoutePath(), "/interaction/dialogs/").concat(id));
        }
        else if (type !== 'forum') {
            NavigateWrapper.navigate("".concat(getCommonRoutePath(), "/interaction/").concat(userId || id));
        }
        else {
            NavigateWrapper.navigate(getCommonRoutePath());
        }
    }
    // Remove universitySsoData data
    localStorageWrapper.removeItem('universitySsoData');
};
