import {
    ACCOUNT,
    ACCOUNT_INFO_FAILED,
    ACCOUNT_INFO_SUCCESS,
    ACCOUNT_LOGOUT,
    AUTH_BY_CODE,
    CHANGE_MAILING,
    EXTERNAL_SPEAKER_AUTH,
    GET_COUNTRIES_SUCCESS,
    GET_NATIONALITIES_SUCCESS,
    GET_USER_COUNTRY_SUCCESS,
    LOGIN_SUCCESS,
    MANDATORY_QUESTIONS,
    MANDATORY_QUESTIONS_FAILED,
    MANDATORY_QUESTIONS_SUCCESS,
    MOB_APP_AUTH,
    SET_CHAT_PERMISSION,
    SET_SLUG_FROM_URL,
    SOCIAL_MEDIA_LOGIN_SUCCESS,
    SSO_LOGIN_SUCCESS,
    TIK_TOK_LOGIN_SUCCESS,
    UNIVERSITY_QUESTIONS_SUCCESS,
} from '../../actions/accountActions';

import { STUDENT_INFO_SUCCESS } from '../../actions/userActions';

import { getToken } from 'src/helpers/token';
import OptionalQuestionModel from '../../../models/OptionalQuestionModel';
import { ADD_NEW_DIALOG_TO_IDS, GET_DIALOGS_IDS_SUCCESS } from '../../actions/dialogsActions';

const token = getToken();

/**
 * @type {import('../../types/account').AccountState}
 */

const ACCOUNT_STORE = {
    token,
    account_info: null,
    startUser: null,
    countries: [],
    nationalities: [],
    isAmbassador: false,
    ssoAutoLoginWasCalled: false,
    dialogIds: [],
    questions: [],
    hasMandatoryQuestions: false,
    mandatoryQuestionsLoading: true,
    timeLimitReached: true,
    isMaintenanceMode: false,
    isAllowedToChat: false, // only for ambassadors
    isAuthFromMobApp: false, // only for ambassadors logged in from mob app
    isExternalSpeaker: false, // only for external speakers
    urlSlug: null, // for redirect to live event
    loadedQuestionsUniversityId: null,
    acceptedTermsAnswered: false,
    authCode: null,
    onlyMandatory: false,
    isLoading: !!token,
};

/**
 * @param {import('../../types/account').AccountState} state
 * @param {import('redux').AnyAction} action
 * @returns {import('../../types/account').AccountState}
 */

export default function (state = ACCOUNT_STORE, action) {
    const payload = action.payload;

    switch (action.type) {
        case AUTH_BY_CODE: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case LOGIN_SUCCESS: {
            return {
                ...state,
                token: payload.token,
                isLoading: payload.isLoading != null ? payload.isLoading : state.isLoading,
            };
        }

        case SSO_LOGIN_SUCCESS: {
            return {
                ...state,
                token: payload.token,
            };
        }

        case SOCIAL_MEDIA_LOGIN_SUCCESS: {
            return {
                ...state,
                token: payload.token,
                onlyMandatory: false,
            };
        }

        case TIK_TOK_LOGIN_SUCCESS: {
            return {
                ...state,
                token: payload.token,
            };
        }

        case STUDENT_INFO_SUCCESS: {
            return {
                ...state,
                startUser: payload.user,
            };
        }

        case ACCOUNT_INFO_SUCCESS: {
            return {
                ...state,
                account_info: payload.account,
                hasMandatoryQuestions:
                    payload.account && payload.account.isAnsweredMandatoryQuestion !== null && payload.groupId,
                isAllowedToChat: payload.account && payload.account.permission && payload.account.permission.is_enabled,
                timeLimitReached: payload.account && payload.account.timeIsUp,
                isAmbassador: payload.account && payload.account.id && payload.account.key === 'univercity-student',
                notificationData: payload.notificationData,
                authCode: payload.authCode,
                isLoading: false,
            };
        }

        case ACCOUNT_INFO_FAILED: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case UNIVERSITY_QUESTIONS_SUCCESS: {
            /* payload.quizQuestion.sort((a, b) => a.id - b.id);

            const questions = [];
            let marketingPreference = null;
            for (const question of payload.quizQuestion) {
                if (question.key === "marketing_preference") {
                    marketingPreference = question;
                } else {
                    questions.push(question);
                }
            }

            if (marketingPreference) {
                questions.push(marketingPreference);
            } */
            const questions = payload.questions.map((question) => OptionalQuestionModel(question));

            return {
                ...state,
                questions: questions,
                loadedQuestionsUniversityId: payload.universityId,
                acceptedTermsAnswered: false,
                onlyMandatory: payload.onlyMandatory,
            };
        }

        case ACCOUNT.RESET_QUESTIONS: {
            return {
                ...state,
                questions: [],
                loadedQuestionsUniversityId: null,
            };
        }

        case ACCOUNT.FIRE_ON_ACCEPTED_TERMS: {
            return {
                ...state,
                acceptedTermsAnswered: payload.accepted,
            };
        }

        case MANDATORY_QUESTIONS: {
            return {
                ...state,
                mandatoryQuestionsLoading: true,
            };
        }

        case MANDATORY_QUESTIONS_SUCCESS: {
            return {
                ...state,
                mandatoryQuestions: payload.questions,
                mandatoryQuestionsLoading: false,
            };
        }

        case MANDATORY_QUESTIONS_FAILED: {
            return {
                ...state,
                mandatoryQuestionsLoading: false,
            };
        }

        case GET_USER_COUNTRY_SUCCESS: {
            return {
                ...state,
                geoCountry: payload.alpha2Code,
            };
        }

        case GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                countries: payload.countries,
            };
        }

        case GET_NATIONALITIES_SUCCESS: {
            return {
                ...state,
                nationalities: payload.nationalities,
            };
        }

        case GET_DIALOGS_IDS_SUCCESS: {
            return {
                ...state,
                dialogIds: payload.ids,
            };
        }

        case ADD_NEW_DIALOG_TO_IDS: {
            const ids = [...state.dialogIds];
            if (!ids.includes(+payload.id)) {
                ids.push(payload.id);
            }
            return {
                ...state,
                dialogIds: ids,
            };
        }

        case SET_CHAT_PERMISSION: {
            return {
                ...state,
                isAllowedToChat: payload.isAllowedToChat || false,
                timeLimitReached: payload.timeLimitReached || false,
            };
        }

        case CHANGE_MAILING: {
            const account_info = state.account_info;
            for (const item of account_info.mailing) {
                if (item.id === payload.id) {
                    item.answer = payload.answer;
                }
            }
            return {
                ...state,
                account_info,
            };
        }

        case ACCOUNT_LOGOUT: {
            payload.token = state.token;
            return {
                ...state,
                token: null,
                isLoading: false,
            };
        }

        case MOB_APP_AUTH: {
            return {
                ...state,
                isAuthFromMobApp: true,
            };
        }

        case EXTERNAL_SPEAKER_AUTH: {
            return {
                ...state,
                isExternalSpeaker: true,
            };
        }

        case SET_SLUG_FROM_URL: {
            return {
                ...state,
                urlSlug: payload,
            };
        }

        case ACCOUNT.SET_MAINTENANCE_MODE: {
            return {
                ...state,
                isMaintenanceMode: true,
                isLoading: false,
            };
        }

        default:
            return state;
    }
}
