export default {
    // Positions
    positions: {
        tl: 'tl',
        tr: 'tr',
        tc: 'tc',
        bl: 'bl',
        br: 'br',
        bc: 'bc',
    },

    // Levels
    levels: {
        success: 'success',
        error: 'error',
        warning: 'warning',
        info: 'info',
    },

    // Notification defaults
    notification: {
        title: null,
        message: null,
        level: null,
        position: 'tr',
        autoDismiss: 5,
        dismissible: 'both',
        action: null,
    },
};
