export const tryParseJSON = (jsonString) => {
    try {
        const parsed = JSON.parse(jsonString);
        return parsed;
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
    }
};

export const tryStringifyJSON = (obj) => {
    try {
        const jsonString = JSON.stringify(obj);
        return jsonString;
    } catch (error) {
        console.error('Error stringifying JSON:', error);
        return null;
    }
};
