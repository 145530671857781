import HttpService from '../../system/HttpServices';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        searchItems,
        preFilterItems,
        searchType,
        currentGroupChatsIds,
        limit = 10,
        offset = 0,
        isForum = false,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            groupChatsNotInIds: currentGroupChatsIds,
            searchType,
            limit: limit,
            offset: 0, // if random then 0 with groupChatsNotInIds
            orderBy: 'random',
            isForum,
            countryIds,
            profileTypeIds,
            subjectIds,
        };
        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }

        return HttpService.post({
            path: '/dialogs/getGroupChats',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },

    watched: (groupChatId) => {
        return HttpService.post({ path: '/dialogs/' + groupChatId + '/watch' });
    },
};
