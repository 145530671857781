import {
    GROUP_CHATS_INFO,
    GROUP_CHATS_INFO_SUCCESS,
    GROUP_CHATS_INFO_FAIL,
    LOAD_NEXT_GROUP_CHATS,
    LOAD_NEXT_GROUP_CHATS_SUCCESS,
    REFRESH_GROUP_CHATS_DATA,
} from '../actions/groupChatsActions';

const elementsToLoad =
    window.innerWidth > 1300
        ? 8
        : window.innerWidth > 900 && window.innerWidth <= 1300
        ? 9
        : window.innerWidth > 650 && window.innerWidth <= 900
        ? 6
        : 4;

const initialState = {
    items: null,
    ids: [],
    limit: elementsToLoad,
    offset: 0,
    total: 0,
    isLoading: true,
    nextLoading: false,
    refreshGroupChatsData: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GROUP_CHATS_INFO:
            payload.limit = state.limit;
            payload.offset = 0;
            return state;

        case GROUP_CHATS_INFO_SUCCESS:
            const groupChatsIds = [];
            if (payload.groupChats && payload.groupChats.length) {
                for (const groupChat of payload.groupChats) {
                    groupChatsIds.push(groupChat.id);
                }
            }
            return {
                ...state,
                items: payload.groupChats,
                ids: groupChatsIds,
                isLoading: false,
                nextLoading: false,
                offset: payload.offset,
                total: payload.total,
            };
        case GROUP_CHATS_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                nextLoading: false,
            };

        case LOAD_NEXT_GROUP_CHATS:
            payload.ids = [...state.ids];
            payload.limit = state.limit;
            payload.offset = state.offset + state.limit;
            return {
                ...state,
                nextLoading: true,
            };

        case LOAD_NEXT_GROUP_CHATS_SUCCESS:
            const ids = [];
            if (payload.groupChats && payload.groupChats.length) {
                for (const groupChat of payload.groupChats) {
                    ids.push(groupChat.id);
                }
            }
            return {
                ...state,
                items: [...state.items, ...payload.groupChats],
                ids: [...state.ids, ...ids],
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };

        case REFRESH_GROUP_CHATS_DATA:
            return {
                ...state,
                refreshGroupChatsData: payload,
            };

        default:
            return state;
    }
}
