import HttpService from '../../system/HttpServices';
import pusher from '../../system/services/PusherWrapper';

export default {
    get: (dialogId, beforeMessageId, afterMessageId, limit, privateCode, aroundMessageId) => {
        const data = {
            order: 'desc',
            orderBy: 'id',
        };
        if (afterMessageId) {
            data['limit'] = limit;
            data['afterMessageId'] = afterMessageId;
        } else if (aroundMessageId) {
            data['limit'] = limit;
            data['aroundMessageId'] = aroundMessageId;
        } else {
            data['limit'] = limit;
            if (beforeMessageId) {
                data['beforeMessageId'] = beforeMessageId;
            }
        }
        if (privateCode) {
            data['privateCode'] = privateCode;
        }
        return HttpService.get({ path: '/dialogs/' + dialogId + '/messages', data, requestType: 'interaction' });
    },
    getGroupMessages: (dialogId, beforeMessageId, afterMessageId, limit, privateCode, aroundMessageId) => {
        const data = {
            order: 'desc',
            orderBy: 'id',
            limit,
        };
        if (afterMessageId) {
            data['afterMessageId'] = afterMessageId;
        } else if (aroundMessageId) {
            data['aroundMessageId'] = aroundMessageId;
        } else {
            data['limit'] = limit;
            if (beforeMessageId) {
                data['beforeMessageId'] = beforeMessageId;
            }
        }
        if (privateCode) {
            data['privateCode'] = privateCode;
        }
        return HttpService.get({ path: '/dialogs/' + dialogId + '/groupMessages', data, requestType: 'interaction' });
    },
    getAmbassadorMessages: (dialog_id) => {
        return HttpService.get({ path: '/dialogs/' + dialog_id + '/ambassadorMessages', requestType: 'interaction' });
    },
    send: (type, text, dialog_id, parentMessageId, privateCode) => {
        return HttpService.post({
            path: '/dialogs/' + dialog_id + '/messages/prospect/send',
            data: {
                type: type,
                text: text,
                parentMessageId,
                socketId: pusher.connection.socket_id,
                privateCode,
            },
            requestType: 'interaction',
        });
    },
    sendAmbassadorMessage: (type, text, dialog_id, parentMessageId) => {
        return HttpService.post({
            path: '/dialogs/' + dialog_id + '/messages/send',
            data: {
                type: type,
                text: text,
                parentMessageId,
                socketId: pusher.connection.socket_id,
            },
            requestType: 'interaction',
        });
    },
    reportMessage: (reportData) => {
        return HttpService.post({ path: '/dialogs/reportMessage', data: reportData, requestType: 'interaction' });
    },
};
