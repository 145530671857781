import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import { MAIN_INFO, MAIN_INFO_FAIL, MAIN_INFO_LOADED, MAIN_INFO_SUCCESS } from '../../actions/mainActions';
import { GET_NEXT_ITEMS_SUCCESS, GET_NEXT_ITEMS_FAIL } from '../../actions/randomItems';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getContentTypes = (state) => state.widget.contentTypes;
const getSearchType = (state) => state.widget.searchType;

export function* mainInfo(action) {
    const payload = action.payload;
    const random = payload.random !== false;
    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const contentTypes = yield select(getContentTypes);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.main.get(
                groupUniversityId,
                payload.university_id && !groupUniversityId ? [payload.university_id] : universityIds,
                payload.groupCountryIds,
                payload.search,
                preFilterItems,
                searchType,
                contentTypes,
                random,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: MAIN_INFO_SUCCESS,
                payload: response.data,
            });
        } else {
            yield put({
                type: MAIN_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: MAIN_INFO_FAIL,
        });
    }
}

export function* getNextItems(action) {
    const payload = action.payload;
    const random = payload.random !== false;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const contentTypes = yield select(getContentTypes);
    const searchType = yield select(getSearchType);

    let callMainInfo = false;
    try {
        const response = yield call(() => {
            return API.main.get(
                groupUniversityId,
                payload.university_id && !groupUniversityId
                    ? [payload.university_id]
                    : payload.universityIds
                      ? payload.universityIds
                      : universityIds,
                payload.groupCountryIds,
                payload.search,
                preFilterItems,
                searchType,
                contentTypes,
                random,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success && response.data) {
            const filterTerms = [
                ...(payload.countryIds ? payload.countryIds : []),
                ...(payload.profileTypeIds ? payload.profileTypeIds : []),
                ...(payload.subjectIds ? payload.subjectIds : []),
            ];
            // in case with no search we adding these items to main info for conditions all over the app
            if (
                (!payload.search ||
                    (payload.search &&
                        (!payload.search.length ||
                            (payload.search.length &&
                                payload.queryTerms &&
                                payload.queryTerms.length === payload.search.length)))) &&
                !filterTerms.length &&
                (!preFilterItems || (preFilterItems && !preFilterItems.length)) &&
                (!contentTypes || (contentTypes && !contentTypes.length)) &&
                response.data &&
                (response.data.groupChats || response.data.users || response.data.posts || response.data.faqs)
            ) {
                yield put({
                    type: MAIN_INFO_SUCCESS,
                    payload: response.data,
                });
            }

            yield put({
                type: GET_NEXT_ITEMS_SUCCESS,
                payload: {
                    liveStreams: response.data.liveStreams,
                    groupChats: response.data.groupChats,
                    students: response.data.users,
                    posts: response.data.posts,
                    faqs: response.data.faqs,
                    forumChats: response.data.forum,
                    random: random,
                },
            });
            if (
                (!response.data.liveStreams || (response.data.liveStreams && !response.data.liveStreams.length)) &&
                (!response.data.groupChats || (response.data.groupChats && !response.data.groupChats.length)) &&
                (!response.data.users || (response.data.users && !response.data.users.length)) &&
                (!response.data.posts || (response.data.posts && !response.data.posts.length)) &&
                (!response.data.faqs || (response.data.faqs && !response.data.faqs.length)) &&
                (!response.data.forum || (response.data.forum && !response.data.forum.length))
            ) {
                callMainInfo = true;
            } else {
                yield put({
                    type: MAIN_INFO_LOADED,
                });
            }
        } else if (!response.cancelled) {
            yield put({
                type: GET_NEXT_ITEMS_FAIL,
            });
            callMainInfo = true;
        }
    } catch (e) {
        yield put({
            type: GET_NEXT_ITEMS_FAIL,
        });
        console.log(e);
        callMainInfo = true;
    }

    if (callMainInfo) {
        const getMainInfo = (state) => state.main;
        const mainInfo = yield select(getMainInfo);
        // we only need it once at least per day
        if (!mainInfo.appLoadedWithNoError) {
            const filterTerms = [
                ...(payload.countryIds ? payload.countryIds : []),
                ...(payload.profileTypeIds ? payload.profileTypeIds : []),
                ...(payload.subjectIds ? payload.subjectIds : []),
            ];
            if ((payload.search && payload.search.length) || (filterTerms && filterTerms.length)) {
                yield put({
                    type: MAIN_INFO,
                    payload: {
                        university_id: payload.university_id,
                        random: true,
                    },
                });
            } else {
                yield put({
                    type: MAIN_INFO_LOADED,
                });
            }
        }
    }
}
