import HttpService from '../../system/HttpServices';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        searchItems,
        preFilterItems,
        searchType,
        faqsIds,
        limit,
        offset,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            faqsNotInIds: faqsIds,
            searchType,
            limit: limit,
            offset: 0, // if random then 0 with faqsNotInIds
            orderBy: 'random',
            countryIds,
            profileTypeIds,
            subjectIds,
        };
        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }

        return HttpService.post({
            path: '/faqs/getFaqs',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },
    getFaq: (faq_id) => {
        return HttpService.get({ path: '/faqs/' + faq_id });
    },

    watched: (faq_id) => {
        return HttpService.post({ path: '/faqs/' + faq_id + '/watch' });
    },

    answerLiked: (answer_id, faq_id, unlike, faqWatched) => {
        return HttpService.post({
            path: '/faqs/' + faq_id + '/answers/' + answer_id + '/like',
            data: { unlike, faqWatched },
        });
    },

    shared: (faq_id) => {
        return HttpService.post({ path: '/faqs/' + faq_id + '/share' });
    },
};
