const OptionalQuestionModel = (data) => {
    const response = {
        id: null,
        key: '',
        order: null,
        question: '',
        answer_type: 'default',
        predefined_answers: [],
        isMandatory: false,
    };

    if (data) {
        if (data.order) response.id = data.order;
        if (data.key) response.key = data.key;
        if (data.order) response.order = data.order;
        if (data.question) response.question = data.question;
        if (data.answer_type) response.answer_type = data.answer_type;
        if (data.predefined_answers) response.predefined_answers = data.predefined_answers;
        if (data.is_required) response.isMandatory = data.is_required;
    }

    return response;
};

export default OptionalQuestionModel;
