import {
    FORUM_CHATS_INFO,
    FORUM_CHATS_INFO_SUCCESS,
    FORUM_CHATS_INFO_FAIL,
    LOAD_NEXT_FORUM_CHATS,
    LOAD_NEXT_FORUM_CHATS_SUCCESS,
    REFRESH_FORUM_CHATS_DATA,
    GET_FORUM_MESSAGES_SUCCESS,
    SET_ACTIVE_FORUM_CHAT,
} from '../actions/forumChatsActions';

const elementsToLoad =
    window.innerWidth > 1300
        ? 8
        : window.innerWidth > 900 && window.innerWidth <= 1300
        ? 9
        : window.innerWidth > 650 && window.innerWidth <= 900
        ? 6
        : 4;

const initialState = {
    items: null,
    ids: [],
    limit: elementsToLoad,
    offset: 0,
    total: 0,
    isLoading: true,
    nextLoading: false,
    refreshForumChatsData: true,
    activeForumChatData: {
        forumChat: null,
        message: null,
        showAllAnswers: false,
    },
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case FORUM_CHATS_INFO:
            payload.limit = state.limit;
            payload.offset = 0;
            return { ...state };

        case FORUM_CHATS_INFO_SUCCESS: {
            const forumChatsIds = [];
            if (payload.forumChats && payload.forumChats.length) {
                for (const forumChat of payload.forumChats) {
                    forumChatsIds.push(forumChat.id);
                }
            }
            return {
                ...state,
                items: payload.forumChats,
                ids: forumChatsIds,
                isLoading: false,
                nextLoading: false,
                offset: payload.offset,
                total: payload.total,
                activeForumChatData: { ...initialState.activeForumChatData },
            };
        }

        case FORUM_CHATS_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                nextLoading: false,
            };

        case LOAD_NEXT_FORUM_CHATS:
            payload.ids = [...state.ids];
            payload.limit = state.limit;
            payload.offset = state.offset + state.limit;
            return {
                ...state,
                nextLoading: true,
            };

        case LOAD_NEXT_FORUM_CHATS_SUCCESS: {
            const ids = [];
            if (payload.forumChats && payload.forumChats.length) {
                for (const forumChat of payload.forumChats) {
                    ids.push(forumChat.id);
                }
            }
            return {
                ...state,
                items: [...state.items, ...payload.forumChats],
                ids: [...state.ids, ...ids],
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };
        }

        case SET_ACTIVE_FORUM_CHAT: {
            const { activeForumChatData } = payload;

            return {
                ...state,
                activeForumChatData: {
                    forumChat: activeForumChatData.forumChat,
                    message: activeForumChatData.message,
                    showAllAnswers: activeForumChatData.showAllAnswers,
                },
            };
        }

        case GET_FORUM_MESSAGES_SUCCESS: {
            const chats = [...state.items];
            for (const chat in chats) {
                if (chats[chat].id === payload.forumChatId) {
                    chats[chat].messages = payload.messages;
                    chats[chat].totalMessages = payload.messages.length;
                }
            }
            return {
                ...state,
                items: chats,
                currentChatOpenedId: payload.currentChatOpenedId, // if we clicked see more answers before sending,
                // we have to write it here as after receiving new messages our key for current forum element will change, and it will be reloaded
            };
        }

        case REFRESH_FORUM_CHATS_DATA:
            return {
                ...state,
                refreshForumChatsData: payload,
            };

        default:
            return state;
    }
}
