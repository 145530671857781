import { httpServices } from 'src/system/HttpServices/HttpService';
export default {
    getAllSpaces: function (signal) {
        return httpServices.get({
            path: '/spaces',
            withCredentials: true,
            requestType: 'community',
            signal: signal,
        });
    },
    getById: function (spaceId, signal) {
        return httpServices.get({
            path: "/spaces/".concat(spaceId),
            requestType: 'community',
            signal: signal,
        });
    },
};
