import {
    STUDENT_INFO_SUCCESS,
    STUDENT_INFO_FAIL,
    STUDENT_POST_LIKED_SUCCESS,
    STUDENT_FAQ_ANSWER_LIKED_SUCCESS,
} from '../actions/studentActions';

const initialState = {
    user: null,
    universityProfile: false,
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case STUDENT_INFO_SUCCESS:
            return {
                user: payload.user,
                posts: payload.posts.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                }),
                faqs: payload.faqs,
                universityProfile: !!payload.universityProfile,
                isLoading: false,
            };

        case STUDENT_INFO_FAIL:
            return {
                ...state,
                user: null,
                posts: [],
                faqs: [],
                universityProfile: !!payload.universityProfile,
                isLoading: false,
            };

        case STUDENT_POST_LIKED_SUCCESS:
            let posts = state.posts ? [...state.posts] : null;
            if (posts) {
                for (let post in posts) {
                    if (posts[post].id === payload.postId) {
                        let like_count = posts[post].like_count;
                        posts[post].like_count = payload.unlike
                            ? like_count > 0
                                ? like_count - 1
                                : 0
                            : like_count + 1;
                    }
                }
            }
            return {
                ...state,
                posts,
            };

        case STUDENT_FAQ_ANSWER_LIKED_SUCCESS:
            let faqs = state.faqs ? [...state.faqs] : null;
            for (let faq in faqs) {
                if (faqs[faq].id === payload.faqId) {
                    for (let answer in faqs[faq].answers) {
                        if (faqs[faq].answers[answer].id === payload.answerId) {
                            let like_count = faqs[faq].answers[answer].like_count;
                            faqs[faq].answers[answer].like_count = payload.unlike
                                ? like_count > 0
                                    ? like_count - 1
                                    : 0
                                : like_count + 1;
                        }
                    }
                }
            }
            return {
                ...state,
                faqs,
            };

        default:
            return state;
    }
}
