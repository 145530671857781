import { configureStore } from '@reduxjs/toolkit';
import notifications from '../../modules/react-notification-system-redux/reducer';
import communityAccountReducer from '../slices/account';
import subjectsReducer from '../slices/subjects';
export var communityStore = configureStore({
    reducer: {
        notifications: notifications,
        subjects: subjectsReducer,
        communityAccount: communityAccountReducer,
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
        });
    },
});
