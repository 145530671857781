import {
    FAQS_INFO,
    FAQS_INFO_FAIL,
    FAQS_INFO_SUCCESS,
    FAQS_ANSWER_LIKED_SUCCESS,
    LOAD_NEXT_FAQS,
    LOAD_NEXT_FAQS_SUCCESS,
    REFRESH_FAQS_DATA,
} from '../actions/faqsActions';

const initialState = {
    items: null,
    ids: [],
    limit: 10,
    offset: 0,
    isLoading: true,
    nextLoading: false,
    refreshFaqsData: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case FAQS_INFO:
            payload.limit = state.limit;
            payload.offset = 0;
            return state;

        case FAQS_INFO_SUCCESS:
            const faqIds = [];
            if (payload.faqs && payload.faqs.length) {
                for (const faq of payload.faqs) {
                    faqIds.push(faq.id);
                }
            }
            return {
                ...state,
                items: payload.faqs,
                ids: faqIds,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };

        case FAQS_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                nextLoading: false,
            };

        case LOAD_NEXT_FAQS:
            payload.ids = [...state.ids];
            payload.limit = state.limit;
            payload.offset = state.limit + state.offset;
            return {
                ...state,
                nextLoading: true,
            };

        case LOAD_NEXT_FAQS_SUCCESS:
            const ids = [];
            if (payload.faqs && payload.faqs.length) {
                for (const faq of payload.faqs) {
                    ids.push(faq.id);
                }
            }
            return {
                ...state,
                items: [...state.items, ...payload.faqs],
                ids: [...state.ids, ...ids],
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };

        case FAQS_ANSWER_LIKED_SUCCESS:
            const items = state.items ? [...state.items] : null;
            for (const faq in items) {
                if (items[faq].id === payload.faqId) {
                    for (const answer in items[faq].answers) {
                        if (items[faq].answers[answer].id === payload.answerId) {
                            const like_count = items[faq].answers[answer].like_count;
                            items[faq].answers[answer].like_count = payload.unlike
                                ? like_count > 0
                                    ? like_count - 1
                                    : 0
                                : like_count + 1;
                        }
                    }
                }
            }
            return {
                ...state,
                items: items,
            };

        case REFRESH_FAQS_DATA:
            return {
                ...state,
                refreshFaqsData: payload,
            };
        default:
            return state;
    }
}
