import {
    CLEAR_SEARCH,
    REMOVE_SEARCH_TERM,
    SET_SEARCH_TERM,
    SET_SEARCH_TERM_BUBBLE,
    SET_GROUP_UNIVERSITY_BUBBLE,
    SET_SEARCH_TERM_HASH,
    SET_SEARCH_TERM_HASH_FOR_GROUP_UNI,
    RETURN_PREV_SEARCH,
    MATCH_ME_SEARCH,
} from '../actions/searchActions';

const initialState = {
    items: [], // only search items from main input search or terms in url
    bubbles: [], // search items, custom and fixed filters to show picked or searched terms
    groupUniversityBubble: null, // only for group
    searchResultsHash: {
        main: [],
        chatToUs: [],
        posts: [],
        faq: [],
        groupChats: [],
        forumChats: [],
    },
    previousData: {
        items: [],
        bubbles: [],
        groupUniversityBubble: null,
    },
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_SEARCH_TERM: {
            const items = [...state.items];
            const bubbles = [...state.bubbles];
            const foundTerm = bubbles.find((term) => term.toLowerCase() === payload.item.toLowerCase());
            if (!foundTerm) {
                const foundItem = items.find((term) => term.toLowerCase() === payload.item.toLowerCase());
                if (!foundItem) items.push(payload.item);
                bubbles.push(payload.item);
                return {
                    ...state,
                    items: items,
                    bubbles: bubbles,
                    previousData: payload.matchMe ? state.previousData : { ...state.previousData, items, bubbles },
                };
            } else {
                return state;
            }
        }

        case SET_SEARCH_TERM_BUBBLE: {
            const filterBubbles = [...state.bubbles];
            const find = filterBubbles.find((term) => term.toLowerCase() === payload.item.toLowerCase());
            filterBubbles.push(payload.item);
            return find
                ? state
                : {
                      ...state,
                      bubbles: filterBubbles,
                      previousData: payload.matchMe
                          ? state.previousData
                          : { ...state.previousData, bubbles: filterBubbles },
                  };
        }

        case SET_SEARCH_TERM_HASH: {
            return {
                ...state,
                searchResultsHash: {
                    ...state.searchResultsHash,
                    [payload.key]: payload.searchTerm,
                },
            };
        }

        case SET_GROUP_UNIVERSITY_BUBBLE: {
            return {
                ...state,
                groupUniversityBubble: payload.item,
                previousData: payload.matchMe
                    ? state.previousData
                    : { ...state.previousData, groupUniversityBubble: payload.item },
            };
        }

        case REMOVE_SEARCH_TERM: {
            const updatedSearchResultsHash = {};
            for (const key in state.searchResultsHash) {
                updatedSearchResultsHash[key] = state.searchResultsHash[key].filter(
                    (term) => term.toLowerCase() !== payload.item.toLowerCase()
                );
            }

            const items = state.items.filter((term) => term.toLowerCase() !== payload.item.toLowerCase());
            const bubbles = state.bubbles.filter((term) => term.toLowerCase() !== payload.item.toLowerCase());
            const groupUniversityBubble =
                state.groupUniversityBubble && state.groupUniversityBubble.toLowerCase() !== payload.item.toLowerCase()
                    ? state.groupUniversityBubble
                    : null;

            return {
                items,
                bubbles,
                groupUniversityBubble,
                searchResultsHash: updatedSearchResultsHash,
                previousData: payload.matchMe
                    ? state.previousData
                    : { ...state.previousData, items, bubbles, groupUniversityBubble },
            };
        }

        case MATCH_ME_SEARCH: {
            return {
                ...state,
                previousData: {
                    items: state.items,
                    bubbles: state.bubbles,
                    groupUniversityBubble: state.groupUniversityBubble,
                },
            };
        }

        case RETURN_PREV_SEARCH: {
            return {
                ...state,
                items: state.previousData.items,
                bubbles: state.previousData.bubbles,
                groupUniversityBubble: state.previousData.groupUniversityBubble,
            };
        }

        case CLEAR_SEARCH: {
            return {
                items: [],
                bubbles: [],
                groupUniversityBubble: null,
                searchResultsHash: initialState.searchResultsHash,
                previousData: {
                    items: [],
                    bubbles: [],
                    groupUniversityBubble: null,
                },
            };
        }

        case SET_SEARCH_TERM_HASH_FOR_GROUP_UNI: {
            const newSearchResultsHash = {};

            for (const key in state.searchResultsHash) {
                newSearchResultsHash[key] = payload.groupUniversityBubble
                    ? [...payload.hashedSearchTerm, payload.groupUniversityBubble]
                    : payload.hashedSearchTerm;
            }

            return {
                ...state,
                searchResultsHash: newSearchResultsHash,
            };
        }

        default:
            return state;
    }
}
