import {
    CHANGE_MESSAGE_KEYWORDS,
    CLEAR_MESSAGES_STORE,
    HIDE_NEW_MESSAGES,
    LOAD_AROUND_MESSAGES_SUCCESS,
    LOAD_NEW_MESSAGES_SUCCESS,
    LOAD_OLD_MESSAGES_SUCCESS,
    MESSAGES_INFO,
    MESSAGES_INFO_SUCCESS,
    NEW_MESSAGES_RECEIVED,
    RESET_AROUND_MESSAGES_REQUEST,
} from '../actions/messagesActions';

const MESSAGES = {
    isLoading: false,
    items: null,
    isNewMessagesModalOpened: false,
    activeUsers: '0',
    isBlockedScroll: false,
    limit: 50,
    total: 0,
    oldLoaded: false,
    previousScrollHeight: null,
    pinnedMessages: [],
    isFirstMessageLoaded: true,
    isLastMessageLoaded: false,
    scrollDirection: 'top',
    isAroundMessage: false,
    isScrollDownButtonVisible: false,
};

export default function (state = MESSAGES, action) {
    const payload = action.payload;

    switch (action.type) {
        case MESSAGES_INFO: {
            let block = true;
            try {
                const element = document.getElementById('chat-body');

                if (element && +element.scrollHeight - Math.ceil(+element.scrollTop) < +element.clientHeight + 6) {
                    block = false;
                }
            } catch (err) {}
            payload.limit = state.limit;
            if (payload.onScroll) {
                payload.beforeMessageId = !state.items || (state.items && !state.items.length) ? 0 : state.items[0].id;
            }
            return {
                ...state,
                isLoading: !payload.loaderDisabled,
                isBlockedScroll: !!payload.isBlockedScroll && block,
                oldLoaded: false,
            };
        }

        case MESSAGES_INFO_SUCCESS: {
            const items = payload.items && payload.items.length ? payload.items.reverse() : [];
            return {
                ...state,
                items: items,
                isLoading: false,
                activeUsers: payload.activeUsers,
                total: payload.total,
                pinnedMessages: payload.pinnedMessages || [],
                isLastMessageLoaded: false,
                scrollDirection: 'top',
                isScrollDownButtonVisible: false,
            };
        }

        case LOAD_NEW_MESSAGES_SUCCESS: {
            const items = state.items ? [...state.items] : [];
            const lastItemId = items && items.length ? items[items.length - 1].id : 0;
            const newItems = payload.items && payload.items.length ? payload.items.reverse() : [];
            for (const item of newItems) {
                // on some case additional check
                if (item.id > lastItemId) {
                    items.push(item);
                }
            }
            return {
                ...state,
                items: items,
                activeUsers: payload.activeUsers,
                isFirstMessageLoaded: payload.items.length === 0,
                scrollDirection: 'bottom',
            };
        }

        case LOAD_OLD_MESSAGES_SUCCESS: {
            const element = document.getElementById('chat-body');
            let previousScrollHeight = 0;
            if (element) {
                previousScrollHeight = element.scrollHeight;
            }
            let items = state.items ? [...state.items] : [];
            // on some case we will check if messages new
            if (
                items.length &&
                payload.items &&
                payload.items.length &&
                items[0].id > payload.items[payload.items.length - 1].id
            ) {
                items = [...payload.items.reverse(), ...items];
            }
            return {
                ...state,
                items: items,
                previousScrollHeight: previousScrollHeight,
                oldLoaded: true,
                isLastMessageLoaded: payload.items.length === 0,
                scrollDirection: 'top',
            };
        }
        case LOAD_AROUND_MESSAGES_SUCCESS: {
            return {
                ...state,
                items: payload.items.reverse(),
                isFirstMessageLoaded: false,
                isLastMessageLoaded: false,
                scrollDirection: 'bottom',
                isAroundMessage: true,
                isScrollDownButtonVisible: true,
            };
        }

        case NEW_MESSAGES_RECEIVED: {
            return {
                ...state,
                isNewMessagesModalOpened: true,
            };
        }

        case HIDE_NEW_MESSAGES: {
            return {
                ...state,
                isNewMessagesModalOpened: false,
            };
        }

        case CHANGE_MESSAGE_KEYWORDS: {
            const items = state.items ? [...state.items] : [];
            if (items.length) {
                for (const item in items) {
                    if (items[item].id === +payload.message_id) {
                        items[item].hide_keywords = payload.hide_keywords;
                    }
                }
            }
            return {
                ...state,
                items: items,
            };
        }

        case CLEAR_MESSAGES_STORE: {
            const newState = MESSAGES;

            return newState;
        }

        case RESET_AROUND_MESSAGES_REQUEST: {
            return {
                ...state,
                isAroundMessage: false,
            };
        }

        default:
            return state;
    }
}
