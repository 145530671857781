import { OPEN_LOGIN_POPUP, CLOSE_LOGIN_POPUP } from '../../actions/accountActions';

const initialState = {
    isOpened: false,
    university: null,
    id: null,
    name: '',
    type: 'chat',
    subType: null,
    slug: null,
    userId: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case OPEN_LOGIN_POPUP: {
            const { university, id, name, type, subType, slug, userId } = payload || {};
            return {
                ...state,
                isOpened: true,
                university,
                id,
                name,
                type: type || 'chat',
                subType,
                slug,
                userId,
            };
        }

        case CLOSE_LOGIN_POPUP: {
            return {
                ...state,
                isOpened: false,
            };
        }

        default:
            return state;
    }
}
