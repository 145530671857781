import HttpService from '../../system/HttpServices';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        groupCountryIds,
        searchItems,
        preFilterItems,
        searchType,
        contentTypes,
        random,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            searchType,
            usersLimit: 4, // we need 4 to show 3 and to know if we need to show show more, below for posts and faqs the same
            postsLimit: 4,
            faqsLimit: 5,
            liveEventLimit: 4,
            forumLimit: 5,
            countryIds,
            profileTypeIds,
            subjectIds,
        };
        if (random) {
            data['orderBy'] = 'random';
        }
        if (contentTypes && contentTypes.length) {
            data['contentTypes'] = contentTypes;
        }
        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }
        if (groupCountryIds && groupCountryIds.length > 0) {
            data['groupCountryIds'] = groupCountryIds;
        }
        if (countryIds && countryIds.length > 0) {
            data['countryIds'] = countryIds;
        }

        return HttpService.post({
            path: '/getContent',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },
};
