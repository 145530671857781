const NAMESPACE_PREFIX = 'EVENT_STREAM';

export const EVENT_STREAM = {
    OPEN_JOIN_MODAL: `${NAMESPACE_PREFIX}.OPEN_JOIN_MODAL`,
    CLOSE_JOIN_MODAL: `${NAMESPACE_PREFIX}.CLOSE_JOIN_MODAL`,
    MUTE_LOCAL_AUDIO: `${NAMESPACE_PREFIX}.MUTE_LOCAL_AUDIO`,
    MUTE_LOCAL_VIDEO: `${NAMESPACE_PREFIX}.MUTE_LOCAL_VIDEO`,
    SET_AUDIO_DEVICES: `${NAMESPACE_PREFIX}.SET_AUDIO_DEVICES`,
    SET_VIDEO_DEVICES: `${NAMESPACE_PREFIX}.SET_VIDEO_DEVICES`,
    SET_JOIN_IN_PROGRESS: `${NAMESPACE_PREFIX}.SET_JOIN_IN_PROGRESS`,
    SET_CURRENT_PARTICIPANT: `${NAMESPACE_PREFIX}.SET_CURRENT_PARTICIPANT`,
    ADD_PARTICIPANT: `${NAMESPACE_PREFIX}.ADD_PARTICIPANT`,
    SET_JOINED: `${NAMESPACE_PREFIX}.SET_JOINED`,
    SET_MEDIA: `${NAMESPACE_PREFIX}.SET_MEDIA`,
    REMOVE_PARTICIPANT: `${NAMESPACE_PREFIX}.REMOVE_PARTICIPANT`,
    SET_REMOTE_VIDEO_MUTED: `${NAMESPACE_PREFIX}.SET_REMOTE_VIDEO_MUTED`,
    SET_REMOTE_AUDIO_MUTED: `${NAMESPACE_PREFIX}.SET_REMOTE_AUDIO_MUTED`,
    RESET: `${NAMESPACE_PREFIX}.RESET`,
    SET_SCREEN_MEDIA: `${NAMESPACE_PREFIX}.SET_SCREEN_MEDIA`,
    SET_LOCAL_SCREEN_SHARED: `${NAMESPACE_PREFIX}.SET_LOCAL_SCREEN_SHARED`,
    SET_STREAM_AUDIO_MUTED: `${NAMESPACE_PREFIX}.SET_STREAM_AUDIO_MUTED`,
    SET_CHAT_VIEW_DISPLAYED: `${NAMESPACE_PREFIX}.SET_CHAT_VIEW_DISPLAYED`,
};
