import store from '../redux/store';
import { getUniversityGroupId, getUniversityId, isUniGroup } from './Helpers';
import { localStorageWrapper } from './storageFactory';
import { UNIVERSITY_IDS } from '../common/accountIds';

export const IsLoggedIn = (account) => {
    if (!account) {
        account = store.getState().account;
    }
    return account.account_info && account.account_info.id;
};

export const IsAmbassador = (account) => {
    if (!account) {
        account = store.getState().account;
    }
    return IsLoggedIn(account) && account.account_info.key === 'univercity-student';
};

export const IsProspect = (account) => {
    if (!account) {
        account = store.getState().account;
    }
    return IsLoggedIn(account) && account.account_info.key === 'prospect';
};

export const IsWebviewProspects = (account) => {
    if (!account) {
        account = store.getState().account;
    }
    return IsLoggedIn(account) && account.account_info.key === 'prospect' && account.isAuthFromMobApp;
};

export const IsExternalSpeaker = (account) => {
    if (!account) {
        account = store.getState().account;
    }
    return (
        IsLoggedIn(account) &&
        (account.account_info.key === 'live-event-speaker' || account.account_info.key === 'admin')
    );
};

export const showMatchMe = () => {
    const uniId = +getUniversityId();
    const uniGroupId = +getUniversityGroupId();
    const matchMeDevUniversities = [1];
    const matchMeStagingUniversities = [100061];
    const matchMeProdUniversities = [361];
    const matchMeDevGroups = [1];
    const matchMeStagingGroups = [2];
    const matchMeProdGroups = [1, 27];
    if (
        process.env.APP_ENV === 'development' &&
        ((isUniGroup() && matchMeDevGroups.includes(uniGroupId)) ||
            (!isUniGroup() && matchMeDevUniversities.includes(uniId)))
    ) {
        return true;
    } else if (
        process.env.APP_ENV === 'staging' &&
        ((isUniGroup() && matchMeStagingGroups.includes(uniGroupId)) ||
            (!isUniGroup() && matchMeStagingUniversities.includes(uniId)))
    ) {
        return true;
    } else if (
        process.env.APP_ENV === 'production' &&
        ((isUniGroup() && matchMeProdGroups.includes(uniGroupId)) ||
            (!isUniGroup() && matchMeProdUniversities.includes(uniId)))
    ) {
        return true;
    }
    return false;
};

export const saveAnsweredQuestionsUniversity = (universityId) => {
    const account = store.getState().account;
    if (!account.account_info) return;
    const answeredQuestionsUniversities = localStorageWrapper.getItem(
        'answeredQuestionsUniversities-' + account.account_info.id
    )
        ? JSON.parse(localStorageWrapper.getItem('answeredQuestionsUniversities-' + account.account_info.id))
        : [];

    if (!answeredQuestionsUniversities.includes(universityId)) {
        answeredQuestionsUniversities.push(universityId);
        localStorageWrapper.setItem(
            'answeredQuestionsUniversities-' + account.account_info.id,
            JSON.stringify(answeredQuestionsUniversities)
        );
    }
};

export const getMinimumAgeForNationality = (nationality) => {
    let minimumAge = 13;

    switch (nationality) {
        case 'Austrian':
        case 'Bulgarian':
        case 'Cypriot':
            minimumAge = 14;
            break;

        case 'Czech':
        case 'French':
        case 'Greek':
        case 'Slovenian':
            minimumAge = 15;
            break;

        case 'Croatian':
        case 'Dutch':
        case 'German':
        case 'Hungarian':
        case 'Irish':
        case 'Italian':
        case 'Luxembourger':
        case 'Maltese':
        case 'Romanian':
        case 'Slovak':
        case 'Swiss':
            minimumAge = 16;
            break;

        case 'Belgian':
        case 'British':
        case 'Danish':
        case 'Estonian':
        case 'Finnish':
        case 'Latvian':
        case 'Norwegian':
        case 'Polish':
        case 'Portuguese':
        case 'Spanish':
        case 'Swedish':
        default: // All other countries not mentioned in the list
            minimumAge = 13;
            break;
    }

    return minimumAge;
};

export const getMinimumAge = (universityId, nationality) => {
    if (
        universityId === UNIVERSITY_IDS.DOWNING ||
        universityId === UNIVERSITY_IDS.DEAKIN ||
        universityId === UNIVERSITY_IDS.SHEFELD_HALLAM ||
        universityId === UNIVERSITY_IDS.TEST_UNI_3
    ) {
        return 16;
    } else if (nationality) {
        return getMinimumAgeForNationality(nationality);
    }
    return 13;
};
