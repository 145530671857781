import {
    DIALOGS_INFO_SUCCESS,
    PROSPECT_DIALOGS_INFO,
    AMBASSADOR_DIALOGS_INFO,
    FIND_OR_CREATE_DIALOG_SUCCESS,
    CLEAR_DIALOGS_STORE,
    FIND_OR_CREATE_DIALOG_FAILED,
    FIND_OR_CREATE_DIALOG,
    RESET_DIALOG_NEW_MESSAGES,
    GET_SINGLE_DIALOG_INFO_SUCCESS,
    DELETE_DIALOG,
} from '../actions/dialogsActions';

const initialState = {
    isLoading: true,
    items: null,
    redirectDialogId: null,
    findOrCreateIsLoading: false,
};

export default function (state = initialState, action) {
    const payload = action.payload;

    switch (action.type) {
        case DIALOGS_INFO_SUCCESS: {
            const items = payload.items;
            if (payload.resetMessagesDialogId) {
                for (const item in items) {
                    if (items[item].id === payload.resetMessagesDialogId) {
                        items[item].new_messages = 0;
                    }
                }
            }
            return {
                ...state,
                items: items,
                isLoading: false,
            };
        }

        case PROSPECT_DIALOGS_INFO: {
            return {
                ...state,
                isLoading: payload && payload.isFirstLoad,
            };
        }

        case AMBASSADOR_DIALOGS_INFO: {
            return {
                ...state,
                isLoading: payload && payload.isFirstLoad,
            };
        }

        case GET_SINGLE_DIALOG_INFO_SUCCESS: {
            const items = state.items ? [...state.items] : null;
            if (items && payload.items && payload.items[0]) {
                for (const key in items) {
                    if (payload.items[0].id === items[key].id) {
                        items[key] = payload.items[0];
                    }
                }
            }
            return {
                ...state,
                items: items,
            };
        }

        case FIND_OR_CREATE_DIALOG: {
            return {
                ...state,
                findOrCreateIsLoading: true,
            };
        }

        case FIND_OR_CREATE_DIALOG_SUCCESS: {
            return {
                ...state,
                redirectDialogId: payload.dialog_id,
                findOrCreateIsLoading: false,
            };
        }

        case FIND_OR_CREATE_DIALOG_FAILED: {
            return {
                ...state,
                findOrCreateIsLoading: false,
            };
        }

        case RESET_DIALOG_NEW_MESSAGES: {
            const items = state.items ? [...state.items] : [];
            for (const item in items) {
                if (items[item].id === payload.dialog_id) {
                    items[item].new_messages = 0;
                }
            }
            return {
                ...state,
                items,
            };
        }

        case DELETE_DIALOG: {
            const items = [...state.items];
            const newItems = [];
            for (const item of items) {
                if (item.id !== payload.dialog_id) {
                    newItems.push(item);
                }
            }
            return {
                ...state,
                items: newItems,
            };
        }

        case CLEAR_DIALOGS_STORE: {
            const newState = initialState;

            return newState;
        }

        default:
            return state;
    }
}
