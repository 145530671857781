import { OPEN_ACTION_MODAL } from '../redux/actions/componentsActions';
import { CHAT_INFO, RESET_ACTIVE_CHAT, RESET_ONLY_CHAT } from '../redux/actions/currentChatActions';
import {
    ADD_NEW_DIALOG_TO_IDS,
    AMBASSADOR_DIALOGS_INFO,
    DELETE_DIALOG,
    GET_SINGLE_AMBASSADOR_DIALOG_INFO,
    GET_SINGLE_GROUP_DIALOG_INFO,
    GET_SINGLE_PROSPECT_DIALOG_INFO,
    GROUP_DIALOGS_INFO,
    PROSPECT_DIALOGS_INFO,
} from '../redux/actions/dialogsActions';
import { CHANGE_MESSAGE_KEYWORDS, CLEAR_MESSAGES_STORE, MESSAGES_INFO } from '../redux/actions/messagesActions';
import store from '../redux/store';
import pusher from '../system/services/PusherWrapper';
import { getCommonRoutePath } from './Helpers';
import { sessionStorageWrapper } from './storageFactory';

import { LIVE_STREAM_STATUS } from '../common/constants';
import { SET_CHAT_PERMISSION } from '../redux/actions/accountActions';
import {
    ADD_LIVE_STREAM_PROSPECT,
    ARCHIVE_LIVE_STREAM,
    GET_LIVE_STREAM,
    GET_LIVE_STREAM_PROSPECTS,
    UPDATE_LIVE_STREAM_STATUS,
} from '../redux/actions/currentStreamActions';

const sendRequestData = {
    timeout: null,
    dialogId: null,
};
const sendRequestToGroupChatData = {
    timeout: null,
    dialogId: null,
    dialogsCount: 0,
};

const sendRequestToGroupChat = (dialogId) => {
    const lastChatDate = sessionStorageWrapper.getItem('lastChatDate');
    let sendNewRequest = true;
    try {
        const newDate = new Date();
        if (lastChatDate) {
            if (newDate - new Date(lastChatDate) < 20000) {
                sendNewRequest = false;
                sendRequestToGroupChatData.dialogsCount += 1;
                if (!sendRequestToGroupChatData.timeout) {
                    sendRequestToGroupChatData.dialogId = dialogId;
                    sendRequestToGroupChatData.timeout = setTimeout(() => {
                        const { currentChat } = store.getState();
                        if (sendRequestToGroupChatData.timeout) {
                            clearTimeout(sendRequestToGroupChatData.timeout);
                            sendRequestToGroupChatData.timeout = null;
                        }
                        // writing new date as we don't need to send new request at the same moment as we do current, in 5sec will be send new
                        const date = new Date();
                        sessionStorageWrapper.setItem('lastChatDate', date);
                        if (sendRequestToGroupChatData.dialogsCount > 1) {
                            store.dispatch({
                                type: GROUP_DIALOGS_INFO,
                                payload: {},
                            });
                            sendRequestToGroupChatData.dialogsCount = 0;
                        } else if (sendRequestToGroupChatData.dialogId !== currentChat.data.id) {
                            store.dispatch({
                                type: GET_SINGLE_GROUP_DIALOG_INFO,
                                payload: {
                                    dialogId: sendRequestToGroupChatData.dialogId,
                                },
                            });
                        }
                    }, 21000);
                }
            } else {
                if (sendRequestToGroupChatData.timeout) {
                    clearTimeout(sendRequestToGroupChatData.timeout);
                    sendRequestToGroupChatData.timeout = null;
                }
                sessionStorageWrapper.setItem('lastChatDate', newDate);
            }
        } else {
            if (sendRequestToGroupChatData.timeout) {
                clearTimeout(sendRequestToGroupChatData.timeout);
                sendRequestToGroupChatData.timeout = null;
            }
            sessionStorageWrapper.setItem('lastChatDate', newDate);
        }
    } catch (err) {
        // TODO handle error?
    }
    return sendNewRequest;
};

const sendRequest = (currentChat) => {
    if (currentChat.chatsType === 1) return true;
    const lastMessagesDate = sessionStorageWrapper.getItem('lastMessagesDate');
    let sendNewRequest = true;
    const newDate = new Date();
    try {
        if (lastMessagesDate) {
            if (newDate - new Date(lastMessagesDate) < 5000) {
                sendNewRequest = false;
                if (!sendRequestData.timeout) {
                    sendRequestData.dialogId = currentChat.data.id;
                    sendRequestData.timeout = setTimeout(() => {
                        const { currentChat } = store.getState();
                        if (sendRequestData.timeout) {
                            clearTimeout(sendRequestData.timeout);
                            sendRequestData.timeout = null;
                        }
                        // writing new date as we don't need to send new request at the same moment as we do current, in 6sec will be send new
                        const date = new Date();
                        sessionStorageWrapper.setItem('lastMessagesDate', date);
                        // if user still on current dialog
                        if (sendRequestData.dialogId === currentChat.data.id) {
                            messagesInfo(sendRequestData.dialogId);
                        } else {
                            store.dispatch({
                                type: GET_SINGLE_GROUP_DIALOG_INFO,
                                payload: {
                                    dialogId: sendRequestData.dialogId,
                                },
                            });
                        }
                    }, 7000);
                }
            } else {
                if (sendRequestData.timeout) {
                    clearTimeout(sendRequestData.timeout);
                    sendRequestData.timeout = null;
                }
                sessionStorageWrapper.setItem('lastMessagesDate', newDate);
            }
        } else {
            if (sendRequestData.timeout) {
                clearTimeout(sendRequestData.timeout);
                sendRequestData.timeout = null;
            }
            sessionStorageWrapper.setItem('lastMessagesDate', newDate);
        }
    } catch (err) {
        // TODO handle error?
    }
    return sendNewRequest;
};

const sendEventRequest = (currentChat) => {
    const lastMessagesDate = sessionStorageWrapper.getItem('lastMessagesDate');
    let sendNewRequest = true;
    const newDate = new Date();
    try {
        if (lastMessagesDate) {
            if (newDate - new Date(lastMessagesDate) < 5000) {
                sendNewRequest = false;
                if (!sendRequestData.timeout) {
                    sendRequestData.dialogId = currentChat.id;
                    sendRequestData.timeout = setTimeout(() => {
                        const { currentLiveStream } = store.getState();
                        if (sendRequestData.timeout) {
                            clearTimeout(sendRequestData.timeout);
                            sendRequestData.timeout = null;
                        }
                        // writing new date as we don't need to send new request at the same moment as we do current, in 6sec will be send new
                        const date = new Date();
                        sessionStorageWrapper.setItem('lastMessagesDate', date);
                        // if user still on current dialog
                        if (
                            sendRequestData.dialogId === currentLiveStream.data[currentLiveStream.activeTab]?.id ||
                            sendRequestData.dialogId === currentLiveStream.data.dialogs[0]?.id
                        ) {
                            messagesInfo(sendRequestData.dialogId);
                        }
                    }, 7000);
                }
            } else {
                if (sendRequestData.timeout) {
                    clearTimeout(sendRequestData.timeout);
                    sendRequestData.timeout = null;
                }
                sessionStorageWrapper.setItem('lastMessagesDate', newDate);
            }
        } else {
            if (sendRequestData.timeout) {
                clearTimeout(sendRequestData.timeout);
                sendRequestData.timeout = null;
            }
            sessionStorageWrapper.setItem('lastMessagesDate', newDate);
        }
    } catch (err) {
        // TODO handle error?
    }
    return sendNewRequest;
};

const messagesInfo = (dialogId) => {
    const element = document.getElementById('chat-body');
    const liveChatElement = document.getElementById('live-chat-body');
    const { messages } = store.getState();
    if ((element || liveChatElement) && messages && messages.items && messages.items[messages.items.length - 1].id) {
        store.dispatch({
            type: MESSAGES_INFO,
            payload: {
                dialog_id: dialogId,
                afterMessageId: messages.items[messages.items.length - 1].id,
                loaderDisabled: true,
                isBlockedScroll: liveChatElement ? false : true,
                realtimeAction: true,
            },
        });
    }
};

const showNotificationPopup = (data, currentChat) => {
    const { account } = store.getState();
    if (
        !data.notification ||
        !data.notification.message ||
        (data.user_id && account.account_info && data.user_id === account.account_info.id)
    ) {
        // we don't need to show if we haven't received message and if user sends to himself (when he has few opened tabs with different socket Ids)
        return;
    }

    try {
        if (
            data.type !== 4 &&
            data.type !== 5 &&
            data.type !== 6 &&
            'Notification' in window &&
            (!currentChat || !currentChat.data || +currentChat.data.id !== +data.dialog_id)
        ) {
            if (window.top != window.self) {
                const postData = JSON.stringify({
                    desktopNotificationDialogId: data.dialog_id,
                    body: data.notification.message,
                    icon: data.notification.icon,
                    title: data.notification.title,
                });
                window.parent.postMessage(postData, '*');
            } else {
                const notification = new Notification(data.notification.title, {
                    body: data.notification.message,
                    icon: data.notification.icon,
                });

                notification.onclick = () => {
                    window.open(getCommonRoutePath() + '/interaction/dialogs/' + data.dialog_id, '_blank');
                };
            }
        }
    } catch (err) {
        // TODO notify user?
        console.log(err);
    }
};

const dialogListener = (account, dialogId) => {
    if (account.account_info.key === 'prospect') {
        window.channel.bind('dialogs:' + dialogId + ':messages:new', (data) => {
            const { currentChat, currentLiveStream } = store.getState();
            if (window.location.href.includes('/interaction/dialogs')) {
                if (data.keyword_message_id) {
                    store.dispatch({
                        type: CHANGE_MESSAGE_KEYWORDS,
                        payload: {
                            hide_keywords: data.hide_keywords,
                            message_id: data.keyword_message_id,
                        },
                    });
                    return;
                }
                if (data.reported_message_id || data.blocked_prospect_id || data.unblocked_prospect_id) {
                    store.dispatch({
                        type: MESSAGES_INFO,
                        payload: {
                            dialog_id: dialogId,
                            realtimeAction: true,
                        },
                    });
                    store.dispatch({
                        type: CHAT_INFO,
                        payload: {
                            dialog_id: dialogId,
                            realtimeAction: true,
                        },
                    });
                }
                if ((currentChat.data && currentChat.data.id != data.dialog_id) || !currentChat.data) {
                    if (!currentChat.chatsType || currentChat.chatsType === 1) {
                        store.dispatch({
                            type: GET_SINGLE_PROSPECT_DIALOG_INFO,
                            payload: {
                                dialogId: data.dialog_id,
                            },
                        });
                    } else {
                        const sendNewRequest = sendRequestToGroupChat(data.dialog_id);
                        if (sendNewRequest) {
                            store.dispatch({
                                type: GET_SINGLE_GROUP_DIALOG_INFO,
                                payload: {
                                    dialogId: data.dialog_id,
                                },
                            });
                        }
                    }
                }

                if (currentChat.data && currentChat.data.id == data.dialog_id) {
                    const sendChatMessagesRequests = sendRequest(currentChat);
                    if (sendChatMessagesRequests) {
                        messagesInfo(data.dialog_id);
                    }
                }
                if (data.dialogAction) {
                    if (data.archivedAction && currentChat?.data?.id == data.dialog_id) {
                        window.location =
                            window.location.pathname.split('/')[0] + getCommonRoutePath() + '/interaction/dialogs';
                    }
                    if (currentChat.chatsType === 4) {
                        store.dispatch({
                            type: GROUP_DIALOGS_INFO,
                            payload: {},
                        });
                    } else if (currentChat.chatsType === 1) {
                        store.dispatch({
                            type: PROSPECT_DIALOGS_INFO,
                            payload: {},
                        });
                    }
                }
            } else if (window.location.href.includes('/live-streams/')) {
                if (data.reported_message_id || data.blocked_prospect_id || data.unblocked_prospect_id) {
                    const slug = new URL(window.location.href).pathname.split('/').pop();
                    store.dispatch({
                        type: MESSAGES_INFO,
                        payload: {
                            dialog_id: dialogId,
                        },
                    });
                    store.dispatch({
                        type: GET_LIVE_STREAM,
                        payload: {
                            eventSlug: slug,
                            blockStreamRefresh: true,
                        },
                    });
                }

                if (
                    currentLiveStream.data &&
                    currentLiveStream.data[currentLiveStream.activeTab] &&
                    currentLiveStream.data[currentLiveStream.activeTab].id === data.dialog_id
                ) {
                    const sendChatMessagesRequests = sendEventRequest(
                        currentLiveStream.data[currentLiveStream.activeTab]
                    );
                    if (sendChatMessagesRequests) {
                        messagesInfo(data.dialog_id);
                    }
                }
                if (
                    currentLiveStream.data &&
                    (currentLiveStream.data.status === LIVE_STREAM_STATUS.ON_DEMAND ||
                        currentLiveStream.data.status === LIVE_STREAM_STATUS.COMPLETED) &&
                    currentLiveStream.data.dialogs &&
                    currentLiveStream.data.dialogs.length > 0 &&
                    currentLiveStream.data.dialogs[0].id === data.dialog_id
                ) {
                    const sendChatMessagesRequests = sendEventRequest(currentLiveStream.data.dialogs[0]);
                    if (sendChatMessagesRequests) {
                        messagesInfo(data.dialog_id);
                    }
                }

                if (data.keyword_message_id) {
                    store.dispatch({
                        type: CHANGE_MESSAGE_KEYWORDS,
                        payload: {
                            hide_keywords: data.hide_keywords,
                            message_id: data.keyword_message_id,
                        },
                    });
                    return;
                }
                if (
                    data.type === 'live-stream-chat' &&
                    data.notification.message.includes('closed this conversation')
                ) {
                    store.dispatch({
                        type: ARCHIVE_LIVE_STREAM,
                        payload: {
                            isArchived: true,
                        },
                    });
                } else if (
                    data.type === 'live-stream-chat' &&
                    data.notification.message.includes('opened this conversation')
                ) {
                    store.dispatch({
                        type: ARCHIVE_LIVE_STREAM,
                        payload: {
                            isArchived: false,
                        },
                    });
                }
            }
            showNotificationPopup(data, currentChat);
        });
    } else {
        window.channel.bind('dialogs:' + dialogId + ':messages:new', (data) => {
            const { currentChat, account, currentLiveStream } = store.getState();
            // first we are checking if ambassador was removed from current or another conversation
            const removedFromConversation =
                account &&
                account.account_info &&
                account.account_info.id &&
                data.detached_users &&
                data.detached_users.includes(account.account_info.id);

            const disabledToChat =
                account &&
                account.account_info &&
                account.account_info.id &&
                data.disabled_ambassador_id &&
                data.disabled_ambassador_id === account.account_info.id;
            const enabledToChat =
                account &&
                account.account_info &&
                account.account_info.id &&
                data.enabled_ambassador_id &&
                data.enabled_ambassador_id === account.account_info.id;
            if (data.blocked_prospect_id || data.reported_message_id || data.unblocked_prospect_id) {
                store.dispatch({
                    type: MESSAGES_INFO,
                    payload: {
                        dialog_id: dialogId,
                        realtimeAction: true,
                    },
                });
                if (window.location.href.includes('/live-streams/')) {
                    const slug = new URL(window.location.href).pathname.split('/').pop();
                    store.dispatch({
                        type: GET_LIVE_STREAM,
                        payload: {
                            eventSlug: slug,
                            blockStreamRefresh: true,
                        },
                    });
                } else {
                    store.dispatch({
                        type: GET_SINGLE_AMBASSADOR_DIALOG_INFO,
                        payload: {
                            dialogId: data.dialog_id,
                        },
                    });
                    store.dispatch({
                        type: CHAT_INFO,
                        payload: {
                            dialog_id: dialogId,
                            realtimeAction: true,
                        },
                    });
                }
            } else if (disabledToChat) {
                store.dispatch({
                    type: SET_CHAT_PERMISSION,
                    payload: {
                        isAllowedToChat: false,
                        timeLimitReached: data.disabled_reason === 'timeLimit',
                    },
                });
            } else if (enabledToChat) {
                store.dispatch({
                    type: SET_CHAT_PERMISSION,
                    payload: {
                        isAllowedToChat: true,
                        timeLimitReached: false,
                    },
                });
            } else if (removedFromConversation) {
                if (currentChat.data.id == data.dialog_id) {
                    store.dispatch({
                        type: RESET_ACTIVE_CHAT,
                        payload: {
                            resetOnlyGroupLastChat:
                                currentChat.groupLastChat &&
                                currentChat.groupLastChat.id &&
                                currentChat.groupLastChat.id == data.dialog_id,
                            resetOnlyIndividualLastChat:
                                currentChat.individualLastChat &&
                                currentChat.individualLastChat.id &&
                                currentChat.individualLastChat.id == data.dialog_id,
                        },
                    });
                    store.dispatch({
                        type: CLEAR_MESSAGES_STORE,
                    });
                    store.dispatch({
                        type: OPEN_ACTION_MODAL,
                        payload: {
                            title:
                                'You have been removed from conversation' + (data.user ? ' by ' + data.user : '') + '.',
                        },
                    });
                } else if (
                    currentChat.groupLastChat &&
                    currentChat.groupLastChat.id &&
                    currentChat.groupLastChat.id == data.dialog_id &&
                    (!currentChat.chatsType || currentChat.chatsType === 1)
                ) {
                    store.dispatch({
                        type: RESET_ONLY_CHAT,
                        payload: {
                            resetOnlyGroupLastChat: true,
                        },
                    });
                } else if (
                    currentChat.individualLastChat &&
                    currentChat.individualLastChat.id &&
                    currentChat.individualLastChat.id == data.dialog_id &&
                    currentChat.chatsType === 4
                ) {
                    store.dispatch({
                        type: RESET_ONLY_CHAT,
                        payload: {
                            resetOnlyIndividualLastChat: true,
                        },
                    });
                }
                store.dispatch({
                    type: DELETE_DIALOG,
                    payload: {
                        dialog_id: data.dialog_id,
                    },
                });
            } else if (data.keyword_message_id) {
                store.dispatch({
                    type: CHANGE_MESSAGE_KEYWORDS,
                    payload: {
                        hide_keywords: data.hide_keywords,
                        message_id: data.keyword_message_id,
                    },
                });
                return;
            } else {
                if (window.location.href.includes('/interaction/dialogs')) {
                    if ((currentChat.data && currentChat.data.id != data.dialog_id) || !currentChat.data) {
                        if (!currentChat.chatsType || currentChat.chatsType === 1) {
                            store.dispatch({
                                type: GET_SINGLE_AMBASSADOR_DIALOG_INFO,
                                payload: {
                                    dialogId: data.dialog_id,
                                },
                            });
                        } else {
                            const sendNewRequest = sendRequestToGroupChat(data.dialog_id);
                            if (sendNewRequest) {
                                store.dispatch({
                                    type: GET_SINGLE_GROUP_DIALOG_INFO,
                                    payload: {
                                        dialogId: data.dialog_id,
                                    },
                                });
                            }
                        }
                    }

                    if (currentChat.data && currentChat.data.id == data.dialog_id) {
                        const sendChatMessagesRequests = sendRequest(currentChat);
                        if (sendChatMessagesRequests) {
                            messagesInfo(data.dialog_id);
                        }
                    }
                } else if (window.location.href.includes('/live-streams/')) {
                    if (
                        currentLiveStream.data &&
                        currentLiveStream.data[currentLiveStream.activeTab] &&
                        currentLiveStream.data[currentLiveStream.activeTab].id === data.dialog_id
                    ) {
                        const sendChatMessagesRequests = sendEventRequest(
                            currentLiveStream.data[currentLiveStream.activeTab]
                        );
                        if (sendChatMessagesRequests) {
                            messagesInfo(data.dialog_id);
                        }
                    }
                }
            }

            showNotificationPopup(data, currentChat);
        });
    }
};

export const bindDialogs = (dialogs, liveEventId, eventSlug) => {
    if (window.channel) {
        window.channel.unbind();
    }
    const { account } = store.getState();
    if (dialogs) {
        window.channel = pusher.subscribe('tap-page');

        for (const dialogId of dialogs) {
            if (account.account_info && account.account_info.key) {
                dialogListener(account, dialogId);
            }
        }
    }

    if (account.account_info) {
        window.channel.bind(`liveStreams:${liveEventId}:info`, (data) => {
            const { isChatActive, status: eventStatus, isStreamActive } = data;
            if (data && data.action === 'userAction') {
                const isSpeakerAction = data.actionType === 'speakers';
                const isProspectAction = data.actionType === 'prospects';
                const isAmbasadorAction = data.actionType === 'ambassadors';

                if (isProspectAction) {
                    if (data.newProspectJoined) {
                        store.dispatch({
                            type: ADD_LIVE_STREAM_PROSPECT,
                            payload: {
                                prospect: data.data.user,
                            },
                        });
                    } else {
                        store.dispatch({
                            type: GET_LIVE_STREAM_PROSPECTS,
                            payload: {
                                eventSlug,
                            },
                        });
                    }
                }

                if (isSpeakerAction || isAmbasadorAction) {
                    store.dispatch({
                        type: GET_LIVE_STREAM,
                        payload: {
                            eventSlug,
                            blockStreamRefresh: true,
                        },
                    });
                }
            }
            if (eventStatus) {
                store.dispatch({
                    type: UPDATE_LIVE_STREAM_STATUS,
                    payload: {
                        eventStatus,
                        isChatActive,
                        isStreamActive,
                    },
                });
            }
        });
    }
};

export const bindDialog = (dialogId) => {
    if (!window.channel) {
        window.channel = pusher.subscribe('tap-page');
    }
    window.channel.unbind('dialogs:' + dialogId + ':messages:new');

    const { account } = store.getState();
    if (account.account_info && account.account_info.key) {
        dialogListener(account, dialogId);
    } else {
        window.channel.bind('dialogs:' + dialogId + ':messages:new', (data) => {
            const { currentChat } = store.getState();
            if (
                window.location.href.includes('/interaction/dialogs') &&
                currentChat.chatsType === data.type &&
                currentChat.data &&
                currentChat.data.id == data.dialog_id
            ) {
                const sendChatMessagesRequests = sendRequest(currentChat);
                if (sendChatMessagesRequests) {
                    messagesInfo(data.dialog_id);
                }
            }

            showNotificationPopup(data, currentChat);
        });
    }
};

export const unbindDialogs = () => {
    if (window.channel) {
        window.channel.unbind();
    }
};

export const bindUserChannel = () => {
    if (window.userChannel) {
        window.userChannel.unbind();
    }
    window.userChannel = pusher.subscribe('user');
    const { account } = store.getState();
    if (account.account_info) {
        window.userChannel.bind(account.account_info.id + ':dialogs:new', (data) => {
            if (data.type && data.dialog_id) {
                if (+data.type === 4) {
                    store.dispatch({
                        type: GROUP_DIALOGS_INFO,
                        payload: {},
                    });
                } else if (+data.type === 1 || +data.type === 4) {
                    store.dispatch({
                        type: AMBASSADOR_DIALOGS_INFO,
                        payload: {},
                    });
                }
            }
            if (!account.dialogIds.includes(+data.dialog_id)) {
                store.dispatch({
                    type: ADD_NEW_DIALOG_TO_IDS,
                    payload: {
                        id: data.dialog_id,
                    },
                });
                bindDialog(data.dialog_id);
            }

            // we could show here some message
        });
    }
};

export const unbindUserChannel = () => {
    if (window.userChannel) {
        window.userChannel.unbind();
    }
};
