import { call, put, select } from 'redux-saga/effects';
import API from '../../../api';

import {
    DISPLAY_PROSPECT_INFO,
    SET_ACTIVE_CHAT,
    SET_CHAT_FEEDBACK,
    SHOW_CHAT_MEMBERS,
} from '../../actions/currentChatActions';
import { AMBASSADOR_DIALOGS_INFO } from '../../actions/dialogsActions';

import { MESSAGES_INFO } from '../../actions/messagesActions';

import { bindDialog } from '../../../helpers/pusherHelper';
import ProspectPrivateChatModel from '../../../models/ProspectPrivateChatModel';

const getAccountInfo = (state) => state.account.account_info;
const getCurrentChat = (state) => state.currentChat;

export function* currentChatInfo(action) {
    const { payload } = action;
    try {
        const accountInfo = yield select(getAccountInfo);
        const currentChatInfo = yield select(getCurrentChat);

        let response = {};
        let privateCode = null;
        if (payload.privateCode) {
            privateCode = payload.privateCode;
        } else if (currentChatInfo.privateCode && currentChatInfo.privateCodeDialogId === +payload.dialog_id) {
            privateCode = currentChatInfo.privateCode;
        }
        if (!accountInfo) {
            response = yield call(() => {
                return API.dialogs.getGroupChatById(payload.dialog_id, privateCode);
            });
        } else {
            response = yield call(() => {
                return API.dialogs.getById(payload.dialog_id, privateCode);
            });
        }

        if (response.success) {
            yield put({
                type: SET_ACTIVE_CHAT,
                payload: {
                    data: response.data,
                    type_chat: response.data.type,
                    chat_id: response.data.id,
                    firstLoad: payload.firstLoad,
                    privateCode: payload.privateCode,
                    realtimeAction: payload.realtimeAction,
                },
            });

            if (action.onSuccess) {
                action.onSuccess(response.data);
            }
        } else {
            if (action.onFailure) {
                action.onFailure(
                    response &&
                        response.errorData &&
                        !response.errorData.isActive &&
                        response.errorData.dialogType === 4
                );
            }
        }
    } catch (e) {
        console.log(e);
        if (action.onFailure) {
            action.onFailure();
        }
    }
}

export function* sendFeedback(action) {
    const { payload } = action;
    const response = yield call(() => {
        const data = {
            prospect_id: payload.prospect_id,
            score: payload.score,
            comments: payload.comments,
        };
        return API.dialogs.sendProspectFeedback(payload.dialog_id, data);
    });

    if (response.success) {
        action.onSuccess();
        yield put({
            type: SET_CHAT_FEEDBACK,
            payload: {
                dialog_id: payload.dialog_id,
                prospect_id: payload.prospect_id,
                score: payload.score,
                comments: payload.comments,
            },
        });
    }
}

export function* chatToProspect(action) {
    try {
        const { payload } = action;

        const response = yield call(() => {
            return API.dialogs.chatToProspect(payload);
        });

        if (response.success) {
            yield put({
                type: AMBASSADOR_DIALOGS_INFO,
                payload: {
                    prospectDialog: ProspectPrivateChatModel(response.data),
                    isFirstLoad: true,
                },
            });
            yield put({
                type: SET_ACTIVE_CHAT,
                payload: {
                    data: ProspectPrivateChatModel(response.data),
                    type_chat: response.data.type,
                    chat_id: response.data.id,
                    firstLoad: payload.firstLoad,
                    privateCode: payload.privateCode,
                },
            });
            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: response.data.id,
                    dialog_type: 1,
                    noResetForNewMessages: true,
                },
            });

            yield put({
                type: SHOW_CHAT_MEMBERS,
                payload: {
                    showMembers: false,
                },
            });

            yield put({
                type: DISPLAY_PROSPECT_INFO,
                payload: false,
            });

            bindDialog(response.data.id);
        }
    } catch (e) {
        console.log(e);
    }
}
