import { RNS_HIDE_NOTIFICATION, RNS_REMOVE_ALL_NOTIFICATIONS, RNS_SHOW_NOTIFICATION } from './const';

export default function Notifications(state = [], action = {}) {
    switch (action.type) {
        case RNS_SHOW_NOTIFICATION:
            return [...state, { ...action, uid: action.uid }];
        case RNS_HIDE_NOTIFICATION:
            return state.filter((notification) => {
                return notification.uid !== action.uid;
            });
        case RNS_REMOVE_ALL_NOTIFICATIONS:
            return [];
    }
    return state;
}
