import { CancelToken, axiosInstance } from './client';
import Handler from './handler';
import { getConfigData, getOnCatch, getOnThen, getTypePrefix } from './helpers';

class HttpServices {
    static async get({ path, data, currentToken = false, withCredentials = false, typeError, requestType = 'widget' }) {
        if (typeError) {
            Handler.typeError(typeError);
            return {
                success: false,
            };
        }
        if (currentToken && window.tapCancelApiCall != undefined) {
            window.tapCancelApiCall();
            window.tapCancelApiCall = undefined;
        }

        const configData = getConfigData(withCredentials, data);
        if (currentToken) {
            configData.cancelToken = new CancelToken((c) => {
                window.tapCancelApiCall = c;
            });
        }

        const prefix = getTypePrefix(requestType);
        const handleError = requestType === 'interaction' || requestType === 'common';

        return axiosInstance
            .get(prefix + path, configData)
            .then((response) => getOnThen(response, handleError))
            .catch((error) => getOnCatch(error, handleError));
    }

    /**
     * @template {any} Res
     * @template {any} Req
     * @param {Object} options
     * @param {string} options.path
     * @param {Req} [options.data={}]
     * @param {boolean} [options.withCredentials=false]
     * @param {string} [options.typeError]
     * @param {string} [options.requestType='widget']
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */

    static async post({ path, data = {}, withCredentials = false, typeError, requestType = 'widget' }) {
        if (typeError) {
            Handler.typeError(typeError);
            return {
                success: false,
            };
        }

        const prefix = getTypePrefix(requestType);
        const handleError = requestType === 'interaction' || requestType === 'common';
        return axiosInstance
            .post(prefix + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response, handleError))
            .catch((error) => getOnCatch(error, handleError));
    }

    static async put({ path, data = {}, withCredentials = false, requestType = 'widget' }) {
        const prefix = getTypePrefix(requestType);
        const handleError = requestType === 'interaction' || requestType === 'common';

        return axiosInstance
            .put(prefix + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response, handleError))
            .catch((error) => getOnCatch(error, handleError));
    }
}

export default HttpServices;
