import { call, put, select } from 'redux-saga/effects';
import API from '../../../api';
import { getCommonRoutePath, getUniversityId, isIosOrAndroid } from '../../../helpers/Helpers';
import { saveAnsweredQuestionsUniversity } from '../../../helpers/UserHelper';
import { NavigateWrapper } from '../../../helpers/navigateWrapper';
import { bindUserChannel, unbindDialogs, unbindUserChannel } from '../../../helpers/pusherHelper';
import { localStorageWrapper } from '../../../helpers/storageFactory';
import {
    ACCOUNT,
    ACCOUNT_INFO,
    ACCOUNT_INFO_FAILED,
    ACCOUNT_INFO_SUCCESS,
    CLOSE_LOGIN_POPUP,
    CLOSE_REGISTRATION_POPUP,
    EXTERNAL_SPEAKER_AUTH,
    GET_COUNTRIES_SUCCESS,
    GET_NATIONALITIES_SUCCESS,
    GET_USER_COUNTRY_SUCCESS,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    MANDATORY_QUESTIONS_FAILED,
    MANDATORY_QUESTIONS_SUCCESS,
    OPEN_REGISTRATION_POPUP,
    REGISTRATION_FAILED,
    REGISTRATION_SUCCESS,
    SAVE_MANDATORY_QUESTIONS_FAILED,
    SAVE_MANDATORY_QUESTIONS_SUCCESS,
    SAVE_UNIVERSITY_QUESTIONS,
    SAVE_UNIVERSITY_QUESTIONS_FAILED,
    SAVE_UNIVERSITY_QUESTIONS_SUCCESS,
    SOCIAL_MEDIA_LOGIN_FAILED,
    SOCIAL_MEDIA_LOGIN_SUCCESS,
    SOCIAL_MEDIA_REGISTRATION_FAILED,
    SSO_LOGIN_FAILED,
    SSO_LOGIN_SUCCESS,
    TIK_TOK_LOGIN_FAILED,
    TIK_TOK_LOGIN_SUCCESS,
    UNIVERSITY_QUESTIONS_FAILED,
    UNIVERSITY_QUESTIONS_SUCCESS,
    getAccountInfo,
} from '../../actions/accountActions';
import { REMOVE_DATA_REGISTER_MODAL } from '../../actions/componentsActions';
import { GET_DIALOGS_IDS } from '../../actions/dialogsActions';
import { SET_ACTIVE_FORUM_CHAT } from '../../actions/forumChatsActions';
import { SET_STREAM_UNI_DATA } from '../../actions/liveStreamsActions';

import { isSingleSsoAuth, openSingleSsoWindow } from '../../../helpers/SingleSsoHelper';

import { getToken, removeToken, setToken } from 'src/helpers/token';
import store from '../../store';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getCurrentUniversityId = (state) => state.widget.currentUniversityId;

export async function checkEmail(email) {
    return API.account.checkEmail(email);
}

export function* login(action) {
    const payload = action.payload;

    try {
        let universityId = payload.universityId;
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }

        const response = yield call(() => {
            return API.account.login(payload.email, payload.password, universityId, universityGroupId);
        });

        if (response.success) {
            setToken(response.data.token);

            yield put({
                type: LOGIN_SUCCESS,
                payload: {
                    token: response.data.token,
                },
            });

            yield put({
                type: CLOSE_LOGIN_POPUP,
            });

            yield put(
                getAccountInfo(
                    { notifications: !payload.noNotifications },
                    { afterSuccess: action.onAccountSuccess || false }
                )
            );

            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {
            yield put({
                type: LOGIN_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: LOGIN_FAILED,
        });
    }
}

export function* ssoLogin(action) {
    const { payload, meta } = action;
    let { isSsoNew, email, code, universityId, universityGroupId } = payload;
    const { onAccountSuccess, onFinish } = meta;

    try {
        if (!universityGroupId) {
            universityGroupId = yield select(getGroupUniversityId);
        }
        const response = yield call(() => {
            if (isSsoNew) {
                if (universityGroupId) {
                    universityId = null;
                }
                return API.account.ssoNewLogin(email, code, universityId, universityGroupId);
            } else {
                return API.account.ssoLogin(code, universityId, universityGroupId);
            }
        });

        if (response.success) {
            if (response.data.token) {
                setToken(response.data.token);

                yield put({
                    type: SSO_LOGIN_SUCCESS,
                    payload: {
                        token: response.data.token,
                    },
                });

                yield put(getAccountInfo(null, { afterSuccess: onAccountSuccess || false }));
            } else if (onFinish) {
                onFinish(response.data.user_data);
            }
        } else {
            yield put({
                type: SSO_LOGIN_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SSO_LOGIN_FAILED,
        });
    }
}

export function* ssoLoginAuth(action) {
    const { payload, meta } = action;
    const { token } = payload;
    const { onAccountSuccess, onFinish } = meta || {};

    try {
        let universityId = payload.universityId;
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }
        const response = yield call(() => {
            return API.account.ssoLoginAuth(token, universityId, universityGroupId);
        });

        if (response.success) {
            if (response.data.token) {
                setToken(response.data.token);

                yield put({
                    type: SSO_LOGIN_SUCCESS,
                    payload: {
                        token: response.data.token,
                    },
                });

                yield put(getAccountInfo(null, { afterSuccess: onAccountSuccess || false }));
            } else if (onFinish) {
                onFinish(response.data.user_data);
            }
        } else {
            yield put({
                type: SSO_LOGIN_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: SSO_LOGIN_FAILED,
        });
    }
}

export function* tikTokCallback(action) {
    const { payload, meta } = action;
    const { code } = payload;
    const { onFinish } = meta;
    try {
        const response = yield call(() => {
            return API.account.tikTokCallback({ code });
        });

        if (response.success) {
            onFinish(response.data);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* tikTokLogin(action) {
    const payload = action.payload;

    try {
        let universityId = payload.university_id;
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }

        const response = yield call(() => {
            return API.account.tikTokLogin({ payload, universityId, universityGroupId });
        });

        if (response.success) {
            setToken(response.data.token);

            yield put({
                type: TIK_TOK_LOGIN_SUCCESS,
                payload: {
                    token: response.data.token,
                },
            });

            yield put({
                type: CLOSE_LOGIN_POPUP,
            });

            yield put(
                getAccountInfo(
                    { notifications: !payload.noNotifications },
                    { afterSuccess: action.onAccountSuccess || false }
                )
            );

            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {
            yield put({
                type: TIK_TOK_LOGIN_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: TIK_TOK_LOGIN_FAILED,
        });
    }
}

export function* socialMediaLogin(action) {
    const payload = action.payload;

    try {
        let universityId = payload.universityId;
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }

        const response = yield call(() => {
            return API.account.socialLogin(payload.type, payload.signedData, universityId, universityGroupId);
        });

        if (response.success) {
            if (response.data.token) {
                setToken(response.data.token);

                yield put({
                    type: SOCIAL_MEDIA_LOGIN_SUCCESS,
                    payload: {
                        token: response.data.token,
                    },
                });

                yield put({
                    type: CLOSE_LOGIN_POPUP,
                });
                yield put({
                    type: CLOSE_REGISTRATION_POPUP,
                });

                if (!payload.fromSignInPage) {
                    yield put(getAccountInfo({ notifications: true }));
                }

                if (action.onSuccess) {
                    action.onSuccess();
                }
            } else {
                action.onFinish(true, response.data.user_data);
            }
        } else {
            yield put({
                type: SOCIAL_MEDIA_LOGIN_FAILED,
            });
            action.onFinish(false);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: SOCIAL_MEDIA_LOGIN_FAILED,
        });
        action.onFinish(false);
    }
}

export function* socialMediaRegistration(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.account.socialRegistration(payload.type, payload.data);
        });

        if (response.success) {
            setToken(response.data.token);

            yield put({
                type: REMOVE_DATA_REGISTER_MODAL,
            });

            yield put({
                type: SOCIAL_MEDIA_LOGIN_SUCCESS,
                payload: {
                    token: response.data.token,
                },
            });

            yield put(getAccountInfo({ notifications: true }, { afterSuccess: action.onLoginSuccess || false }));

            action.onSuccess();
        } else {
            yield put({
                type: SOCIAL_MEDIA_REGISTRATION_FAILED,
            });
            action.onFailed();
        }
    } catch (e) {
        yield put({
            type: SOCIAL_MEDIA_REGISTRATION_FAILED,
        });
        action.onFailed();
    }
}

export function* tikTokRegistration(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.account.tikTokRegistration(payload);
        });

        if (response.success && response.data && response.data.token) {
            yield put({
                type: REGISTRATION_SUCCESS,
            });

            yield put({
                type: REMOVE_DATA_REGISTER_MODAL,
            });

            setToken(response.data.token);

            yield put({
                type: LOGIN_SUCCESS,
                payload: {
                    token: response.data.token,
                },
            });

            yield put(getAccountInfo({ notifications: true }, { afterSuccess: action.onLoginSuccess || false }));

            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {
            if (action.onFailed) {
                action.onFailed();
            }
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: REGISTRATION_FAILED,
        });
        if (action.onFailed) {
            action.onFailed();
        }
    }
}

export function* accountInfo({ payload = {}, meta = {} }) {
    try {
        const groupUniversityId = yield select(getGroupUniversityId);

        const response = yield call(() => {
            return API.account.get(groupUniversityId);
        });

        if (response.success) {
            yield put({
                type: ACCOUNT_INFO_SUCCESS,
                payload: {
                    account: response.data.account,
                    notificationData: response.data.account.notificationData,
                    groupId: groupUniversityId,
                    authCode: payload.authCode,
                },
            });
            if (response.data.account && response.data.account.key === 'univercity-student') {
                bindUserChannel();
            }
            if (meta.afterSuccess) {
                meta.afterSuccess(response.data.account);
            }
            if (payload.notifications) {
                yield put({
                    type: GET_DIALOGS_IDS,
                    payload: {},
                });
            }
        } else {
            yield put({
                type: ACCOUNT_INFO_FAILED,
            });
        }
    } catch (e) {
        yield put({
            type: ACCOUNT_INFO_FAILED,
        });
    }
}

export function* authByCode(action) {
    const onSuccess = action.onSuccess;
    const payload = action.payload;
    try {
        let universityId = getUniversityId();
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }

        const response = yield call(() => {
            return API.account.authByCode(payload.authCode, universityId, universityGroupId);
        });

        if (response.success && response.data) {
            onSuccess(response.data.token);
        } else {
            yield put({
                type: ACCOUNT_INFO_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: ACCOUNT_INFO_FAILED,
        });
    }
}

export function* authBySpeakerCode(action) {
    const onSuccess = action.onSuccess;
    const onFail = action.onFail;
    const payload = action.payload;

    try {
        let universityId = getUniversityId();
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }
        payload.requestData.universityId = universityId;
        payload.requestData.universityGroupId = universityGroupId;

        const response = yield call(() => {
            return API.LiveStreams.joinExternalSpeaker(payload.slug, payload.requestData);
        });

        if (response.success && response.data) {
            onSuccess(response.data.token);

            yield put({
                type: EXTERNAL_SPEAKER_AUTH,
            });
        } else {
            yield put({
                type: ACCOUNT_INFO_FAILED,
            });
            onFail();
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: ACCOUNT_INFO_FAILED,
        });
    }
}

export function* saveAcceptedTerms({ payload, meta }) {
    try {
        const groupUniversityId = yield select(getGroupUniversityId);

        const response = yield call(() => {
            return API.account.saveAcceptedTerms(payload, groupUniversityId);
        });

        if (response.success) {
            yield put(getAccountInfo({ notificationData: true }));
            meta.onSuccess();
        }
    } catch (e) {
        console.log(e);
    }
    meta.onFinish();
}

export function* universityQuestions({ payload, meta }) {
    try {
        const response = yield call(() => {
            return API.account.universityQuestions(payload.universityId);
        });

        if (response.success) {
            const questions =
                response.data && response.data.questions
                    ? response.data.questions.filter(
                          (item) =>
                              (payload.onlyMandatory && item.is_required) ||
                              (!payload.onlyMandatory && !item.is_required)
                      )
                    : [];
            yield put({
                type: UNIVERSITY_QUESTIONS_SUCCESS,
                payload: {
                    questions: questions,
                    universityId: payload.universityId,
                    onlyMandatory: payload.onlyMandatory,
                },
            });
            if (!payload.onlyMandatory && questions.length === 0) {
                let engagementId = payload.engagementId;
                let engagementType = payload.engagementType;
                if (payload.data) {
                    engagementId = payload.data.id;
                    engagementType = payload.data.type;
                } else {
                    yield put({
                        type: SAVE_UNIVERSITY_QUESTIONS,
                        payload: {
                            universityId: payload.universityId,
                            answers: [],
                            engagementId,
                            engagementType,
                        },
                    });
                }
            }
            yield put({
                type: SET_STREAM_UNI_DATA,
                payload: {
                    university: response.data && response.data.university ? response.data.university : null,
                },
            });
            if (meta && meta.onSuccess) meta.onSuccess(questions);
            if (payload.onlyMandatory && payload.data) {
                const isIosOrAndroidDevice = isIosOrAndroid();
                const isSingleSso = isSingleSsoAuth();
                if (isIosOrAndroidDevice) {
                    localStorageWrapper.setItem('RegistrationData', JSON.stringify(payload.data));
                    yield put({
                        type: ACCOUNT.SET_REGISTRATION_DATA,
                        payload: payload.data,
                    });
                    NavigateWrapper.navigate(`${getCommonRoutePath()}/interaction/sign-up`);
                } else {
                    if (isSingleSso) {
                        openSingleSsoWindow(payload.data);
                    } else {
                        yield put({
                            type: OPEN_REGISTRATION_POPUP,
                            payload: payload.data,
                        });
                    }
                }
            }
        } else {
            yield put({
                type: UNIVERSITY_QUESTIONS_FAILED,
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: UNIVERSITY_QUESTIONS_FAILED,
        });
    }
}

export function* saveUniversityQuestions(action) {
    try {
        const groupUniversityId = yield select(getGroupUniversityId);

        const response = yield call(() => {
            return API.account.saveUniversityQuestions(action.payload, groupUniversityId);
        });

        if (response.success) {
            yield put({
                type: SAVE_UNIVERSITY_QUESTIONS_SUCCESS,
            });
            yield put(getAccountInfo());
            if (action.onSuccess) action.onSuccess();
        } else {
            yield put({
                type: SAVE_UNIVERSITY_QUESTIONS_FAILED,
            });
            if (action.onError) action.onError();
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: SAVE_UNIVERSITY_QUESTIONS_FAILED,
        });
        if (action.onError) action.onError();
    }
}

export function* mandatoryQuestions() {
    try {
        const groupUniversityId = yield select(getGroupUniversityId);

        const response = yield call(() => {
            return API.account.mandatoryQuestions(groupUniversityId);
        });

        if (response.success) {
            yield put({
                type: MANDATORY_QUESTIONS_SUCCESS,
                payload: {
                    questions: response.data,
                },
            });
        } else {
            yield put({
                type: MANDATORY_QUESTIONS_FAILED,
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: MANDATORY_QUESTIONS_FAILED,
        });
    }
}

export function* saveMandatoryQuestions(action) {
    try {
        const response = yield call(() => {
            return API.account.saveMandatoryQuestions(action.payload);
        });

        if (response.success) {
            yield put({
                type: SAVE_MANDATORY_QUESTIONS_SUCCESS,
            });
            yield put(getAccountInfo());
        } else {
            yield put({
                type: SAVE_MANDATORY_QUESTIONS_FAILED,
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: SAVE_MANDATORY_QUESTIONS_FAILED,
        });
    }
}

export function* universityQuestionsData({ payload, meta }) {
    try {
        const response = yield call(() => {
            return API.account.universityQuestionsData(payload.universityId);
        });

        const questions =
            response.data && response.data.questions
                ? response.data.questions.filter(
                      (item) =>
                          (payload.onlyMandatory && item.is_required) || (!payload.onlyMandatory && !item.is_required)
                  )
                : [];
        if (response.success) {
            if (payload.activeForumChatData) {
                store.dispatch({
                    type: SET_ACTIVE_FORUM_CHAT,
                    payload: {
                        activeForumChatData: payload.activeForumChatData,
                    },
                });
            }
            if (questions.length > 0) {
                yield put({
                    type: UNIVERSITY_QUESTIONS_SUCCESS,
                    payload: {
                        questions,
                        universityId: payload.universityId,
                    },
                });
            } else {
                yield put({
                    type: SAVE_UNIVERSITY_QUESTIONS,
                    payload: {
                        universityId: payload.universityId,
                        answers: [],
                        engagementId: payload?.activeForumChatData?.forumChat?.id,
                        engagementType: 'forum',
                    },
                });
                saveAnsweredQuestionsUniversity(payload.universityId);
            }
        }
        meta.onFinish(questions);
    } catch (e) {
        console.log(e);
        meta.onFinish();
    }
}

export function* saveUtmData(action) {
    const payload = action.payload;
    try {
        yield call(() => {
            return API.account.saveUtmData(payload.universityId, payload.utmData);
        });
    } catch (e) {
        console.log(e);
    }
}

export function* registration(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.account.registration(payload);
        });

        if (response.success && response.data && response.data.token) {
            yield put({
                type: REGISTRATION_SUCCESS,
            });

            yield put({
                type: REMOVE_DATA_REGISTER_MODAL,
            });

            setToken(response.data.token);

            yield put({
                type: LOGIN_SUCCESS,
                payload: {
                    token: response.data.token,
                },
            });

            yield put(getAccountInfo({ notifications: true }, { afterSuccess: action.onLoginSuccess || false }));

            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {
            if (action.onFailed) {
                action.onFailed();
            }
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: REGISTRATION_FAILED,
        });
        if (action.onFailed) {
            action.onFailed();
        }
    }
}

export function* getUserCountry() {
    try {
        const response = yield call(() => {
            return API.account.getUserCountry();
        });

        yield put({
            type: GET_USER_COUNTRY_SUCCESS,
            payload: response.data,
        });
    } catch (e) {
        console.log(e);
    }
}

export function* getCountries() {
    try {
        const response = yield call(() => {
            return API.account.getCountries();
        });

        yield put({
            type: GET_COUNTRIES_SUCCESS,
            payload: {
                countries: response.data.tags,
            },
        });
    } catch (e) {
        console.log(e);
    }
}

export function* getNationalities(action) {
    const { payload } = action;
    try {
        const response = yield call(() => {
            return API.account.getNationalities(payload.universityId);
        });

        const [firstElement, ...rest] = response.data;
        response.data = [...rest, firstElement];

        yield put({
            type: GET_NATIONALITIES_SUCCESS,
            payload: {
                nationalities: response.data,
            },
        });
    } catch (e) {
        console.log(e);
    }
}

export function* accountChangeAvatar(action) {
    const payload = action.payload;
    try {
        yield call(() => {
            return API.account.changeAvatar(payload.avatar);
        });
        yield put({ type: ACCOUNT_INFO });
    } catch (e) {
        console.log(e);
    }
}

export function* startRecoveryPassword(action) {
    const payload = action.payload;

    try {
        const universityId = yield select(getCurrentUniversityId);
        const groupUniversityId = yield select(getGroupUniversityId);

        const data = {
            email: payload.email,
        };

        if (groupUniversityId) {
            data.groupId = groupUniversityId;
        } else if (universityId) {
            data.universityId = universityId;
        }

        const response = yield call(() => {
            return API.account.startRecovery(data);
        });

        if (response.success) {
            action.onFinish(true);
        } else {
            action.onFinish();
        }
    } catch (err) {
        action.onFinish();
    }
}

export function* endRecoveryPassword(action) {
    const payload = action.payload;

    try {
        let universityId = getUniversityId();
        const universityGroupId = yield select(getGroupUniversityId);
        if (universityGroupId) {
            universityId = null;
        }
        const response = yield call(() => {
            return API.account.endRecovery(
                payload.user_id,
                payload.hex,
                payload.new_password,
                payload.confirm_password,
                universityId,
                universityGroupId
            );
        });

        if (response.success) {
            action.onFinish(response.data);
        } else {
            action.onFinish();
        }
    } catch (err) {
        action.onFinish();
    }
}

export function* getUniversitySsoData(action) {
    const { payload, meta } = action;

    try {
        const universityGroupId = yield select(getGroupUniversityId);
        const response = yield call(() => {
            return API.account.getUniversitySsoData(payload.email, payload.universityId, universityGroupId);
        });

        if (response.success) {
            meta.onSuccess(response.data.appDomain, response.data.appClientId, response.data.isEncryptionOn);
        } else {
            meta.onFailed();
        }
    } catch (error) {
        console.log(error);
        meta.onFailed();
    }
}

export function* getExperienceBasedQuestions() {
    try {
        const response = yield call(() => {
            return API.widget.getExperienceBasedQuestions();
        });

        if (response.success) {
            yield put({
                type: ACCOUNT.GET_EXPERIENCE_BASED_QUESTIONS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* accountLogout({ payload, meta }) {
    try {
        const universityId = payload.universityId || (yield select(getCurrentUniversityId));
        const groupUniversityId = payload.universityGroupId || (yield select(getGroupUniversityId));

        const data = {
            token: getToken() || payload.token,
        };
        if (groupUniversityId) {
            data.universityGroupId = groupUniversityId;
        } else if (universityId) {
            data.universityId = universityId;
        }
        if (data.token) {
            yield call(() => {
                return API.account.logout(data);
            });
        }

        removeToken();
        localStorageWrapper.removeItem('pusherTransportTLS');
        window.document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        unbindDialogs();
        unbindUserChannel();
        if (payload && payload.redirect) {
            window.location = window.location.pathname.split('/')[0] + getCommonRoutePath() + '/interaction/sign-in';
        }
        if (meta.onSuccess) meta.onSuccess();
    } catch (e) {
        console.log(e);
    }
}
