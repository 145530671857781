import HttpService from '../../system/HttpServices';
import { IsMobile } from '../../helpers/Helpers';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        searchItems,
        preFilterItems,
        searchType,
        currentLiveStreamsIds,
        limit = 10,
        offset = 0,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            liveStreamsNotInIds: currentLiveStreamsIds,
            searchType,
            limit: limit,
            offset: 0, // if random then 0 with groupChatsNotInIds
            countryIds,
            profileTypeIds,
            subjectIds,
        };

        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }

        return HttpService.post({
            path: '/liveStreams/getLiveStreams',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },

    getLiveStream: (eventSlug) => {
        return HttpService.post({ path: '/liveStreams/' + eventSlug });
    },

    getLiveStreamProspects: (eventSlug, limit, offset) => {
        return HttpService.get({ path: '/liveStreams/' + eventSlug + '/prospects', data: { limit, offset } });
    },
    joinExternalSpeaker: (eventSlug, data) => {
        data.accessType = IsMobile() ? 'mobile_web' : 'web';
        return HttpService.post({ path: '/liveStreams/' + eventSlug + '/joinSpeaker', data, withCredentials: true });
    },
    getLiveStreamBySlug: (eventSlug) => {
        return HttpService.get({ path: '/liveStreams/' + eventSlug });
    },
    trackLiveStreamTime: (data) => {
        return HttpService.post({ path: '/LiveStreams/timeTrack', data });
    },
    trackLiveStreamWatch: (data) => {
        return HttpService.post({ path: '/LiveStreams/watch', data });
    },
    trackLiveStreamPlay: (data) => {
        return HttpService.post({ path: '/LiveStreams/play', data });
    },
};
