import API from '../../../api';
import { call, put } from 'redux-saga/effects';
import {
    STUDENT_INFO_SUCCESS,
    STUDENT_POST_LIKED_SUCCESS,
    STUDENT_FAQ_ANSWER_LIKED_SUCCESS,
    STUDENT_INFO_FAIL,
} from '../../actions/studentActions';

export function* studentInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.students.getOne(payload.student_id);
        });

        if (response.success) {
            yield put({
                type: STUDENT_INFO_SUCCESS,
                payload: {
                    user: response.data.user,
                    posts: response.data.posts,
                    faqs: response.data.faqs,
                },
            });
            if (action.afterSuccess) {
                action.afterSuccess(response.data.user);
            }
        } else {
            yield put({
                type: STUDENT_INFO_FAIL,
                payload: {},
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: STUDENT_INFO_FAIL,
            payload: {},
        });
    }
}

export function* universityData(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.students.getUniversityData(payload.university_id);
        });

        if (response.success) {
            yield put({
                type: STUDENT_INFO_SUCCESS,
                payload: {
                    user: { university: response.data.university },
                    posts: response.data.posts,
                    faqs: response.data.faqs,
                    universityProfile: true,
                },
            });
        } else {
            yield put({
                type: STUDENT_INFO_FAIL,
                payload: {
                    universityProfile: true,
                },
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: STUDENT_INFO_FAIL,
            payload: {
                universityProfile: true,
            },
        });
    }
}

export function* studentPostsLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.liked(payload.post_id, payload.unlike, payload.postWatched);
        });

        if (response.success) {
            yield put({
                type: STUDENT_POST_LIKED_SUCCESS,
                payload: {
                    postId: payload.post_id,
                    unlike: payload.unlike,
                },
            });
        }

        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* studentFaqAnswerLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.answerLiked(payload.answer_id, payload.faq_id, payload.unlike, payload.faqWatched);
        });

        if (response.success) {
            yield put({
                type: STUDENT_FAQ_ANSWER_LIKED_SUCCESS,
                payload: {
                    answerId: payload.answer_id,
                    faqId: payload.faq_id,
                    unlike: payload.unlike,
                },
            });
        }

        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}
