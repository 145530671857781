import { LOCAL_STORAGE_KEYS } from 'src/common/localStorageKeys';
import { tryStringifyJSON } from 'src/common/utils/jsonUtils';
import { isCommunity } from './is-community';
import { localStorageWrapper } from './storageFactory';
export function setToken(token) {
    localStorageWrapper.setItem(LOCAL_STORAGE_KEYS.TAP_FEED_ACCOUNT_TOKEN, JSON.stringify(token));
}
export function getToken() {
    try {
        var token = localStorageWrapper.getItem(LOCAL_STORAGE_KEYS.TAP_FEED_ACCOUNT_TOKEN);
        return token ? JSON.parse(token) : null;
    }
    catch (e) {
        return null;
    }
}
export function isTokenExist() {
    return !!localStorageWrapper.getItem(LOCAL_STORAGE_KEYS.TAP_FEED_ACCOUNT_TOKEN);
}
export function removeToken() {
    localStorageWrapper.removeItem(LOCAL_STORAGE_KEYS.TAP_FEED_ACCOUNT_TOKEN);
}
export function setCommunityToken(token) {
    localStorageWrapper.setItem(LOCAL_STORAGE_KEYS.COMMUNTY_ACCOUNT_TOKEN, tryStringifyJSON(token));
}
export function getCommunityToken() {
    try {
        var token = localStorageWrapper.getItem(LOCAL_STORAGE_KEYS.COMMUNTY_ACCOUNT_TOKEN);
        return token ? JSON.parse(token) : null;
    }
    catch (e) {
        return null;
    }
}
export function removeCommunityToken() {
    localStorageWrapper.removeItem(LOCAL_STORAGE_KEYS.COMMUNTY_ACCOUNT_TOKEN);
}
export function getTokenType() {
    return isCommunity() ? LOCAL_STORAGE_KEYS.COMMUNTY_ACCOUNT_TOKEN : LOCAL_STORAGE_KEYS.TAP_FEED_ACCOUNT_TOKEN;
}
