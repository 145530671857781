import API from '../../../api';
import { call, put } from 'redux-saga/effects';
import { WIDGET_INFO_SUCCESS, SOCIAL_PROOF_SUCCESS } from '../../actions/widgetActions';

export function* widgetInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.widget.get(payload.university_id, payload.groupId, payload.preFilterItems, payload.contentTypes);
        });

        if (response.success) {
            yield put({
                type: WIDGET_INFO_SUCCESS,
                payload: {
                    data: response.data,
                },
            });
            if (action.onSuccess) {
                action.onSuccess(response.data);
            }
        }
    } catch (e) {
        console.log(e);
    }
    if (action.onFinish) {
        action.onFinish();
    }
}

export function* singleUniversityWidgetInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.widget.get(payload.university_id);
        });

        if (response.success) {
            action.onSuccess(response.data);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* socialProof(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.widget.getSocialProofInfo(payload.university_id);
        });

        if (response.success) {
            yield put({
                type: SOCIAL_PROOF_SUCCESS,
                payload: {
                    data: response.data,
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}
