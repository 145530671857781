import API from '../../../api';
import { call, put } from 'redux-saga/effects';
import { FAQ_INFO_SUCCESS, FAQ_INFO_FAIL, FAQ_ANSWER_LIKED_SUCCESS } from '../../actions/faqActions';

export function* faqInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.getFaq(payload.faq_id);
        });

        if (response.success) {
            yield put({
                type: FAQ_INFO_SUCCESS,
                payload: {
                    faq: response.data.faq,
                },
            });
        } else if (!response.cancelled) {
            yield put({
                type: FAQ_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: FAQ_INFO_FAIL,
        });
    }
}

export function* faqWatched(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.watched(payload.faq_id);
        });
    } catch (e) {
        console.log(e);
    }
}

export function* faqAnswerLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.answerLiked(payload.answer_id, payload.faq_id, payload.unlike, payload.faqWatched);
        });

        if (response.success) {
            yield put({
                type: FAQ_ANSWER_LIKED_SUCCESS,
                payload: {
                    answerId: payload.answer_id,
                    unlike: payload.unlike,
                },
            });
        }

        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}
