import { createAction } from '@reduxjs/toolkit';
export var WIDGET_INFO = 'WIDGET_INFO';
export var WIDGET_INFO_SUCCESS = 'WIDGET_INFO_SUCCESS';
export var WIDGET_LOAD = 'WIDGET_LOAD';
export var SOCIAL_PROOF = 'SOCIAL_PROOF';
export var SOCIAL_PROOF_SUCCESS = 'SOCIAL_PROOF_SUCCESS';
export var SET_UNI_GROUP = 'SET_UNI_GROUP';
export var SET_TITLES = 'SET_TITLES';
export var CHAT_HEADER_SET = 'CHAT_HEADER_SET';
export var CHECK_IF_MOBILE = 'CHECK_IF_MOBILE';
export var SET_UTM_DATA = 'SET_UTM_DATA';
export var SINGLE_UNIVERSITY_WIDGET_INFO = 'SINGLE_UNIVERSITY_WIDGET_INFO';
export var setUniGroup = function (universityIds) {
    if (universityIds === void 0) { universityIds = []; }
    return ({
        type: SET_UNI_GROUP,
        payload: {
            universityIds: universityIds,
        },
    });
};
export var setTitles = function (titles) {
    if (titles === void 0) { titles = {}; }
    return ({
        type: SET_TITLES,
        payload: titles,
    });
};
export var setIsMobile = createAction('media-change');
