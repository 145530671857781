import { FAQ_INFO_SUCCESS, FAQ_INFO_FAIL, FAQ_ANSWER_LIKED_SUCCESS } from '../actions/faqActions';

const initialState = {
    item: null,
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case FAQ_INFO_SUCCESS:
            return {
                ...state,
                item: payload.faq,
                isLoading: false,
            };

        case FAQ_INFO_FAIL:
            return {
                ...state,
                item: null,
                isLoading: false,
            };

        case FAQ_ANSWER_LIKED_SUCCESS:
            let currentItem = state.item ? { ...state.item } : null;
            for (let answer in currentItem.answers) {
                if (currentItem.answers[answer].id === payload.answerId) {
                    let like_count = currentItem.answers[answer].like_count;
                    currentItem.answers[answer].like_count = payload.unlike
                        ? like_count > 0
                            ? like_count - 1
                            : 0
                        : like_count + 1;
                }
            }
            return {
                ...state,
                item: currentItem,
            };

        default:
            return state;
    }
}
