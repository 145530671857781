import {
    GET_FILTER_NEW_OPTIONS_SUCCESS,
    GET_FILTER_OPTIONS_FAIL,
    GET_FILTER_OPTIONS_REQUEST_LOADING,
    GET_FILTER_OPTIONS_SUCCESS,
} from '../actions/filtersActions';

const initialState = {
    types: [],
    options: {
        isLoading: false,
    },
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_FILTER_OPTIONS_REQUEST_LOADING:
            return {
                ...state,
                options: {
                    ...state.options,
                    ...payload.options,
                },
            };

        case GET_FILTER_OPTIONS_SUCCESS:
            return {
                ...state,
                options: {
                    ...state.options,
                    ...payload.options,
                },
            };

        case GET_FILTER_NEW_OPTIONS_SUCCESS:
            return {
                ...state,
                options: {
                    ...payload.options,
                },
            };

        case GET_FILTER_OPTIONS_FAIL:
            return {
                ...state,
                options: {
                    ...state.options,
                    ...payload.options,
                },
            };

        default:
            return state;
    }
}
