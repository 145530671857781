import { httpServices } from 'src/system/HttpServices/HttpService';
export default {
    getAllChats: function (data, signal) {
        return httpServices.get({
            path: '/dialogs',
            withCredentials: true,
            requestType: 'community',
            signal: signal,
            data: data,
        });
    },
    getUserChats: function (data, signal) {
        return httpServices.get({
            path: '/dialogs/my',
            withCredentials: true,
            requestType: 'community',
            signal: signal,
            data: data,
        });
    },
    getChatInfo: function (data, signal) {
        return httpServices.get({
            path: "/dialogs/".concat(data.id),
            withCredentials: true,
            requestType: 'community',
            signal: signal,
            data: { spaceId: data.spaceId },
        });
    },
    getPersonalChatInfo: function (data, signal) {
        return httpServices.get({
            path: "/dialogs/personal/".concat(data.id),
            withCredentials: true,
            requestType: 'community',
            signal: signal,
            data: { spaceId: data.spaceId },
        });
    },
    getMessages: function (id, data, signal) {
        return httpServices.get({
            path: "/dialogs/".concat(id, "/messages"),
            withCredentials: true,
            requestType: 'community',
            data: data,
            signal: signal,
        });
    },
    sendMessage: function (id, data) {
        return httpServices.post({
            path: "/dialogs/".concat(id, "/messages"),
            withCredentials: true,
            requestType: 'community',
            data: data,
        });
    },
    markChatAsRead: function (id) {
        return httpServices.post({
            path: "/dialogs/".concat(id, "/messages/view"),
            withCredentials: true,
            requestType: 'community',
            data: undefined,
        });
    },
    startChatWithAmbassador: function (data) {
        return httpServices.post({
            path: '/dialogs/create/ambassador',
            withCredentials: true,
            requestType: 'community',
            data: data,
        });
    },
    startChatWithProspect: function (data) {
        return httpServices.post({
            path: '/dialogs/create/prospect',
            withCredentials: true,
            requestType: 'community',
            data: data,
        });
    },
    joinChat: function (id, spaceId) {
        return httpServices.post({
            path: "/dialogs/".concat(id, "/join"),
            withCredentials: true,
            requestType: 'community',
            data: { spaceId: spaceId },
        });
    },
    leaveChat: function (id, spaceId) {
        return httpServices.delete({
            path: "/dialogs/".concat(id, "/leave"),
            withCredentials: true,
            requestType: 'community',
            data: { spaceId: spaceId },
        });
    },
};
