export const POSTS_INFO = 'POSTS_INFO';
export const POSTS_INFO_SUCCESS = 'POSTS_INFO_SUCCESS';
export const POSTS_INFO_FAIL = 'POSTS_INFO_FAIL';

export const REFRESH_POSTS_DATA = 'REFRESH_POSTS_DATA';

export const LOAD_NEXT_POSTS = 'LOAD_NEXT_POSTS';
export const LOAD_NEXT_POSTS_SUCCESS = 'LOAD_NEXT_POSTS_SUCCESS';

export const POSTS_LIKED = 'POSTS_LIKED';
export const POSTS_LIKED_SUCCESS = 'POSTS_LIKED_SUCCESS';

export const POSTS_SHARED = 'POSTS_SHARED';

export const fetchData = (options) => ({
    type: LOAD_NEXT_POSTS,
    payload: { ...options },
});
