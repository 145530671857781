export const FORUM_CHATS_INFO = 'FORUM_CHATS_INFO';
export const FORUM_CHATS_INFO_SUCCESS = 'FORUM_CHATS_INFO_SUCCESS';
export const FORUM_CHATS_INFO_FAIL = 'FORUM_CHATS_INFO_FAIL';

export const REFRESH_FORUM_CHATS_DATA = 'REFRESH_FORUM_CHATS_DATA';

export const LOAD_NEXT_FORUM_CHATS = 'LOAD_NEXT_FORUM_CHATS';
export const LOAD_NEXT_FORUM_CHATS_SUCCESS = 'LOAD_NEXT_FORUM_CHATS_SUCCESS';
export const GET_FORUM_MESSAGES = 'GET_FORUM_MESSAGES';
export const GET_FORUM_MESSAGES_SUCCESS = 'GET_FORUM_MESSAGES_SUCCESS';
export const SET_ACTIVE_FORUM_CHAT = 'SET_ACTIVE_FORUM_CHAT';

export const fetchData = (options) => ({
    type: LOAD_NEXT_FORUM_CHATS,
    payload: { ...options },
});
