/* ISC License (ISC). Copyright 2017 Michal Zalecki */
export function storageFactory(getStorage) {
    let inMemoryStorage = {};

    let isSupportedCache = null;

    function isSupported() {
        if (isSupportedCache !== null) {
            return isSupportedCache;
        }

        try {
            const testKey = '__some_random_key_you_are_not_going_to_use__';
            getStorage().setItem(testKey, testKey);
            getStorage().removeItem(testKey);

            isSupportedCache = true;
            return true;
        } catch (e) {
            isSupportedCache = false;
            return false;
        }
    }

    function clear() {
        if (isSupported()) {
            getStorage().clear();
        } else {
            inMemoryStorage = {};
        }
    }

    function getItem(name) {
        if (isSupported()) {
            return getStorage().getItem(name);
        }
        if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
            return inMemoryStorage[name];
        }

        return null;
    }

    function key(index) {
        if (isSupported()) {
            return getStorage().key(index);
        } else {
            return Object.keys(inMemoryStorage)[index] || null;
        }
    }

    function removeItem(name) {
        if (isSupported()) {
            getStorage().removeItem(name);
        } else {
            delete inMemoryStorage[name];
        }
    }

    function setItem(name, value) {
        if (isSupported()) {
            getStorage().setItem(name, value);
        } else {
            inMemoryStorage[name] = String(value);
        }
    }

    function length() {
        if (isSupported()) {
            return getStorage().length;
        } else {
            return Object.keys(inMemoryStorage).length;
        }
    }

    function getStorageData() {
        if (isSupported()) {
            return getStorage();
        } else {
            return {};
        }
    }

    return {
        getItem,
        setItem,
        removeItem,
        clear,
        key,
        getStorageData,
        get length() {
            return length();
        },
    };
}

export const localStorageWrapper = storageFactory(() => localStorage);
export const sessionStorageWrapper = storageFactory(() => sessionStorage);
