const ProspectPrivateChatModel = (data = {}) => {
    const {
        id,
        archived,
        closed,
        admin_closed,
        last_activity,
        type,
        avatar_id,
        color,
        reported,
        created_at,
        description,
        new_messages,
        last_message,
        prospect,
        isProspectBlocked,
        avatar,
        is_enabled,
        university,
        university_id,
    } = data;
    return {
        id,
        archived,
        closed,
        admin_closed,
        last_activity,
        type,
        avatar_id,
        color,
        reported,
        created_at,
        description,
        new_messages,
        last_message,
        prospect,
        isProspectBlocked,
        avatar,
        is_enabled,
        university,
        university_id,
    };
};

export default ProspectPrivateChatModel;
