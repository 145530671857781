import API from '../../../api';
import { call, put, select, delay } from 'redux-saga/effects';
import {
    LOAD_NEXT_POSTS_SUCCESS,
    POSTS_INFO_SUCCESS,
    POSTS_LIKED_SUCCESS,
    REFRESH_POSTS_DATA,
} from '../../actions/postsActions';
import { SHOW_DATA } from '../../actions/mainActions';
import { LIKED_NEXT_ITEMS_SUCCESS } from '../../actions/randomItems';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getSearchType = (state) => state.widget.searchType;

export function* postsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.posts.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                [],
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: POSTS_INFO_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
            yield put({
                type: REFRESH_POSTS_DATA,
                payload: { refresh: false },
            });

            yield delay(60000);

            yield put({
                type: REFRESH_POSTS_DATA,
                payload: { refresh: true },
            });

            if (response.data.posts.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showPosts',
                    },
                });
            }
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* loadNextPostsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.posts.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_POSTS_SUCCESS,
                payload: {
                    posts: response.data.posts,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* postsLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.liked(payload.post_id, payload.unlike, payload.postWatched);
        });

        if (response.success) {
            if (payload.randomPage) {
                yield put({
                    type: LIKED_NEXT_ITEMS_SUCCESS,
                    payload: {
                        postId: payload.post_id,
                        unlike: payload.unlike,
                    },
                });
            } else {
                yield put({
                    type: POSTS_LIKED_SUCCESS,
                    payload: {
                        postId: payload.post_id,
                        unlike: payload.unlike,
                    },
                });
            }
        }

        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* postsShared(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.shared(payload.post_id);
        });

        if (response.success) {
        }
    } catch (e) {
        console.log(e);
    }
}
