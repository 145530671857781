import { ACCOUNT } from '../../actions/accountActions';

const initialState = {
    isOpened: false,
    university: null,
    id: null,
    name: '',
    type: 'chat',
    askedQuestion: null,
    items: null,
    isSkipped: false,
    universitySettings: null,
};

export default function (state = initialState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case ACCOUNT.OPEN_EXPERIENCE_BASED_QUESTIONS_POPUP: {
            const { university, id, name, type, universitySettings } = payload || {};
            return {
                ...state,
                isOpened: true,
                university,
                id,
                name,
                type: type || 'chat',
                universitySettings: universitySettings ? universitySettings : null,
            };
        }

        case ACCOUNT.GET_EXPERIENCE_BASED_QUESTIONS_SUCCESS: {
            return {
                ...state,
                items: payload,
            };
        }

        case ACCOUNT.SAVE_EXPERIENCE_BASED_QUESTION: {
            return {
                ...state,
                askedQuestion: payload.questionToAsk,
            };
        }

        case ACCOUNT.SKIP_EXPERIENCE_BASED_QUESTION: {
            return {
                ...state,
                isSkipped: payload.isSkipped,
            };
        }

        case ACCOUNT.CLOSE_EXPERIENCE_BASED_QUESTIONS_POPUP: {
            return {
                ...state,
                isOpened: false,
                university: null,
                id: null,
                name: '',
                type: 'chat',
                askedQuestion: '',
            };
        }

        default:
            return state;
    }
}
