import API from '../../../api';
import { call, put } from 'redux-saga/effects';
import { POST_INFO_FAIL, POST_INFO_SUCCESS, POST_LIKED_SUCCESS } from '../../actions/postActions';

export function* postInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.getPost(payload.post_id);
        });

        if (response.success) {
            yield put({
                type: POST_INFO_SUCCESS,
                payload: {
                    post: response.data.post,
                },
            });
            if (action.onSuccess) action.onSuccess(response.data.post);
        } else if (!response.cancelled) {
            yield put({
                type: POST_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: POST_INFO_FAIL,
        });
    }
}

export function* postWatched(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.watched(payload.post_id);
        });
    } catch (e) {
        console.log(e);
    }
}

export function* postLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.posts.liked(payload.post_id, payload.unlike);
        });

        if (response.success) {
            yield put({
                type: POST_LIKED_SUCCESS,
                payload: {
                    unlike: payload.unlike,
                },
            });
        }

        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}
