import {
    NEW_CHATS_MESSAGES,
    NEW_CHATS_MESSAGES_SUCCESS,
    NEW_CHATS_MESSAGES_FAILED,
    NEW_MESSAGES_COUNTER_SUCCESS,
    ADD_PLUS_ONE_TO_CHAT_COUNTER,
} from '../actions/chatBoxActions';

const initialState = {
    isLoading: true,
    items: null,
    newMessages: 0,
};

export default function (state = initialState, action) {
    const payload = action.payload;

    switch (action.type) {
        case NEW_MESSAGES_COUNTER_SUCCESS: {
            return {
                ...state,
                newMessages: payload.newMessages,
            };
        }

        case NEW_CHATS_MESSAGES_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            };
        }

        case NEW_CHATS_MESSAGES: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case NEW_CHATS_MESSAGES_FAILED: {
            return {
                ...state,
                items: [],
                isLoading: false,
            };
        }

        case ADD_PLUS_ONE_TO_CHAT_COUNTER: {
            return {
                ...state,
                newMessages: state.newMessages + 1,
            };
        }

        default:
            return state;
    }
}
