import HttpService from '../../system/HttpServices';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        searchItems,
        preFilterItems,
        searchType,
        currentPostsIds,
        limit = 10,
        offset = 0,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            postsNotInIds: currentPostsIds,
            searchType,
            limit: limit,
            offset: 0, // if random then 0 with postsNotInIds
            orderBy: 'sharing_at',
            order: 'DESC',
            countryIds,
            profileTypeIds,
            subjectIds,
        };
        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }

        return HttpService.post({
            path: '/posts/getPosts',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },

    getPost: (post_id) => {
        return HttpService.get({ path: '/posts/' + post_id });
    },

    watched: (post_id) => {
        return HttpService.post({ path: '/posts/' + post_id + '/watch' });
    },

    liked: (post_id, unlike, postWatched) => {
        return HttpService.post({ path: '/posts/' + post_id + '/like/', data: { unlike, postWatched } });
    },

    shared: (post_id) => {
        return HttpService.post({ path: '/posts/' + post_id + '/share' });
    },
};
