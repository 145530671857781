import { takeEvery } from 'redux-saga/effects';
import { MAIN_INFO } from '../actions/mainActions';
import { mainInfo, getNextItems } from './main';
import { GROUP_CHAT_WATCHED, GROUP_CHATS_INFO, LOAD_NEXT_GROUP_CHATS } from '../actions/groupChatsActions';
import { groupChatsInfo, groupChatWatched, loadNextGroupChatsInfo } from './groupChats';
import { FORUM_CHATS_INFO, LOAD_NEXT_FORUM_CHATS, GET_FORUM_MESSAGES } from '../actions/forumChatsActions';
import { forumChatsInfo, loadNextForumChatsInfo, getForumMessages } from './forumChats';

import { GET_LIVE_STREAMS, LOAD_NEXT_LIVE_STREAMS, GET_LIVE_STREAM_BY_SLUG } from '../actions/liveStreamsActions';
import {
    GET_LIVE_STREAM,
    GET_LIVE_STREAM_PROSPECTS,
    GET_NEXT_LIVE_STREAM_PROSPECTS,
    TRACK_LIVE_STREAM_TIME,
    TRACK_LIVE_STREAM_WATCH,
    TRACK_LIVE_STREAM_PLAY,
} from '../actions/currentStreamActions';
import {
    getLiveStreams,
    loadNextLiveStreams,
    getLiveStream,
    getLiveStreamProspects,
    getNextLiveStreamProspects,
    getLiveStreamBySlug,
    trackLiveStreamTime,
    trackLiveStreamWatch,
    trackLiveStreamPlay,
} from './liveStreamsSaga';

import { STUDENTS_INFO, LOAD_NEXT_STUDENTS } from '../actions/studentsActions';
import { studentsInfo, loadNextStudentsInfo } from './students';
import { STUDENT_INFO, UNIVERSITY_DATA, STUDENT_POST_LIKED, STUDENT_FAQ_ANSWER_LIKED } from '../actions/studentActions';
import { studentInfo, universityData, studentPostsLiked, studentFaqAnswerLiked } from './student';
import { USERS_INFO } from '../actions/usersActions';
import { usersInfo } from './users';

import { FAQ_INFO, FAQ_WATCHED, FAQ_ANSWER_LIKED } from '../actions/faqActions';
import { faqInfo, faqWatched, faqAnswerLiked } from './faq';
import { FAQS_INFO, FAQS_ANSWER_LIKED, FAQS_SHARED, LOAD_NEXT_FAQS } from '../actions/faqsActions';
import { faqsInfo, faqsAnswerLiked, faqsShared, loadNextFaqsInfo } from './faqs';
import { POST_INFO, POST_WATCHED, POST_LIKED } from '../actions/postActions';
import { POSTS_INFO, POSTS_LIKED, POSTS_SHARED, LOAD_NEXT_POSTS } from '../actions/postsActions';
import { postInfo, postWatched, postLiked } from './post';
import { postsInfo, postsLiked, postsShared, loadNextPostsInfo } from './posts';
import { WIDGET_INFO, SOCIAL_PROOF, SINGLE_UNIVERSITY_WIDGET_INFO } from '../actions/widgetActions';
import { widgetInfo, socialProof, singleUniversityWidgetInfo } from './widget';

import { GET_NEXT_ITEMS } from '../actions/randomItems';
import { GET_FILTER_OPTIONS_REQUEST } from '../actions/filtersActions';
import { fetchFilterOptions } from './filters';

import {
    ACCOUNT,
    LOGIN,
    SSO_LOGIN,
    SSO_LOGIN_AUTH,
    TIK_TOK_CALLBACK,
    SOCIAL_MEDIA_LOGIN,
    SOCIAL_MEDIA_REGISTRATION,
    TIK_TOK_REGISTRATION,
    REGISTRATION,
    ACCOUNT_INFO,
    AUTH_BY_CODE,
    AUTH_BY_EXTERNAL_SPEAKER_CODE,
    UNIVERSITY_QUESTIONS,
    SAVE_UNIVERSITY_QUESTIONS,
    MANDATORY_QUESTIONS,
    SAVE_MANDATORY_QUESTIONS,
    SAVE_UNIVERSITY_USER_UTM_DATA,
    GET_COUNTRIES,
    GET_USER_COUNTRY,
    GET_NATIONALITIES,
    ACCOUNT_CHANGE_AVATAR,
    START_RECOVERY_PASSWORD,
    END_RECOVERY_PASSWORD,
    UNIVERSITY_QUESTIONS_DATA,
    ACCOUNT_LOGOUT,
    TIK_TOK_LOGIN,
} from '../actions/accountActions';

import {
    GROUP_DIALOGS_INFO,
    PROSPECT_DIALOGS_INFO,
    AMBASSADOR_DIALOGS_INFO,
    FLAG_DIALOG,
    CLOSE_DIALOG,
    CLOSE_AMBASSADOR_DIALOG,
    FIND_OR_CREATE_DIALOG,
    AFTER_DIALOG_REPORT,
    INVITE_USERS,
    GET_SINGLE_PROSPECT_DIALOG_INFO,
    GET_SINGLE_GROUP_DIALOG_INFO,
    GET_SINGLE_AMBASSADOR_DIALOG_INFO,
    GET_DIALOGS_IDS,
    SEND_FEEDBACK,
    LEAVE_CHAT,
} from '../actions/dialogsActions';

import { NEW_MESSAGES_COUNTER, NEW_CHATS_MESSAGES } from '../actions/chatBoxActions';

import { MESSAGES_INFO, SEND_MESSAGE, SEND_AMBASSADOR_MESSAGE, REPORT_MESSAGE } from '../actions/messagesActions';

import {
    groupDialogsInfo,
    prospectDialogsInfo,
    ambassadorDialogsInfo,
    flag,
    closeToggle,
    closeAmbassadorToggle,
    findOrCreateDialog,
    afterDialogReport,
    inviteUsers,
    getSingleProspectDialogInfo,
    getSingleGroupDialogInfo,
    getSingleAmbassadorDialogInfo,
    getDialogsIds,
    leaveChat,
    getProspectInfo,
} from './dialogsSaga';

import { currentChatInfo, sendFeedback, chatToProspect } from './dialogSaga';

import { newMessagesCounter, newChatsMessages } from './chatBoxSaga';

import {
    login,
    ssoLogin,
    ssoLoginAuth,
    tikTokCallback,
    tikTokRegistration,
    socialMediaLogin,
    socialMediaRegistration,
    registration,
    accountInfo,
    authByCode,
    authBySpeakerCode,
    universityQuestions,
    saveUniversityQuestions,
    mandatoryQuestions,
    saveMandatoryQuestions,
    saveUtmData,
    getUserCountry,
    getCountries,
    getNationalities,
    accountChangeAvatar,
    startRecoveryPassword,
    endRecoveryPassword,
    getUniversitySsoData,
    saveAcceptedTerms,
    getExperienceBasedQuestions,
    universityQuestionsData,
    accountLogout,
    tikTokLogin,
} from './accountSaga';

import { messagesInfo, send, sendAmbassadorMessage, reportMessage } from './messagesSaga';
import { CHAT_INFO, GET_PROSPECT_INFO, CHAT_TO_PROSPECT } from '../actions/currentChatActions';

function* saga() {
    yield takeEvery(MAIN_INFO, mainInfo);
    yield takeEvery(GROUP_CHATS_INFO, groupChatsInfo);
    yield takeEvery(LOAD_NEXT_GROUP_CHATS, loadNextGroupChatsInfo);
    yield takeEvery(FORUM_CHATS_INFO, forumChatsInfo);
    yield takeEvery(LOAD_NEXT_FORUM_CHATS, loadNextForumChatsInfo);
    yield takeEvery(GET_FORUM_MESSAGES, getForumMessages);

    yield takeEvery(GET_LIVE_STREAMS, getLiveStreams);
    yield takeEvery(GET_LIVE_STREAM_BY_SLUG, getLiveStreamBySlug);
    yield takeEvery(LOAD_NEXT_LIVE_STREAMS, loadNextLiveStreams);
    yield takeEvery(GET_LIVE_STREAM, getLiveStream);
    yield takeEvery(GET_LIVE_STREAM_PROSPECTS, getLiveStreamProspects);
    yield takeEvery(GET_NEXT_LIVE_STREAM_PROSPECTS, getNextLiveStreamProspects);
    yield takeEvery(TRACK_LIVE_STREAM_TIME, trackLiveStreamTime);
    yield takeEvery(TRACK_LIVE_STREAM_WATCH, trackLiveStreamWatch);
    yield takeEvery(TRACK_LIVE_STREAM_PLAY, trackLiveStreamPlay);

    yield takeEvery(STUDENTS_INFO, studentsInfo);
    yield takeEvery(LOAD_NEXT_STUDENTS, loadNextStudentsInfo);
    yield takeEvery(STUDENT_INFO, studentInfo);
    yield takeEvery(USERS_INFO, usersInfo);
    yield takeEvery(UNIVERSITY_DATA, universityData);
    yield takeEvery(STUDENT_POST_LIKED, studentPostsLiked);
    yield takeEvery(STUDENT_FAQ_ANSWER_LIKED, studentFaqAnswerLiked);
    yield takeEvery(FAQ_INFO, faqInfo);
    yield takeEvery(FAQS_INFO, faqsInfo);
    yield takeEvery(FAQ_WATCHED, faqWatched);
    yield takeEvery(FAQS_SHARED, faqsShared);
    yield takeEvery(FAQ_ANSWER_LIKED, faqAnswerLiked);
    yield takeEvery(FAQS_ANSWER_LIKED, faqsAnswerLiked);
    yield takeEvery(LOAD_NEXT_FAQS, loadNextFaqsInfo);
    yield takeEvery(POST_INFO, postInfo);
    yield takeEvery(POST_WATCHED, postWatched);
    yield takeEvery(POST_LIKED, postLiked);
    yield takeEvery(POSTS_INFO, postsInfo);
    yield takeEvery(POSTS_LIKED, postsLiked);
    yield takeEvery(POSTS_SHARED, postsShared);
    yield takeEvery(LOAD_NEXT_POSTS, loadNextPostsInfo);
    yield takeEvery(WIDGET_INFO, widgetInfo);
    yield takeEvery(SINGLE_UNIVERSITY_WIDGET_INFO, singleUniversityWidgetInfo);
    yield takeEvery(SOCIAL_PROOF, socialProof);
    yield takeEvery(GET_NEXT_ITEMS, getNextItems);
    yield takeEvery(GET_FILTER_OPTIONS_REQUEST, fetchFilterOptions);
    yield takeEvery(CHAT_INFO, currentChatInfo);
    yield takeEvery(SEND_FEEDBACK, sendFeedback);
    yield takeEvery(PROSPECT_DIALOGS_INFO, prospectDialogsInfo);
    yield takeEvery(GET_SINGLE_PROSPECT_DIALOG_INFO, getSingleProspectDialogInfo);
    yield takeEvery(GROUP_DIALOGS_INFO, groupDialogsInfo);
    yield takeEvery(GET_SINGLE_GROUP_DIALOG_INFO, getSingleGroupDialogInfo);
    yield takeEvery(GROUP_CHAT_WATCHED, groupChatWatched);
    yield takeEvery(AMBASSADOR_DIALOGS_INFO, ambassadorDialogsInfo);
    yield takeEvery(GET_SINGLE_AMBASSADOR_DIALOG_INFO, getSingleAmbassadorDialogInfo);
    yield takeEvery(GET_DIALOGS_IDS, getDialogsIds);
    yield takeEvery(LEAVE_CHAT, leaveChat);
    yield takeEvery(GET_PROSPECT_INFO, getProspectInfo);
    yield takeEvery(INVITE_USERS, inviteUsers);
    yield takeEvery(LOGIN, login);
    yield takeEvery(SSO_LOGIN, ssoLogin);
    yield takeEvery(TIK_TOK_LOGIN, tikTokLogin);
    yield takeEvery(SSO_LOGIN_AUTH, ssoLoginAuth);
    yield takeEvery(TIK_TOK_CALLBACK, tikTokCallback);
    yield takeEvery(ACCOUNT.GET_UNIVERSITY_SSO_DATA, getUniversitySsoData);
    yield takeEvery(ACCOUNT.GET_EXPERIENCE_BASED_QUESTIONS, getExperienceBasedQuestions);
    yield takeEvery(ACCOUNT.SAVE_ACCEPTED_TERMS, saveAcceptedTerms);
    yield takeEvery(SOCIAL_MEDIA_LOGIN, socialMediaLogin);
    yield takeEvery(SOCIAL_MEDIA_REGISTRATION, socialMediaRegistration);
    yield takeEvery(TIK_TOK_REGISTRATION, tikTokRegistration);
    yield takeEvery(ACCOUNT_INFO, accountInfo);
    yield takeEvery(AUTH_BY_CODE, authByCode);
    yield takeEvery(AUTH_BY_EXTERNAL_SPEAKER_CODE, authBySpeakerCode);
    yield takeEvery(MESSAGES_INFO, messagesInfo);
    yield takeEvery(SEND_MESSAGE, send);
    yield takeEvery(SEND_AMBASSADOR_MESSAGE, sendAmbassadorMessage);
    yield takeEvery(FLAG_DIALOG, flag);
    yield takeEvery(CLOSE_DIALOG, closeToggle);
    yield takeEvery(CLOSE_AMBASSADOR_DIALOG, closeAmbassadorToggle);
    yield takeEvery(UNIVERSITY_QUESTIONS, universityQuestions);
    yield takeEvery(SAVE_UNIVERSITY_QUESTIONS, saveUniversityQuestions);
    yield takeEvery(MANDATORY_QUESTIONS, mandatoryQuestions);
    yield takeEvery(SAVE_MANDATORY_QUESTIONS, saveMandatoryQuestions);
    yield takeEvery(UNIVERSITY_QUESTIONS_DATA, universityQuestionsData);
    yield takeEvery(REGISTRATION, registration);
    yield takeEvery(FIND_OR_CREATE_DIALOG, findOrCreateDialog);
    yield takeEvery(AFTER_DIALOG_REPORT, afterDialogReport);
    yield takeEvery(GET_USER_COUNTRY, getUserCountry);
    yield takeEvery(GET_COUNTRIES, getCountries);
    yield takeEvery(GET_NATIONALITIES, getNationalities);
    yield takeEvery(ACCOUNT_CHANGE_AVATAR, accountChangeAvatar);
    yield takeEvery(START_RECOVERY_PASSWORD, startRecoveryPassword);
    yield takeEvery(END_RECOVERY_PASSWORD, endRecoveryPassword);
    yield takeEvery(SAVE_UNIVERSITY_USER_UTM_DATA, saveUtmData);
    yield takeEvery(NEW_MESSAGES_COUNTER, newMessagesCounter);
    yield takeEvery(NEW_CHATS_MESSAGES, newChatsMessages);
    yield takeEvery(REPORT_MESSAGE, reportMessage);
    yield takeEvery(CHAT_TO_PROSPECT, chatToProspect);
    yield takeEvery(ACCOUNT_LOGOUT, accountLogout);
}

export default saga;
