export var ABTestNamesEnum;
(function (ABTestNamesEnum) {
    ABTestNamesEnum["ABTEST_1"] = "ABTEST_1";
    ABTestNamesEnum["ABTEST_2"] = "ABTEST_2";
})(ABTestNamesEnum || (ABTestNamesEnum = {}));
export var ABTestBucketsEnum;
(function (ABTestBucketsEnum) {
    ABTestBucketsEnum["A"] = "A";
    ABTestBucketsEnum["B"] = "B";
})(ABTestBucketsEnum || (ABTestBucketsEnum = {}));
export function getAbTestBucket(abData, testName) {
    var _a;
    return (_a = abData[testName]) === null || _a === void 0 ? void 0 : _a.bucket;
}
