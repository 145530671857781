import PermissionModel from '../../models/PermissionModel';
import {
    CHAT_HEADER_SET,
    CHECK_IF_MOBILE,
    SET_TITLES,
    SET_UNI_GROUP,
    SET_UTM_DATA,
    SOCIAL_PROOF_SUCCESS,
    WIDGET_INFO,
    WIDGET_INFO_SUCCESS,
    WIDGET_LOAD,
    setIsMobile,
} from '../actions/widgetActions';
import { ABTestBucketsEnum } from 'src/helpers/AbTest';

/**
 * @type {import('../types/widget').WidgetStore}
 */

const initialState = {
    settings: {},
    permissions: [],
    ssoClientId: null,
    titles: {
        page_title: '',
        page_desc: '',
        display_search_bar: true,
        display_tap_logo: true,
        group_chat_title: '',
        profile_title: '',
        content_title: '',
        faq_title: '',
        social_proof_data: null,
    },
    filters: null,
    preFilterItems: [],
    searchType: 'default', // default, userDependent
    universities: [],
    universitiesById: [],
    groupUniversityId: null,
    universityIds: [],
    allGroupUniversitiesIds: [],
    currentUniversityId: null,
    isChatHeaderHidden: false,
    showOnlyGroupChats: false,
    showOnlyUsers: false,
    showOnlyPosts: false,
    showOnlyFaqs: false,
    contentTypes: [], // showOnlyGroupChats, showOnlyUsers, showOnlyPosts, showOnlyFaqs
    university_name: '',
    loaded: false,
    isTestUniversity: false,
    isMobile: document.body.clientWidth <= 750,
    clientWidth: document.body.clientWidth,
    ABTesting: {},
    institution: null,
    utmData: {
        utmMedium: null,
        utmSource: null,
        utmCampaign: null,
        cameFrom: null,
    },
};

/**
 * @param {import('../types/widget').WidgetStore} state
 * @param {import('redux').AnyAction} action
 * @returns {import('../types/widget').WidgetStore}
 */

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case WIDGET_INFO: {
            const contentTypes = [];
            if (payload.showOnlyLiveEvents) {
                contentTypes.push('liveEvents');
            }
            if (payload.showOnlyGroupChats) {
                contentTypes.push('groupChats');
            }
            if (payload.showOnlyUsers) {
                contentTypes.push('users');
            }
            if (payload.showOnlyPosts) {
                contentTypes.push('posts');
            }
            if (payload.showOnlyFaqs) {
                contentTypes.push('faqs');
            }

            payload.contentTypes = contentTypes;

            return {
                ...state,
                preFilterItems: payload.preFilterItems,
                searchType: payload.searchType || state.searchType,
                groupUniversityId: payload.groupId,
                universityIds: payload.groupId ? [] : [payload.university_id], // for now if group we are sending empty array
                currentUniversityId: payload.groupId ? null : payload.university_id,
                showOnlyGroupChats: payload.showOnlyGroupChats,
                showOnlyUsers: payload.showOnlyUsers,
                showOnlyPosts: payload.showOnlyPosts,
                showOnlyFaqs: payload.showOnlyFaqs,
                contentTypes: contentTypes,
            };
        }

        case WIDGET_INFO_SUCCESS: {
            const universitiesById = {};
            const allGroupUniversitiesIds = [];

            if (state.groupUniversityId && payload.data.universities) {
                for (const university of payload.data.universities) {
                    universitiesById[university.id] = university;
                    allGroupUniversitiesIds.push(university.id);
                }
            }

            if (payload.data.ABTesting) {
                for (const key in payload.data.ABTesting) {
                    payload.data.ABTesting[key] = {
                        ...payload.data.ABTesting[key],
                        bucket: payload.data.ABTesting[key] ? ABTestBucketsEnum.B : ABTestBucketsEnum.A,
                    };
                }
            }

            const filters = payload.data.filters;
            if (state.groupUniversityId && filters) {
                for (const filter in filters) {
                    filters[filter].id = filters[filter].filterIndex;
                }
            }

            const titles = payload.data.titles;
            if (state.groupUniversityId && titles) {
                const hasDisplayLogoProperty = titles.hasOwnProperty('display_tap_logo');
                if (!hasDisplayLogoProperty) {
                    titles.display_tap_logo = true;
                }
            }

            return {
                ...state,
                settings: payload.data.settings ? payload.data.settings : {},
                permissions: payload.data.permissions
                    ? payload.data.permissions.map((item) => PermissionModel(item))
                    : [],
                ssoClientId: payload.data.ssoClientId,
                titles: titles || { ...state.titles },
                filters: filters,
                show_number_likes_views: payload.data.show_number_likes_views,
                universities: payload.data.universities,
                universitiesById: universitiesById,
                allGroupUniversitiesIds: allGroupUniversitiesIds,
                isTestUniversity: payload.data.is_test_university,
                loaded: true,
                ABTesting: payload.data.ABTesting,
                institution: payload.data.institution ? payload.data.institution : state.institution,
                university_name: payload.data.institution
                    ? payload.data.institution.name
                    : payload.data.university_group
                      ? payload.data.university_group.name
                      : state.university_name,
            };
        }

        case WIDGET_LOAD: {
            return {
                ...state,
                loaded: true,
            };
        }

        case SOCIAL_PROOF_SUCCESS: {
            return {
                ...state,
                titles: {
                    ...state.titles,
                    social_proof_data: payload.data.social_proof_data,
                },
            };
        }

        case CHECK_IF_MOBILE: {
            return {
                ...state,
                isMobile: payload.clientWidth <= 750,
                clientWidth: payload.clientWidth,
            };
        }

        case setIsMobile.type: {
            return {
                ...state,
                isMobile: payload,
            };
        }

        case SET_UNI_GROUP: {
            return {
                ...state,
                universityIds: payload.universityIds,
            };
        }

        case SET_TITLES: {
            return {
                ...state,
                titles: {
                    ...state.titles,
                    page_title: payload.page_title ? payload.page_title : state.page_title,
                    page_desc: payload.page_desc ? payload.page_desc : state.page_desc,
                },
            };
        }

        case CHAT_HEADER_SET: {
            return {
                ...state,
                isChatHeaderHidden: payload.chatHeaderHide,
            };
        }

        case SET_UTM_DATA: {
            const { utmMedium, utmSource, utmCampaign, cameFrom } = payload;
            return {
                ...state,
                utmData: {
                    utmMedium,
                    utmSource,
                    utmCampaign,
                    cameFrom,
                },
            };
        }

        default:
            return state;
    }
}
