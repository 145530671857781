import {
    GET_LIVE_STREAMS,
    GET_LIVE_STREAMS_SUCCESS,
    GET_LIVE_STREAMS_FAIL,
    LOAD_NEXT_LIVE_STREAMS,
    LOAD_NEXT_LIVE_STREAMS_SUCCESS,
    SET_REGISTRATION_STREAM,
    SET_STREAM_UNI_DATA,
    RESET_LIVE_STREAM,
    ADD_LIVE_STREAM_PROSPECT_ID,
} from '../actions/liveStreamsActions';
import LiveStreamModel from '../../models/LiveStreamModel';
import OnDemandStreamModel from '../../models/OnDemandStreamModel';

const elementsToLoad =
    window.innerWidth > 1300
        ? 8
        : window.innerWidth > 900 && window.innerWidth <= 1300
        ? 9
        : window.innerWidth > 650 && window.innerWidth <= 900
        ? 6
        : 4;

const initialState = {
    items: null,
    ids: [],
    limit: elementsToLoad,
    offset: 0,
    total: 0,
    isLoading: true,
    nextLoading: false,
    registrationEvent: null,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_LIVE_STREAMS: {
            payload.limit = state.limit;
            payload.offset = 0;
            return state;
        }

        case GET_LIVE_STREAMS_SUCCESS: {
            const liveStreamsIds = [];
            const cleanLiveStreams = [];
            if (payload.liveStreams && payload.liveStreams.length) {
                for (const liveStream of payload.liveStreams) {
                    liveStreamsIds.push(liveStream.id);
                    cleanLiveStreams.push(
                        liveStream.status === 'onDemand' || liveStream.status === 'completed'
                            ? OnDemandStreamModel(liveStream)
                            : LiveStreamModel(liveStream)
                    );
                }
            }
            return {
                ...state,
                items: cleanLiveStreams,
                ids: liveStreamsIds,
                isLoading: false,
                nextLoading: false,
                offset: payload.offset,
                total: payload.total,
            };
        }

        case GET_LIVE_STREAMS_FAIL: {
            return {
                ...state,
                isLoading: false,
                nextLoading: false,
            };
        }

        case LOAD_NEXT_LIVE_STREAMS: {
            payload.ids = [...state.ids];
            payload.limit = state.limit;
            payload.offset = state.offset + state.limit;
            return {
                ...state,
                nextLoading: true,
            };
        }

        case LOAD_NEXT_LIVE_STREAMS_SUCCESS: {
            const ids = [];
            const cleanLiveStreams = [];
            if (payload.liveStreams && payload.liveStreams.length) {
                for (const liveStream of payload.liveStreams) {
                    ids.push(liveStream.id);
                    cleanLiveStreams.push(
                        liveStream.status === 'onDemand' || liveStream.status === 'completed'
                            ? OnDemandStreamModel(liveStream)
                            : LiveStreamModel(liveStream)
                    );
                }
            }
            return {
                ...state,
                items: [...state.items, ...cleanLiveStreams],
                ids: [...state.ids, ...ids],
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };
        }

        case SET_REGISTRATION_STREAM: {
            return {
                ...state,
                registrationEvent: { ...payload.event, university: { id: payload.event.university_id, name: '' } },
            };
        }

        case SET_STREAM_UNI_DATA: {
            return {
                ...state,
                registrationEvent: {
                    ...state.registrationEvent,
                    university: payload.university ? payload.university : state.registrationEvent.university,
                },
            };
        }

        case RESET_LIVE_STREAM: {
            return {
                ...initialState,
            };
        }
        case ADD_LIVE_STREAM_PROSPECT_ID: {
            const { liveStreamId, prospectId } = payload;
            return {
                ...state,
                items: state.items
                    ? state.items.map((item) => {
                          if (
                              item.id === liveStreamId &&
                              !item.registeredProspectIds.some((item) => item === prospectId)
                          ) {
                              item.registeredProspectIds = [...item.registeredProspectIds, prospectId];
                          }
                          return item;
                      })
                    : null,
            };
        }

        default:
            return state;
    }
}
