export const CHAT_INFO = 'CHAT_INFO';

export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const CHANGE_CHATS_TAB = 'CHANGE_CHATS_TAB';
export const SHOW_CHAT_MEMBERS = 'SHOW_CHAT_MEMBERS';
export const SET_ACCEPTED = 'SET_ACCEPTED';

export const GET_PROSPECT_INFO = 'GET_PROSPECT_INFO';
export const GET_PROSPECT_INFO_SUCCESS = 'GET_PROSPECT_INFO_SUCCESS';
export const DISPLAY_PROSPECT_INFO = 'DISPLAY_PROSPECT_INFO';

export const RESET_ACTIVE_CHAT = 'RESET_ACTIVE_CHAT';
export const RESET_ONLY_CHAT = 'RESET_ONLY_CHAT';

export const SET_CHAT_FEEDBACK = 'SET_CHAT_FEEDBACK';
export const CHAT_TO_PROSPECT = 'CHAT_TO_PROSPECT';
