import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
    DIALOGS_INFO_SUCCESS,
    DIALOGS_INFO_FAILED,
    CLOSE_DIALOG_FAILED,
    CLOSE_DIALOG_SUCCESS,
    FLAG_DIALOG_FAILED,
    FLAG_DIALOG_SUCCESS,
    FIND_OR_CREATE_DIALOG_SUCCESS,
    FIND_OR_CREATE_DIALOG_FAILED,
    AFTER_DIALOG_REPORT_FAILED,
    AFTER_DIALOG_REPORT_SUCCESS,
    GET_SINGLE_DIALOG_INFO_SUCCESS,
    GET_DIALOGS_IDS_SUCCESS,
    GET_DIALOGS_IDS,
    AMBASSADOR_DIALOGS_INFO,
    GROUP_DIALOGS_INFO,
} from '../../actions/dialogsActions';

import { CLOSE_ACTION_MODAL_CHILD, OPEN_ACTION_MODAL } from '../../actions/componentsActions';
import { CLEAR_MESSAGES_STORE, MESSAGES_INFO } from '../../actions/messagesActions';
import {
    CHAT_INFO,
    RESET_ACTIVE_CHAT,
    GET_PROSPECT_INFO_SUCCESS,
    DISPLAY_PROSPECT_INFO,
} from '../../actions/currentChatActions';

import { bindDialogs, unbindDialogs } from '../../../helpers/pusherHelper';

const getAccountInfo = (state) => state.account.account_info;
const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getGroupUniversitiesIds = (state) => state.widget.allGroupUniversitiesIds;

export function* groupDialogsInfo(action) {
    try {
        const accountInfo = yield select(getAccountInfo);
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        let response = {};
        if (!accountInfo) {
            response = yield call(() => {
                return API.dialogs.groupDialogsOpened(groupUniversityId ? groupUniversitiesIds : universityIds);
            });
        } else {
            response = yield call(() => {
                return API.dialogs.groupDialogs(groupUniversityId ? groupUniversitiesIds : universityIds);
            });
        }

        if (response.success) {
            yield put({
                type: DIALOGS_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                    resetMessagesDialogId: action.payload.resetMessagesDialogId,
                },
            });
        } else {
            yield put({
                type: DIALOGS_INFO_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DIALOGS_INFO_FAILED,
        });
    }
}

export function* prospectDialogsInfo(action) {
    try {
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        const response = yield call(() => {
            return API.dialogs.prospectDialogs(groupUniversityId ? groupUniversitiesIds : universityIds);
        });

        if (response.success) {
            yield put({
                type: DIALOGS_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                    resetMessagesDialogId: action.payload.resetMessagesDialogId,
                },
            });
        } else {
            yield put({
                type: DIALOGS_INFO_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DIALOGS_INFO_FAILED,
        });
    }
}

export function* ambassadorDialogsInfo(action) {
    try {
        const response = yield call(() => {
            return API.dialogs.ambassadorDialogs();
        });

        if (response.success) {
            const dialogs = response.data.dialogs;
            if (action.payload.prospectDialog) {
                const isDialogExist = response.data.dialogs.some(
                    (item) => item.id === action.payload.prospectDialog.id
                );
                if (!isDialogExist) {
                    dialogs.push(action.payload.prospectDialog);
                }
            }
            yield put({
                type: DIALOGS_INFO_SUCCESS,
                payload: {
                    items: dialogs,
                    resetMessagesDialogId: action.payload.resetMessagesDialogId,
                },
            });
        } else {
            yield put({
                type: DIALOGS_INFO_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DIALOGS_INFO_FAILED,
        });
    }
}

export function* getSingleGroupDialogInfo(action) {
    const payload = action.payload;
    try {
        const accountInfo = yield select(getAccountInfo);
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        let response = {};
        if (!accountInfo) {
            response = yield call(() => {
                return API.dialogs.groupDialogsOpened(
                    groupUniversityId ? groupUniversitiesIds : universityIds,
                    payload.dialogId
                );
            });
        } else {
            response = yield call(() => {
                return API.dialogs.groupDialogs(
                    groupUniversityId ? groupUniversitiesIds : universityIds,
                    payload.dialogId
                );
            });
        }

        if (response.success) {
            yield put({
                type: GET_SINGLE_DIALOG_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getSingleProspectDialogInfo(action) {
    const payload = action.payload;

    try {
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        const response = yield call(() => {
            return API.dialogs.prospectDialogs(
                groupUniversityId ? groupUniversitiesIds : universityIds,
                payload.dialogId
            );
        });

        if (response.success) {
            yield put({
                type: GET_SINGLE_DIALOG_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getSingleAmbassadorDialogInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.ambassadorDialogs(payload.dialogId);
        });

        if (response.success) {
            yield put({
                type: GET_SINGLE_DIALOG_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getDialogsIds(action) {
    const payload = action.payload;

    try {
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        const response = yield call(() => {
            return API.dialogs.getDialogsIds(
                payload.ids ? payload.ids : groupUniversityId ? groupUniversitiesIds : universityIds
            );
        });

        if (response.success) {
            yield put({
                type: GET_DIALOGS_IDS_SUCCESS,
                payload: {
                    ids: response.data.dialogIds,
                },
            });
            unbindDialogs();
            bindDialogs(response.data.dialogIds);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* flag(action) {
    const payload = action.payload;

    try {
        const data = {
            reported_reason: payload.reported_reason,
        };
        const response = yield call(() => {
            return API.dialogs.flag(payload.dialog_id, data);
        });

        if (response.success) {
            yield put({
                type: FLAG_DIALOG_SUCCESS,
            });

            yield put({
                type: CHAT_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                },
            });

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Conversation status has been changed!',
                },
            });
        } else {
            yield put({
                type: FLAG_DIALOG_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: FLAG_DIALOG_FAILED,
        });
    }
}

export function* closeToggle(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.closeToggle(payload.dialog_id);
        });

        if (response.success) {
            yield put({
                type: CLOSE_DIALOG_SUCCESS,
            });

            yield put({
                type: CHAT_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                },
            });
            if (action.onSuccess) action.onSuccess();
        } else {
            yield put({
                type: CLOSE_DIALOG_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: CLOSE_DIALOG_FAILED,
        });
    }
}

export function* closeAmbassadorToggle(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.closeAmbassadorToggle(payload.dialog_id);
        });

        if (response.success) {
            yield put({
                type: CLOSE_DIALOG_SUCCESS,
            });

            yield put({
                type: CHAT_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                },
            });

            yield put({
                type: AMBASSADOR_DIALOGS_INFO,
                payload: {},
            });

            if (action.onSuccess) action.onSuccess();
        } else {
            yield put({
                type: CLOSE_DIALOG_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: CLOSE_DIALOG_FAILED,
        });
    }
}

export function* findOrCreateDialog(action) {
    const payload = action.payload;

    try {
        const groupUniversityId = yield select(getGroupUniversityId);

        const response = yield call(() => {
            return API.dialogs.findOrCreate(payload.student_id, groupUniversityId);
        });

        if (response.success) {
            yield put({
                type: FIND_OR_CREATE_DIALOG_SUCCESS,
                payload: {
                    dialog_id: response.data.dialog_id,
                },
            });
        } else {
            yield put({
                type: FIND_OR_CREATE_DIALOG_FAILED,
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: FIND_OR_CREATE_DIALOG_FAILED,
        });
    }
}

export function* afterDialogReport(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.afterDialogReport(payload.dialog_id, {
                user_mark: payload.user_mark,
                university_mark: payload.university_mark,
            });
        });

        if (response.success) {
            yield put({
                type: AFTER_DIALOG_REPORT_SUCCESS,
                payload: {
                    dialog_id: payload.dialog_id,
                },
            });
        } else {
            yield put({
                type: AFTER_DIALOG_REPORT_FAILED,
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: AFTER_DIALOG_REPORT_FAILED,
        });
    }
}

export function* inviteUsers(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            if (payload.type === 'admins') {
                return API.dialogs.inviteAdmins(payload.users, payload.dialog_id);
            } else {
                return API.dialogs.inviteAmbassadors(payload.users, payload.dialog_id);
            }
        });

        if (response.success) {
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your chat has been edited',
                },
            });

            yield put({
                type: CHAT_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                },
            });

            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    ambassadorMessages: true,
                },
            });
            action.onFinish(true);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* leaveChat(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.leaveChat(payload.dialogId);
        });

        if (response.success) {
            yield put({
                type: CLEAR_MESSAGES_STORE,
            });
            yield put({
                type: RESET_ACTIVE_CHAT,
            });

            if (payload.groupDialogs) {
                yield put({
                    type: GROUP_DIALOGS_INFO,
                    payload: {},
                });
            } else {
                yield put({
                    type: AMBASSADOR_DIALOGS_INFO,
                    payload: {},
                });
            }

            yield put({
                type: GET_DIALOGS_IDS,
                payload: {},
            });
            yield put({
                type: CLOSE_ACTION_MODAL_CHILD,
                payload: {},
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getProspectInfo(action) {
    try {
        const response = yield call(() => {
            return API.dialogs.getProspectInfo(action.payload);
        });

        if (response.success) {
            yield put({
                type: GET_PROSPECT_INFO_SUCCESS,
                payload: {
                    prospectInfo: response.data,
                },
            });

            yield put({
                type: DISPLAY_PROSPECT_INFO,
                payload: true,
            });
        }
    } catch (e) {
        console.log(e);
    }
}
