import {
    GET_NEXT_ITEMS,
    GET_NEXT_ITEMS_SUCCESS,
    GET_NEXT_ITEMS_FAIL,
    LIKED_NEXT_ITEMS_SUCCESS,
    RESET_INFO,
} from '../actions/randomItems';

const initialState = {
    items: [],
    search: false,
    searching: false,
    isLoading: true,
    random: true,
    initialItems: [],
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_NEXT_ITEMS: {
            const toSave = {
                ...state,
                isLoading: true,
            };
            if (!payload.isDefault && !payload.random) {
                toSave.items = [];
            }

            return toSave;
        }

        case GET_NEXT_ITEMS_FAIL: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case GET_NEXT_ITEMS_SUCCESS: {
            if (!payload.random) {
                return {
                    ...state,
                    items: [payload],
                    isLoading: false,
                    search: true,
                    random: false,
                };
            } else {
                const items = [payload];
                return {
                    ...state,
                    items,
                    initialItems: items,
                    isLoading: false,
                    search: false,
                    random: false,
                };
            }
        }

        case RESET_INFO:
            return {
                ...state,
                items: [],
                initialItems: [],
            };

        case LIKED_NEXT_ITEMS_SUCCESS:
            let items = state.items;
            if (state.items.length) {
                items = { ...state.items[0] };
                let posts = items.posts ? items.posts : null;
                let faqs = items.faqs ? items.faqs : null;

                if (payload.postId && posts) {
                    for (let post in posts) {
                        if (posts[post].id === payload.postId) {
                            let like_count = posts[post].like_count;
                            posts[post].like_count = payload.unlike
                                ? like_count > 0
                                    ? like_count - 1
                                    : 0
                                : like_count + 1;
                        }
                    }
                } else if (payload.faqId && faqs) {
                    for (let faq in faqs) {
                        if (faqs[faq].id === payload.faqId) {
                            if (payload.answerId) {
                                for (let answer in faqs[faq].answers) {
                                    if (faqs[faq].answers[answer].id === payload.answerId) {
                                        let like_count = faqs[faq].answers[answer].like_count;
                                        faqs[faq].answers[answer].like_count = payload.unlike
                                            ? like_count > 0
                                                ? like_count - 1
                                                : 0
                                            : like_count + 1;
                                    }
                                }
                            } else {
                                let like_count = faqs[faq].like_count;
                                faqs[faq].like_count = payload.unlike
                                    ? like_count > 0
                                        ? like_count - 1
                                        : 0
                                    : like_count + 1;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                items: [items],
            };

        default:
            return state;
    }
}

const splitResult = (data) => {
    const result = [];
    let index = 0;
    const currentItem = {
        faqs: 4,
        posts: 3,
        students: 3,
    };
    const itemDefault = {
        faqs: [],
        posts: [],
        students: [],
    };
    const addItem = (item, block) => {
        if (!result[index]) {
            result[index] = JSON.parse(JSON.stringify(itemDefault));
        }
        if (result[index][block].length < currentItem[block]) {
            result[index][block].push(item);
            return true;
        } else return false;
    };

    data.faqs.forEach((faq) => {
        if (!addItem(faq, 'faqs')) {
            index++;
            addItem(faq, 'faqs');
        }
    });

    index = 0;

    data.posts.forEach((post) => {
        if (!addItem(post, 'posts')) {
            index++;
            addItem(post, 'posts');
        }
    });

    index = 0;

    data.students.forEach((user) => {
        if (!addItem(user, 'students')) {
            index++;
            addItem(user, 'students');
        }
    });

    return result;
};
