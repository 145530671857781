import API from '../../../api';
import { call, put, select, delay } from 'redux-saga/effects';
import {
    FAQS_INFO_SUCCESS,
    FAQS_INFO_FAIL,
    FAQS_ANSWER_LIKED_SUCCESS,
    LOAD_NEXT_FAQS_SUCCESS,
    REFRESH_FAQS_DATA,
} from '../../actions/faqsActions';
import { LIKED_NEXT_ITEMS_SUCCESS } from '../../actions/randomItems';
import { SHOW_DATA } from '../../actions/mainActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getSearchType = (state) => state.widget.searchType;

export function* faqsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.faqs.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                [],
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: FAQS_INFO_SUCCESS,
                payload: {
                    faqs: response.data.faqs,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });

            yield put({
                type: REFRESH_FAQS_DATA,
                payload: false,
            });

            yield delay(60000);

            yield put({
                type: REFRESH_FAQS_DATA,
                payload: true,
            });

            if (response.data.faqs.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showFaqs',
                    },
                });
            }
        } else if (!response.cancelled) {
            yield put({
                type: FAQS_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: FAQS_INFO_FAIL,
        });
    }
}

export function* loadNextFaqsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.faqs.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_FAQS_SUCCESS,
                payload: {
                    faqs: response.data.faqs,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
        } else if (!response.cancelled) {
            yield put({
                type: FAQS_INFO_FAIL,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: FAQS_INFO_FAIL,
        });
    }
}

export function* faqsAnswerLiked(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.answerLiked(payload.answer_id, payload.faq_id, payload.unlike, payload.faqWatched);
        });

        if (response.success) {
            if (payload.randomPage) {
                yield put({
                    type: LIKED_NEXT_ITEMS_SUCCESS,
                    payload: {
                        answerId: payload.answer_id,
                        faqId: payload.faq_id,
                        unlike: payload.unlike,
                    },
                });
            } else {
                yield put({
                    type: FAQS_ANSWER_LIKED_SUCCESS,
                    payload: {
                        answerId: payload.answer_id,
                        faqId: payload.faq_id,
                        unlike: payload.unlike,
                    },
                });
            }
        }
        if (payload.after) {
            payload.after(!!response.success);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* faqsShared(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.faqs.shared(payload.faq_id);
        });

        if (response.success) {
        }
    } catch (e) {
        console.log(e);
    }
}
