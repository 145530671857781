import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
    GET_LIVE_STREAMS_SUCCESS,
    LOAD_NEXT_LIVE_STREAMS_SUCCESS,
    ADD_LIVE_STREAM_PROSPECT_ID,
} from '../../actions/liveStreamsActions';

import { SHOW_DATA } from '../../actions/mainActions';
import {
    GET_LIVE_STREAM_SUCCESS,
    GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS,
    GET_LIVE_STREAM_PROSPECTS_SUCCESS,
} from '../../actions/currentStreamActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getSearchType = (state) => state.widget.searchType;
const getAccountInfo = (state) => state.account;

export function* getLiveStreams(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.LiveStreams.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                [],
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: GET_LIVE_STREAMS_SUCCESS,
                payload: {
                    liveStreams: response.data.liveStreams,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
            if (response.data.liveStreams.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showLiveStreams',
                    },
                });
            }
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* loadNextLiveStreams(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.LiveStreams.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_LIVE_STREAMS_SUCCESS,
                payload: {
                    liveStreams: response.data && response.data.liveStreams ? response.data.liveStreams : [],
                    offset: payload.offset,
                    total: response.data ? response.data.total : 0,
                },
            });
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* getLiveStream(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.getLiveStream(payload.eventSlug);
        });
        if (response.success) {
            const eventData = response.data && response.data.liveStream ? response.data.liveStream : null;
            const universityQuestionsAnswered =
                response.data && response.data.isAnsweredUniversityQuestion
                    ? response.data.isAnsweredUniversityQuestion
                    : false;

            yield put({
                type: GET_LIVE_STREAM_SUCCESS,
                payload: {
                    data: eventData,
                    universityQuestionsAnswered,
                    blockStreamRefresh: payload.blockStreamRefresh,
                },
            });
            if (action.onSuccess) action.onSuccess(eventData);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getLiveStreamProspects(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.getLiveStreamProspects(payload.eventSlug, payload.limit, payload.offset);
        });
        if (response.success) {
            yield put({
                type: GET_LIVE_STREAM_PROSPECTS_SUCCESS,
                payload: {
                    items: response.data && response.data.users ? response.data.users : [],
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getNextLiveStreamProspects(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.getLiveStreamProspects(payload.eventSlug, payload.limit, payload.offset);
        });
        if (response.success) {
            yield put({
                type: GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS,
                payload: {
                    items: response.data && response.data.users ? response.data.users : [],
                },
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getLiveStreamBySlug(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.getLiveStreamBySlug(payload.slug);
        });

        if (response.success) {
            payload.onSuccess(response.data);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* trackLiveStreamTime(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.trackLiveStreamTime(payload);
        });
    } catch (e) {
        console.log(e);
    }
}

export function* trackLiveStreamWatch(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.trackLiveStreamWatch(payload);
        });
        if (payload.prospectId) {
            yield put({
                type: ADD_LIVE_STREAM_PROSPECT_ID,
                payload,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

export function* trackLiveStreamPlay(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.LiveStreams.trackLiveStreamPlay(payload);
        });
    } catch (e) {
        console.log(e);
    }
}
