import { ACCOUNT } from '../../actions/accountActions';

const initialState = {
    isOpened: false,
    ambassadorId: null,
    chatId: null,
    ambassadorName: null,
    university: null,
    styles: null,
    saveAcceptedTerms: false,
    isOnSendMessage: false,
};

export default function (state = initialState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case ACCOUNT.OPEN_ACCEPT_TERMS_POPUP: {
            const { ambassadorId, chatId, ambassadorName, university, styles, saveAcceptedTerms, isOnSendMessage } =
                payload || {};

            return {
                ...state,
                isOpened: true,
                ambassadorId,
                chatId,
                ambassadorName,
                university,
                styles,
                saveAcceptedTerms,
                isOnSendMessage,
            };
        }

        case ACCOUNT.CLOSE_ACCEPT_TERMS_POPUP: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}
