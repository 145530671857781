import { USERS_INFO, USERS_INFO_SUCCESS, RESET_USERS } from '../actions/usersActions';

const initialState = {
    items: [],
    total: null,
    type: 'ambassadors',
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case USERS_INFO:
            return {
                ...state,
                isLoading: true,
            };

        case USERS_INFO_SUCCESS:
            return {
                ...state,
                items: payload.items,
                total: payload.total,
                type: payload.type || 'ambassadors',
                isLoading: false,
            };

        case RESET_USERS:
            return initialState;

        default:
            return state;
    }
}
