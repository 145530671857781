const NAMESPACE_PREFIX = 'LIVE_STREAM';

export const GET_LIVE_STREAM = 'GET_LIVE_STREAM';
export const GET_LIVE_STREAM_SUCCESS = 'GET_LIVE_STREAM_SUCCESS';
export const CHAT_INFO = 'CHAT_INFO';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const CHANGE_CHATS_TAB = 'CHANGE_CHATS_TAB';

export const GET_LIVE_STREAM_PROSPECTS = 'GET_LIVE_STREAM_PROSPECTS';
export const GET_LIVE_STREAM_PROSPECTS_SUCCESS = 'GET_LIVE_STREAM_PROSPECTS_SUCCESS';
export const GET_NEXT_LIVE_STREAM_PROSPECTS = 'GET_NEXT_LIVE_STREAM_PROSPECTS';
export const GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS = 'GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS';

export const UPDATE_LIVE_STREAM_STATUS = 'UPDATE_LIVE_STREAM_STATUS';
export const ARCHIVE_LIVE_STREAM = 'ARCHIVE_LIVE_STREAM';

export const RESET_LIVE_STREAM = 'RESET_LIVE_STREAM';
export const TRACK_LIVE_STREAM_TIME = 'TRACK_LIVE_STREAM_TIME';
export const TRACK_LIVE_STREAM_WATCH = 'TRACK_LIVE_STREAM_WATCH';
export const TRACK_LIVE_STREAM_PLAY = 'TRACK_LIVE_STREAM_PLAY';
export const ADD_LIVE_STREAM_PROSPECT = 'ADD_LIVE_STREAM_PROSPECT';

export const LIVE_STREAM = {
    OPEN_STREAM_IFRAMLESS_MODAL: `${NAMESPACE_PREFIX}.OPEN_STREAM_IFRAMLESS_MODAL`,
    CLOSE_STREAM_IFRAMLESS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_STREAM_IFRAMLESS_MODAL`,
};

export const openStreamIframlessModal = () => ({
    type: LIVE_STREAM.OPEN_STREAM_IFRAMLESS_MODAL,
});

export const closeStreamIframlessModal = () => ({
    type: LIVE_STREAM.CLOSE_STREAM_IFRAMLESS_MODAL,
});
