import HttpService from '../../system/HttpServices';

export default {
    get: (
        universityGroupId = null,
        universityIds = [],
        groupCountryIds,
        searchItems,
        preFilterItems,
        isCarousel,
        usersIds,
        limit,
        offset,
        countryIds,
        profileTypeIds,
        subjectIds
    ) => {
        const data = {
            groupId: universityGroupId,
            searchItems: searchItems && searchItems.length ? searchItems : [],
            prefilterItems: preFilterItems,
            usersNotInIds: usersIds,
            orderBy: 'random',
            isCarousel: isCarousel,
            countryIds,
            profileTypeIds,
            subjectIds,
        };
        if (universityIds && universityIds.length) {
            data['universityIds'] = universityIds;
        }
        if (groupCountryIds && groupCountryIds.length > 0) {
            data['groupCountryIds'] = groupCountryIds;
        }
        data['limit'] = limit;
        data['offset'] = 0; // if random then 0 with usersNotInIds

        return HttpService.post({
            path: '/users/getUsers',
            data,
            typeError: data.searchItems.length > 10 ? 'search' : null,
        });
    },
    getOne: (user_id) => {
        return HttpService.get({ path: '/users/' + user_id });
    },
    getUniversityData: (university_id) => {
        return HttpService.get({ path: '/universities/' + university_id });
    },
};
