import API from '../../../api';
import { call, put, select, delay } from 'redux-saga/effects';
import {
    STUDENTS_INFO_SUCCESS,
    STUDENTS_INFO_FAIL,
    LOAD_NEXT_STUDENTS_SUCCESS,
    REFRESH_STUDENTS_DATA,
} from '../../actions/studentsActions';
import { SHOW_DATA } from '../../actions/mainActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;

export function* studentsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);

    try {
        const response = yield call(() => {
            return API.students.get(
                groupUniversityId,
                payload.universityIds ? payload.universityIds : universityIds,
                payload.groupCountryIds,
                payload.search,
                preFilterItems,
                payload.isCarousel,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: STUDENTS_INFO_SUCCESS,
                payload: {
                    students: response.data.users,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
            if (response.data.users.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showUsers',
                    },
                });
            } else if (action.onNotFound) {
                action.onNotFound();
            }

            yield put({
                type: REFRESH_STUDENTS_DATA,
                payload: false,
            });

            yield delay(60000);

            yield put({
                type: REFRESH_STUDENTS_DATA,
                payload: true,
            });
        } else if (!response.cancelled) {
            yield put({
                type: STUDENTS_INFO_FAIL,
            });
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
        yield put({
            type: STUDENTS_INFO_FAIL,
        });
    }
}

export function* loadNextStudentsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);

    try {
        const response = yield call(() => {
            return API.students.get(
                groupUniversityId,
                universityIds,
                payload.groupCountryIds,
                payload.search,
                preFilterItems,
                payload.isCarousel,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_STUDENTS_SUCCESS,
                payload: {
                    students: response.data.users,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
            if (action.onSuccess) action.onSuccess();
        } else if (!response.cancelled) {
            yield put({
                type: STUDENTS_INFO_FAIL,
            });
            if (action.onFail) action.onFail();
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
        yield put({
            type: STUDENTS_INFO_FAIL,
        });
        if (action.onFail) action.onFail();
    }
}
