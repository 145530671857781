import API from '../../../api';
import { call, put, select, delay } from 'redux-saga/effects';
import {
    GROUP_CHATS_INFO_SUCCESS,
    LOAD_NEXT_GROUP_CHATS_SUCCESS,
    REFRESH_GROUP_CHATS_DATA,
} from '../../actions/groupChatsActions';
import { SHOW_DATA } from '../../actions/mainActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getPreFilterItems = (state) => state.widget.preFilterItems;
const getSearchType = (state) => state.widget.searchType;

export function* groupChatsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.groupChats.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                [],
                payload.limit,
                payload.offset,
                null,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: GROUP_CHATS_INFO_SUCCESS,
                payload: {
                    groupChats: response.data.groupChats,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });

            yield put({
                type: REFRESH_GROUP_CHATS_DATA,
                payload: false,
            });

            yield delay(60000);

            yield put({
                type: REFRESH_GROUP_CHATS_DATA,
                payload: true,
            });

            if (response.data.groupChats.length > 0) {
                yield put({
                    type: SHOW_DATA,
                    payload: {
                        typeToShow: 'showGroupChats',
                    },
                });
            }
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* loadNextGroupChatsInfo(action) {
    const payload = action.payload;

    const groupUniversityId = yield select(getGroupUniversityId);
    const universityIds = yield select(getUniversityIds);
    const preFilterItems = yield select(getPreFilterItems);
    const searchType = yield select(getSearchType);

    try {
        const response = yield call(() => {
            return API.groupChats.get(
                groupUniversityId,
                payload.university_id ? [payload.university_id] : universityIds,
                payload.search,
                preFilterItems,
                searchType,
                payload.ids,
                payload.limit,
                payload.offset,
                payload.countryIds,
                payload.profileTypeIds,
                payload.subjectIds
            );
        });

        if (response.success) {
            yield put({
                type: LOAD_NEXT_GROUP_CHATS_SUCCESS,
                payload: {
                    groupChats: response.data.groupChats,
                    offset: payload.offset,
                    total: response.data.total,
                },
            });
        }
        if (payload.func) payload.func();
    } catch (e) {
        console.log(e);
    }
}

export function* groupChatWatched(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.groupChats.watched(payload.dialog_id);
        });
    } catch (e) {
        console.log(e);
    }
}
