export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SEARCH_TERM_BUBBLE = 'SET_SEARCH_TERM_BUBBLE';
export const SET_GROUP_UNIVERSITY_BUBBLE = 'SET_GROUP_UNIVERSITY_BUBBLE';
export const MATCH_ME_SEARCH = 'MATCH_ME_SEARCH';
export const RETURN_PREV_SEARCH = 'RETURN_PREV_SEARCH';
export const REMOVE_SEARCH_TERM = 'REMOVE_SEARCH_TERM';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_SEARCH_TERM_HASH = 'SET_SEARCH_TERM_HASH';
export const SET_SEARCH_TERM_HASH_FOR_GROUP_UNI = 'SET_SEARCH_TERM_HASH_FOR_GROUP_UNI';

export const setSearchTerm = (item, matchMe) => ({
    type: SET_SEARCH_TERM,
    payload: { item, matchMe },
});

// individually for filters
export const setSearchTermBubble = (item, matchMe) => ({
    type: SET_SEARCH_TERM_BUBBLE,
    payload: { item, matchMe },
});

export const setSearchUniversityGroupBubble = (item, matchMe) => ({
    type: SET_GROUP_UNIVERSITY_BUBBLE,
    payload: { item, matchMe },
});

export const removeSearchTerm = (item, matchMe) => ({
    type: REMOVE_SEARCH_TERM,
    payload: { item, matchMe },
});

export const clearSearch = () => ({
    type: CLEAR_SEARCH,
});
