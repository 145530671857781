export var UserRole;
(function (UserRole) {
    UserRole["UnivercityStudent"] = "univercity-student";
    UserRole["UnivercityAdmin"] = "univercity-admin";
    UserRole["Prospect"] = "prospect";
    // ... TODO: Add more roles
})(UserRole || (UserRole = {}));
export var UserProfileEnum;
(function (UserProfileEnum) {
    UserProfileEnum["Staff"] = "staff";
    UserProfileEnum["Alumni"] = "alumni";
    UserProfileEnum["Student"] = "student";
    UserProfileEnum["SchoolStudent"] = "school_student";
})(UserProfileEnum || (UserProfileEnum = {}));
Object.freeze(UserProfileEnum);
