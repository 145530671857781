import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import notifications from '../../modules/react-notification-system-redux/reducer';
import { acceptTermsReducer, accountReducer, actionPopupReducer, chatBoxReducer, currentChatReducer, currentStreamReducer, dialogsReducer, eventStreamReducer, experienceBasedQuestionsPopupReducer, faqReducer, faqsReducer, filterTermsReducer, filtersReducer, forumChatsReducer, groupChatsReducer, liveStreamsReducer, loginPopupReducer, mainReducer, messagesReducer, mobileAppPromotionReducer, notificationMethodReducer, postReducer, postsReducer, randomItemsReducer, registrationPopupReducer, replyMessageReducer, reportMessageReducer, searchReducer, studentReducer, studentsReducer, usersReducer, widgetReducer, } from '../reducers';
import saga from '../sagas';
var sagaMiddleware = createSagaMiddleware();
var store = configureStore({
    reducer: {
        main: mainReducer,
        groupChats: groupChatsReducer,
        liveStreams: liveStreamsReducer,
        currentLiveStream: currentStreamReducer,
        post: postReducer,
        posts: postsReducer,
        faq: faqReducer,
        faqs: faqsReducer,
        students: studentsReducer,
        student: studentReducer,
        widget: widgetReducer,
        randomItems: randomItemsReducer,
        searchTerms: searchReducer,
        filters: filtersReducer,
        filterTerms: filterTermsReducer,
        notifications: notifications,
        account: accountReducer,
        registrationPopup: registrationPopupReducer,
        dialogs: dialogsReducer,
        loginPopup: loginPopupReducer,
        experienceBasedQuestionsPopup: experienceBasedQuestionsPopupReducer,
        mobileAppPromotionPopup: mobileAppPromotionReducer,
        acceptTermsPopup: acceptTermsReducer,
        messages: messagesReducer,
        actionPopup: actionPopupReducer,
        notificationMethodPopup: notificationMethodReducer,
        users: usersReducer,
        chatBox: chatBoxReducer,
        currentChat: currentChatReducer,
        replyMessage: replyMessageReducer,
        reportMessage: reportMessageReducer,
        eventStream: eventStreamReducer,
        forumChats: forumChatsReducer,
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
            // eslint-disable-next-line unicorn/prefer-spread
        }).concat(sagaMiddleware);
    },
});
sagaMiddleware.run(saga);
export default store;
