import { getAccountInfo, LOGIN_SUCCESS } from '../redux/actions/accountActions';
import { OPEN_ACTION_MODAL } from '../redux/actions/componentsActions';

import store from '../redux/store';

import { STR_UNIVERSITY_IDS } from '../common/accountIds';
import { tryParseJSON, tryStringifyJSON } from '../common/utils/jsonUtils';
import pusher from '../system/services/PusherWrapper';

import { getCommonRoutePath, getUniversityIdNew, isAllowed } from './Helpers';
import { localStorageWrapper } from './storageFactory';
import { getToken, isTokenExist, setToken } from './token';

export const openWindow = (
    appDomain,
    appClientId,
    onSubmit,
    redirectToUniversity,
    onLoginSuccess,
    isEncryptionOn,
    universitySsoData = {}
) => {
    const { ssoClientId } = store.getState().widget;
    const clientId = ssoClientId || appClientId;
    const left = window.innerWidth / 2 - 200;
    const top = window.innerHeight / 2 - 250;
    const isEncrypted = isSsoEncryptedAllowed() || isEncryptionOn;

    const ssoData = tryParseJSON(localStorageWrapper.getItem('ssoSignInData'));

    const randomId = Math.random().toString(36) + new Date().getTime();
    if (!window.channel) {
        window.channel = pusher.subscribe('tap-page');
    }

    window.channel.bind('users:guest:' + randomId, (data) => {
        if (universitySsoData.id) {
            localStorageWrapper.setItem(
                'universitySsoData',
                tryStringifyJSON({ ...data.ssoRegisterUserData, ...universitySsoData })
            );
        }

        if (data.token) {
            setToken(data.token);
            store.dispatch({
                type: LOGIN_SUCCESS,
                payload: { token: data.token },
            });
            store.dispatch(getAccountInfo({ notifications: true }));

            if (redirectToUniversity && data.ssoSignInUserData) {
                window.location = data.ssoSignInUserData.url;
            }
            if (onLoginSuccess) {
                onLoginSuccess();
            }
        } else if (onSubmit && data.ssoRegisterUserData) {
            onSubmit(data.ssoRegisterUserData);
        }

        window.channel.unbind('users:guest:' + randomId);
    });

    const ssoWindow = window.open(
        (!isEncrypted
            ? appDomain +
              'login?client_id=' +
              appClientId +
              '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri='
            : '') +
            window.location.origin +
            '/interaction/sign-in-redirect' +
            (isEncrypted ? '?' : '&') +
            'state=randomId=' +
            randomId +
            (ssoData?.universityId ? '__universityId=' + ssoData.universityId : '') +
            (ssoData?.isSsoNew ? '__isSsoNew=' + ssoData.isSsoNew : '') +
            (ssoData?.redirectToUniversity ? '__redirectToUniversity=' + ssoData.redirectToUniversity : '') +
            (ssoData?.universityGroupId ? '__universityGroupId=' + ssoData.universityGroupId : '') +
            (ssoData?.email ? '__email=' + ssoData.email : '') +
            '__signInSuccessPagePath=' +
            getCommonRoutePath() +
            '/interaction/sign-in-success' +
            (isEncrypted ? '&ssoClientId=' + clientId : ''),
        'TAP',
        'resizable=yes,width=400,height=500,left=' + left + ',top=' + top
    );

    let leftDomain = false;

    if (ssoWindow) {
        const interval = setInterval(function () {
            try {
                if (ssoWindow.document.domain === document.domain) {
                    if (leftDomain && ssoWindow.document.readyState === 'complete') {
                        // we're here when the child window returned to our domain
                        clearInterval(interval);
                    }
                } else {
                    // this code should never be reached, as the x-site security check throws but just in case
                    leftDomain = true;
                }
            } catch (e) {
                // we're here when the child window has been navigated away or closed
                if (ssoWindow.closed) {
                    clearInterval(interval);
                    return;
                }
                // navigated to another domain
                leftDomain = true;
            }
        }, 500);

        if (window.focus) ssoWindow.focus();
        const timer = setInterval(function () {
            if (ssoWindow.closed) {
                clearInterval(timer);
            }
        }, 1000);
        return true;
    } else {
        const refreshCheck = setInterval(() => {
            if (isTokenExist()) {
                clearInterval(refreshCheck);
                const token = getToken();
                store.dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { token },
                });
                store.dispatch(getAccountInfo({ notifications: true }));

                if (redirectToUniversity && localStorageWrapper.getItem('ssoSignInUserData')) {
                    const userData = tryParseJSON(localStorageWrapper.getItem('ssoSignInUserData'));
                    localStorageWrapper.removeItem('ssoSignInUserData');
                    window.location = userData.url;
                }
                if (onLoginSuccess) {
                    onLoginSuccess();
                }
            } else if (onSubmit && localStorageWrapper.getItem('ssoRegisterUserData')) {
                const ssoData = tryParseJSON(localStorageWrapper.getItem('ssoRegisterUserData'));
                localStorageWrapper.removeItem('ssoRegisterUserData');
                onSubmit(ssoData);
            } else {
                store.dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Oops! Your browser has the pop-up blocker enabled. Please turn it off to proceed.',
                    },
                });
                clearInterval(refreshCheck);
            }
        }, 1000);
        return false;
    }
};

export const removeSsoDataFromStorage = () => {
    try {
        const storageData = localStorageWrapper.getStorageData();
        for (const key in storageData) {
            if (key.substring(0, 30) === 'CognitoIdentityServiceProvider') {
                localStorageWrapper.removeItem(key);
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export const isSsoAllowed = () => {
    return isAllowed('ssoProspect') || isAllowed('ssoAmbassador');
};

export const isSsoOnlyActivated = () => {
    return isAllowed('ssoOnly');
};

export const isSsoProspectAllowed = () => {
    return isAllowed('ssoProspect');
};

export const isSsoAmbassadorAllowed = () => {
    return isAllowed('ssoAmbassador');
};

export const isSsoEncryptedAllowed = () => {
    return isAllowed('ssoEncrypted');
};

export const isSsoOnly = () => {
    const universityId = getUniversityIdNew();
    switch (universityId) {
        case STR_UNIVERSITY_IDS.CYCLOPES: // Cyclopes
        case STR_UNIVERSITY_IDS.USYD: // USYD
        case STR_UNIVERSITY_IDS.TEST_UNI: // Test Uni
            return true;
        default:
            return false;
    }
};
