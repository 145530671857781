import axios from 'axios';
import { isCommunity } from 'src/helpers/is-community';
import { communityLogout } from 'src/redux/slices/account';
import Notifications from '../../modules/react-notification-system-redux';
import { ACCOUNT_LOGOUT } from '../../redux/actions/accountActions';
import store from '../../redux/store';
import { communityStore } from '../../redux/store/communityStore';

class Handler {
    static errorHandler(err) {
        if (!axios.isCancel(err) && err.response && err.response.status) {
            const message =
                err.response.data && err.response.data.error && err.response.data.error.message
                    ? err.response.data.error.message
                    : '';
            switch (err.response.status) {
                case 400:
                    this.badRequest(message);
                    break;
                case 401:
                    this.authError(message);
                    break;
                case 403:
                    this.forbidden(message);
                    break;
                case 404:
                    this.notFound(message);
                    break;
                case 429:
                    this.forbidden(message);
                    break;
                default:
                    this.defaultError(message);
                    break;
            }
        } else {
            this.networkError();
        }
    }

    static badRequest(message) {
        const selectedStore = isCommunity() ? communityStore : store;
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        );
    }

    static authError() {
        if (isCommunity()) {
            communityStore.dispatch(communityLogout());
        } else {
            store.dispatch({
                type: ACCOUNT_LOGOUT,
                payload: { redirect: true },
            });
        }
    }

    static forbidden(message) {
        const selectedStore = isCommunity() ? communityStore : store;
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        );
    }

    static notFound(message) {
        const selectedStore = isCommunity() ? communityStore : store;
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        );
    }

    static defaultError() {
        const selectedStore = isCommunity() ? communityStore : store;
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: 'Please contact support.',
                position: 'tr',
                action: '',
            })
        );
    }

    static networkError() {
        const selectedStore = isCommunity() ? communityStore : store;
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        );
    }

    static typeError(error) {
        const selectedStore = isCommunity() ? communityStore : store;

        // if we will have more specific errors in the future, we could move it to separated service or show some concrete errors
        let message = '';
        if (error === 'search') {
            message = 'You can use up to 10 search terms at the same time.';
        }
        selectedStore.dispatch(
            Notifications.error({
                title: 'Error',
                message: message,
                position: 'tr',
                action: '',
            })
        );
    }
}

export default Handler;
