export const MAIN_INFO = 'MAIN_INFO_INFO';
export const MAIN_INFO_SUCCESS = 'MAIN_INFO_SUCCESS';
export const MAIN_INFO_FAIL = 'MAIN_INFO_FAIL';
export const MAIN_INFO_LOADED = 'MAIN_INFO_LOADED';
export const SHOW_DATA = 'SHOW_DATA';

export const RESET_MAIN_INFO = 'RESET_MAIN_INFO';

export const resetMainInfo = () => ({
    type: RESET_MAIN_INFO,
});
