import HttpService from '../../system/HttpServices';
import { GROUP_IDS } from '../../common/accountIds';

export default {
    fetchFilterOptions: ({ fixed_type, ...rest }) => {
        return HttpService.get({ path: `/tags/subtags/${fixed_type}`, data: { ...rest } });
    },
    getCountries: ({ universityId, groupId }) => {
        return HttpService.get({
            path: '/data/countries',
            data: { universityId, groupId, orderBy: 'name', order: '', isAmbassadorRelated: !!universityId },
            requestType: 'common',
        });
    },
    getProfileTypes: ({ universityId, groupId }) => {
        return HttpService.get({
            path: '/data/ambassador/profileTypes',
            data: { universityId, groupId, orderBy: 'name', order: 'asc', isAmbassadorRelated: !!universityId },
            requestType: 'common',
        });
    },
    getSubjects: ({ universityId, groupId }) => {
        return HttpService.get({
            path: '/data/subjects',
            data: {
                universityId,
                groupId,
                orderBy: 'name',
                order: 'asc',
                isAmbassadorRelated: !!universityId || groupId === GROUP_IDS.SUTTON_TRUST, // Filter for all universities and Sutton Trust group
                showCustom: true,
            },
            requestType: 'common',
        });
    },
    fetchGroupCountries: (groupId) => {
        return HttpService.get({ path: '/groupCountries', data: { groupId } });
    },
    fetchFilterOptionsGroupUniversities: (groupId) => {
        return HttpService.get({ path: `/universities/filterByGroup/${groupId}` });
    },
    fetchFilterOptionsUniversities: ({ university_id }) => {
        return HttpService.get({ path: `/groups/universities/${university_id}` });
    },
};
