import { httpServices } from 'src/system/HttpServices/HttpService';
import { UserRole } from 'src/types/user';
export default {
    getUserInfo: function (userId, spaceId) {
        return httpServices
            .get({
            path: "/users/".concat(userId, "/info?spaceId=").concat(spaceId),
            requestType: 'community',
        })
            .then(function (res) {
            var user = res.data.user;
            if (user.roleKey === UserRole.UnivercityStudent) {
                user.ambassadorData.profileType = user.ambassadorData.profile.key;
            }
            return res;
        });
    },
    getAmbassadorMembers: function (data, signal) {
        return httpServices
            .get({
            path: '/users/ambassadors',
            withCredentials: true,
            requestType: 'community',
            data: data,
            signal: signal,
        })
            .then(function (res) {
            res.data.users.forEach(function (user) {
                if (user.roleKey === UserRole.UnivercityStudent) {
                    user.ambassadorData.profileType = user.ambassadorData.profile.key;
                    user.spaceId = data.spaceId;
                }
            });
            return res;
        });
    },
    getOtherMembers: function (data, signal) {
        return httpServices.get({
            path: '/users/prospects',
            withCredentials: true,
            requestType: 'community',
            data: data,
            signal: signal,
        });
    },
};
