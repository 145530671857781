export const MESSAGES_INFO = 'MESSAGES_INFO';
export const MESSAGES_INFO_SUCCESS = 'MESSAGES_INFO_SUCCESS';
export const MESSAGES_INFO_FAILED = 'MESSAGES_INFO_FAILED';
export const LOAD_NEW_MESSAGES_SUCCESS = 'LOAD_NEW_MESSAGES_SUCCESS';
export const LOAD_OLD_MESSAGES_SUCCESS = 'LOAD_OLD_MESSAGES_SUCCESS';
export const LOAD_AROUND_MESSAGES_SUCCESS = 'LOAD_AROUND_MESSAGES_SUCCESS';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const SEND_AMBASSADOR_MESSAGE = 'SEND_AMBASSADOR_MESSAGE';

export const NEW_MESSAGES_RECEIVED = 'NEW_MESSAGES_RECEIVED';
export const HIDE_NEW_MESSAGES = 'HIDE_NEW_MESSAGES';

export const CLEAR_MESSAGES_STORE = 'CLEAR_MESSAGES_STORE';

export const SHOW_OPTIONAL_QUESTIONS = 'SHOW_OPTIONAL_QUESTIONS';
export const SET_USER_MESSAGES_EXISTS = 'SET_USER_MESSAGES_EXISTS';

export const SHOW_INVITE_USERS = 'SHOW_INVITE_USERS';

export const REPLY_MESSAGE = 'REPLY_MESSAGE';

export const SET_REPORT_MESSAGE = 'SET_REPORT_MESSAGE';
export const DISPLAY_REPORT_POPUP = 'DISPLAY_REPORT_POPUP';
export const REPORT_MESSAGE = 'REPORT_MESSAGE';

export const CHANGE_MESSAGE_KEYWORDS = 'CHANGE_MESSAGE_KEYWORDS';
export const RESET_AROUND_MESSAGES_REQUEST = 'RESET_AROUND_MESSAGES_REQUEST';
