import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
    NEW_MESSAGES_COUNTER_SUCCESS,
    NEW_CHATS_MESSAGES_SUCCESS,
    NEW_CHATS_MESSAGES_FAILED,
    NEW_MESSAGES_COUNTER_FAILED,
} from '../../actions/chatBoxActions';

const getGroupUniversityId = (state) => state.widget.groupUniversityId;
const getUniversityIds = (state) => state.widget.universityIds;
const getGroupUniversitiesIds = (state) => state.widget.allGroupUniversitiesIds;

export function* newMessagesCounter(action) {
    const { payload } = action;
    try {
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        const response = yield call(() => {
            return API.dialogs.newMessagesCounter(groupUniversityId ? groupUniversitiesIds : universityIds);
        });

        if (response.success) {
            yield put({
                type: NEW_MESSAGES_COUNTER_SUCCESS,
                payload: {
                    newMessages: response.data.total,
                },
            });
        } else {
            yield put({
                type: NEW_MESSAGES_COUNTER_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: NEW_MESSAGES_COUNTER_FAILED,
        });
    }
}

export function* newChatsMessages(action) {
    const { payload } = action;
    try {
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityIds = yield select(getUniversityIds);
        const groupUniversitiesIds = yield select(getGroupUniversitiesIds);

        const response = yield call(() => {
            return API.dialogs.newChatsMessages(groupUniversityId ? groupUniversitiesIds : universityIds);
        });

        if (response.success) {
            yield put({
                type: NEW_CHATS_MESSAGES_SUCCESS,
                payload: {
                    items: response.data.items,
                },
            });
        } else {
            yield put({
                type: NEW_CHATS_MESSAGES_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: NEW_CHATS_MESSAGES_FAILED,
        });
    }
}
