const getDialogTypeData = (dialogs, type = 'live-event-speakers-chat') => {
    if (dialogs[0] && dialogs[0].type === type) {
        return dialogs[0];
    } else if (dialogs[1] && dialogs[1].type === type) {
        return dialogs[1];
    } else {
        return null;
    }
};

const LiveStreamModel = (data) => {
    const response = {
        id: null,
        university_id: null,
        name: '',
        slug: '',
        image: null,
        image_id: null,
        status: 'upcoming',
        statusName: null,
        is_chat_active: false,
        is_stream_active: false,
        is_promote: false,
        created_at: '',
        description: '',
        from_date: '',
        to_date: '',
        speakers: [],
        dialogs: [],
        ambassadors: [],
        live_chat_id: null,
        liveChat: null,
        speakersChat: null,
        university: null,
        registeredProspectIds: [],
    };

    if (data) {
        if (data.id) response.id = data.id;
        if (data.university_id) response.university_id = data.university_id;
        if (data.name) response.name = data.name;
        if (data.slug) response.slug = data.slug;
        if (data.image) response.image = data.image;
        if (data.image_id) response.image_id = data.image_id;
        if (data.status) response.status = data.status;
        if (data.statusName) response.statusName = data.statusName;
        if (data.is_chat_active) response.is_chat_active = data.is_chat_active;
        if (data.is_stream_active) response.is_stream_active = data.is_stream_active;
        if (data.is_promote) response.is_promote = data.is_promote;
        if (data.created_at) response.created_at = data.created_at;
        if (data.description) response.description = data.description;
        if (data.from_date) response.from_date = data.from_date;
        if (data.to_date) response.to_date = data.to_date;
        if (data.speakers) response.speakers = data.speakers;
        if (data.dialogs) response.dialogs = data.dialogs;
        if (data.ambassadors) response.ambassadors = data.ambassadors;
        if (data.live_chat_id) response.live_chat_id = data.live_chat_id; // to check if user already joined or not
        if (data.dialogs) response.speakersChat = getDialogTypeData(data.dialogs, 'live-event-speakers-chat');
        if (data.dialogs) response.liveChat = getDialogTypeData(data.dialogs, 'live-stream-chat');
        if (data.university) response.university = data.university;
        if (data.registeredProspectIds) response.registeredProspectIds = data.registeredProspectIds;
    }

    return response;
};

export default LiveStreamModel;
