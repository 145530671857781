import { REPLY_MESSAGE } from '../actions/messagesActions';

const initialState = {
    activeMessage: null,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case REPLY_MESSAGE: {
            return {
                ...state,
                activeMessage: payload.activeMessage,
            };
        }

        default: {
            return state;
        }
    }
}
