export const GROUP_CHATS_INFO = 'GROUP_CHATS_INFO';
export const GROUP_CHATS_INFO_SUCCESS = 'GROUP_CHATS_INFO_SUCCESS';
export const GROUP_CHATS_INFO_FAIL = 'GROUP_CHATS_INFO_FAIL';

export const REFRESH_GROUP_CHATS_DATA = 'REFRESH_GROUP_CHATS_DATA';

export const LOAD_NEXT_GROUP_CHATS = 'LOAD_NEXT_GROUP_CHATS';
export const LOAD_NEXT_GROUP_CHATS_SUCCESS = 'LOAD_NEXT_GROUP_CHATS_SUCCESS';
export const GROUP_CHAT_WATCHED = 'GROUP_CHAT_WATCHED';

export const fetchData = (options) => ({
    type: LOAD_NEXT_GROUP_CHATS,
    payload: { ...options },
});
