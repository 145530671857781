import { SET_REPORT_MESSAGE, DISPLAY_REPORT_POPUP } from '../actions/messagesActions';

const initialState = {
    activeMessage: null,
    showReportMessagePopup: false,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case SET_REPORT_MESSAGE: {
            return {
                ...state,
                activeMessage: payload.activeMessage,
            };
        }
        case DISPLAY_REPORT_POPUP: {
            return {
                ...state,
                showReportMessagePopup: payload,
            };
        }

        default: {
            return state;
        }
    }
}
