import { ACCOUNT } from '../../actions/accountActions';

const initialState = {
    isOpened: false,
};

export default function (state = initialState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case ACCOUNT.OPEN_MOBILE_APP_PROMOTION_POPUP: {
            return {
                ...state,
                isOpened: true,
            };
        }

        case ACCOUNT.CLOSE_MOBILE_APP_PROMOTION_POPUP: {
            return {
                ...state,
                isOpened: false,
            };
        }

        default:
            return state;
    }
}
