import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
    MESSAGES_INFO_FAILED,
    MESSAGES_INFO_SUCCESS,
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_SUCCESS,
    MESSAGES_INFO,
    LOAD_NEW_MESSAGES_SUCCESS,
    LOAD_OLD_MESSAGES_SUCCESS,
    LOAD_AROUND_MESSAGES_SUCCESS,
    DISPLAY_REPORT_POPUP,
} from '../../actions/messagesActions';
import { NEW_MESSAGES_COUNTER } from '../../actions/chatBoxActions';
import { RESET_DIALOG_NEW_MESSAGES } from '../../actions/dialogsActions';
import { SET_CHAT_PERMISSION } from '../../actions/accountActions';
import { CHAT_INFO } from '../../actions/currentChatActions';

const getAccountInfo = (state) => state.account.account_info;
const getCurrentChat = (state) => state.currentChat;
const getMessages = (state) => state.messages;

export function* messagesInfo(action) {
    const payload = action.payload;

    try {
        const accountInfo = yield select(getAccountInfo);
        const currentChatInfo = yield select(getCurrentChat);

        let response = {};
        let privateCode = null;
        if (payload.privateCode) {
            privateCode = payload.privateCode;
        } else if (currentChatInfo.privateCode && currentChatInfo.privateCodeDialogId === +payload.dialog_id) {
            privateCode = currentChatInfo.privateCode;
        }
        if ((!accountInfo || !accountInfo.id) && (currentChatInfo.chatsType === 4 || payload.dialog_type === 4)) {
            response = yield call(() => {
                return API.messages.getGroupMessages(
                    payload.dialog_id,
                    payload.beforeMessageId,
                    payload.afterMessageId,
                    payload.limit,
                    privateCode,
                    payload.aroundMessageId
                );
            });
        } else {
            response = yield call(() => {
                return API.messages.get(
                    payload.dialog_id,
                    payload.beforeMessageId,
                    payload.afterMessageId,
                    payload.limit,
                    privateCode,
                    payload.aroundMessageId
                );
            });
        }

        if (response.success) {
            if (payload.afterMessageId) {
                yield put({
                    type: LOAD_NEW_MESSAGES_SUCCESS,
                    payload: {
                        items: response.data.messages,
                        activeUsers: response.data.activeUsers,
                    },
                });
                if (response.data.messages && response.data.messages.length) {
                    let callChat = false; // currentChat.data.closed
                    for (const message of response.data.messages) {
                        if (message.type !== 'text' && message.type !== 'autotext') {
                            callChat = true;
                        }
                    }
                    if (callChat) {
                        yield put({
                            type: CHAT_INFO,
                            payload: {
                                dialog_id: payload.dialog_id,
                                realtimeAction: payload.realtimeAction,
                            },
                        });
                    }
                }
            } else if (payload.beforeMessageId) {
                yield put({
                    type: LOAD_OLD_MESSAGES_SUCCESS,
                    payload: {
                        items: response.data.messages,
                    },
                });
            } else if (payload.aroundMessageId) {
                yield put({
                    type: LOAD_AROUND_MESSAGES_SUCCESS,
                    payload: {
                        items: response.data.messages,
                    },
                });
            } else {
                yield put({
                    type: MESSAGES_INFO_SUCCESS,
                    payload: {
                        items: response.data.messages,
                        activeUsers: response.data.activeUsers,
                        total: response.data.total,
                        pinnedMessages: response.data.pinned_messages,
                    },
                });
            }
            if (!payload.noResetForNewMessages) {
                yield put({
                    type: RESET_DIALOG_NEW_MESSAGES,
                    payload: {
                        dialog_id: payload.dialog_id,
                    },
                });
            }
            if (payload.func) {
                payload.func(response.data);
            }

            // here for now, we must renew our counter in the header, which available only for prospects for now
            if (accountInfo && accountInfo.key === 'prospect' && !payload.noResetForNewMessages) {
                yield put({
                    type: NEW_MESSAGES_COUNTER,
                });
            }
            if (action.onFinish) action.onFinish();
        } else {
            yield put({
                type: MESSAGES_INFO_FAILED,
            });
            if (action.onFinish) action.onFinish(false, false);
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: MESSAGES_INFO_FAILED,
        });
        if (action.onFinish) action.onFinish(false, true);
    }
}

export function* send(action) {
    const payload = action.payload;
    const currentChatInfo = yield select(getCurrentChat);

    try {
        const messages = yield select(getMessages);
        let privateCode = null;
        if (payload.privateCode) {
            privateCode = payload.privateCode;
        } else if (currentChatInfo.privateCode && currentChatInfo.privateCodeDialogId === +payload.dialog_id) {
            privateCode = currentChatInfo.privateCode;
        }
        const response = yield call(() => {
            return API.messages.send(
                payload.type,
                payload.text,
                payload.dialog_id,
                payload.parentMessageId,
                privateCode
            );
        });

        if (response.success) {
            yield put({
                type: SEND_MESSAGE_SUCCESS,
            });

            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    loaderDisabled: !!payload.loaderDisabled,
                    lastMessageId:
                        messages.items && messages.items.length && messages.items[messages.items.length - 1].id
                            ? messages.items[messages.items.length - 1].id
                            : null,
                },
            });
            if (action.onSuccess) action.onSuccess();
        } else {
            yield put({
                type: SEND_MESSAGE_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: SEND_MESSAGE_FAILED,
        });
    }
}

export function* sendAmbassadorMessage(action) {
    const payload = action.payload;

    try {
        const messages = yield select(getMessages);
        const response = yield call(() => {
            return API.messages.sendAmbassadorMessage(
                payload.type,
                payload.text,
                payload.dialog_id,
                payload.parentMessageId
            );
        });

        if (response.success) {
            yield put({
                type: SEND_MESSAGE_SUCCESS,
            });

            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    ambassadorMessages: true,
                    lastMessageId:
                        messages.items && messages.items.length && messages.items[messages.items.length - 1].id
                            ? messages.items[messages.items.length - 1].id
                            : null,
                    loaderDisabled: !!payload.loaderDisabled,
                },
            });
            if (response.data.timeIsUp) {
                yield put({
                    type: SET_CHAT_PERMISSION,
                    payload: {
                        isAllowedToChat: false,
                        timeLimitReached: true,
                    },
                });
            }
            if (action.onSuccess) action.onSuccess();
        } else {
            yield put({
                type: SEND_MESSAGE_FAILED,
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: SEND_MESSAGE_FAILED,
        });
    }
}

export function* reportMessage(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.messages.reportMessage(payload);
        });

        if (response.success) {
            yield put({
                type: DISPLAY_REPORT_POPUP,
                payload: false,
            });

            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    dialog_type: 4,
                    loaderDisabled: true,
                },
            });
            payload.onSuccess(response);
        }
    } catch (e) {
        console.log(e);
    }
}
