import { POST_INFO_SUCCESS, POST_INFO_FAIL, POST_LIKED_SUCCESS } from '../actions/postActions';

const initialState = {
    item: null,
    isLoading: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case POST_INFO_SUCCESS:
            return {
                ...state,
                item: payload.post,
                isLoading: false,
            };

        case POST_INFO_FAIL:
            return {
                ...state,
                item: null,
                isLoading: false,
            };

        case POST_LIKED_SUCCESS:
            let item = state.item ? { ...state.item } : null;
            item.like_count = payload.unlike ? (item.like_count > 0 ? item.like_count - 1 : 0) : item.like_count + 1;
            return {
                ...state,
                item: item,
            };

        default:
            return state;
    }
}
