export const GET_NEXT_ITEMS = 'GET_NEXT_ITEMS';
export const GET_NEXT_ITEMS_SUCCESS = 'GET_NEXT_ITEMS_SUCCESS';
export const GET_NEXT_ITEMS_FAIL = 'GET_NEXT_ITEMS_FAIL';

export const LIKED_NEXT_ITEMS_SUCCESS = 'LIKED_NEXT_ITEMS_SUCCESS';

export const RESET_INFO = 'RESET_INFO';

export const fetchData = (options) => ({
    type: GET_NEXT_ITEMS,
    payload: { ...options },
});

export const resetInfo = () => ({
    type: RESET_INFO,
});
