export const GET_LIVE_STREAMS = 'GET_LIVE_STREAMS';
export const GET_LIVE_STREAMS_SUCCESS = 'GET_LIVE_STREAMS_SUCCESS';
export const GET_LIVE_STREAMS_FAIL = 'GET_LIVE_STREAMS_FAIL';

export const LOAD_NEXT_LIVE_STREAMS = 'LOAD_NEXT_LIVE_STREAMS';
export const LOAD_NEXT_LIVE_STREAMS_SUCCESS = 'LOAD_NEXT_LIVE_STREAMS_SUCCESS';

export const SET_STREAM_UNI_DATA = 'SET_STREAM_UNI_DATA';

export const SET_REGISTRATION_STREAM = 'SET_REGISTRATION_STREAM';

export const GET_LIVE_STREAM_BY_SLUG = 'GET_LIVE_STREAM_BY_SLUG';

export const RESET_LIVE_STREAM = 'RESET_LIVE_STREAM';
export const ADD_LIVE_STREAM_PROSPECT_ID = 'ADD_LIVE_STREAM_PROSPECT_ID';
