import {
    OPEN_REGISTRATION_POPUP,
    CLOSE_REGISTRATION_POPUP,
    NEXT_REGISTRATION_STEP,
    SET_FIRST_MESSAGE,
    REMOVE_FIRST_MESSAGE,
    ACCOUNT,
} from '../../actions/accountActions';

import {
    SET_DATA_REGISTER_MODAL,
    REMOVE_DATA_REGISTER_MODAL,
    SET_MULTI_DATA_REGISTER_MODAL,
} from '../../actions/componentsActions';

const initialState = {
    isOpened: false,
    step: 1,
    data: {
        1: {},
        2: {},
    },
    firstMessage: null,
    university: null,
    id: null,
    name: '',
    type: 'chat',
    slug: null,
    subType: null,
    userId: null,
};

export default function (state = initialState, action) {
    const { type, payload = {} } = action;

    switch (type) {
        case OPEN_REGISTRATION_POPUP: {
            const { university, id, name, type, slug, subType, userId } = payload || {};
            return {
                ...state,
                isOpened: true,
                university,
                id,
                name,
                type: type || 'chat',
                slug,
                subType,
                userId,
            };
        }

        case ACCOUNT.SET_REGISTRATION_DATA: {
            const { university, id, name, type, slug, subType, userId } = payload || {};
            return {
                ...state,
                university,
                id,
                name,
                type: type || 'chat',
                slug,
                subType,
                userId,
            };
        }

        case CLOSE_REGISTRATION_POPUP: {
            return {
                ...state,
                isOpened: false,
            };
        }

        case NEXT_REGISTRATION_STEP: {
            return {
                ...state,
                step: payload.step,
            };
        }

        case SET_DATA_REGISTER_MODAL: {
            const { step = 1, key, val } = payload;

            const newData = {
                ...state.data,
                [step]: {
                    ...state.data[step],
                    [key]: val,
                },
            };

            return {
                ...state,
                data: newData,
            };
        }

        case SET_MULTI_DATA_REGISTER_MODAL: {
            const data = { ...state.data };
            for (const key in payload.userInfo) {
                if (payload.userInfo[key]) {
                    if (key === 'email' || key === 'first_name' || key === 'last_name') {
                        data[1][key] = payload.userInfo[key];
                    } else if (key === 'nationality' || key === 'residence_country') {
                        data[2][key] = payload.userInfo[key];
                    }
                }
            }

            return {
                ...state,
                data,
            };
        }

        case SET_FIRST_MESSAGE: {
            return {
                ...state,
                firstMessage: payload,
            };
        }

        case REMOVE_FIRST_MESSAGE: {
            return {
                ...state,
                firstMessage: null,
            };
        }

        case REMOVE_DATA_REGISTER_MODAL: {
            return {
                ...state,
                isOpened: false,
                step: 1,
                data: {
                    1: {},
                    2: {},
                },
                university: null,
                id: null,
                name: '',
                type: 'chat',
            };
        }

        default:
            return state;
    }
}
