import API from '../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
    GET_FILTER_OPTIONS_FAIL,
    GET_FILTER_OPTIONS_SUCCESS,
    GET_FILTER_OPTIONS_REQUEST_LOADING,
} from '../../actions/filtersActions';

import { GROUP_IDS } from '../../..//common/accountIds';

const getCurrentUniversityId = (state) => state.widget.currentUniversityId;
const getGroupUniversityId = (state) => state.widget.groupUniversityId;

export function* fetchFilterOptions(action) {
    const payload = action.payload;

    try {
        yield put({
            type: GET_FILTER_OPTIONS_REQUEST_LOADING,
            payload: {
                options: {
                    isLoading: true,
                },
            },
        });

        let response = null;
        const groupUniversityId = yield select(getGroupUniversityId);
        const universityId = yield select(getCurrentUniversityId);
        if (universityId) {
            payload.universityId = universityId;
        } else {
            payload.groupId = groupUniversityId;
        }

        switch (payload.fixed_type) {
            case 'group_universities': {
                response = yield call(() => {
                    return API.filters.fetchFilterOptionsGroupUniversities(groupUniversityId);
                });
                if (response.data) {
                    response.data.tags = response.data.universities
                        ? response.data.universities.map((university) => {
                              return {
                                  ...university,
                                  university: true,
                              };
                          })
                        : [];
                }
                break;
            }
            case 'university_countries': {
                response = yield call(() => {
                    return API.filters.fetchGroupCountries(groupUniversityId);
                });
                if (response.data) {
                    response.data.tags = response.data.countries || [];
                }
                break;
            }
            case '_countries': {
                const { sort, order, ...restPayload } = payload;
                if (groupUniversityId && groupUniversityId === GROUP_IDS.QEEENS_UNI_BELFAST) {
                    response = yield call(() => {
                        return API.filters.getCountries(restPayload);
                    });
                } else {
                    response = yield call(() => {
                        return API.filters.getCountries(payload);
                    });
                }

                if (response.data) {
                    if (groupUniversityId && GROUP_IDS.QEEENS_UNI_BELFAST) {
                        const arrOfCountries = response.data.countries.map((item) => {
                            item.disableSorting = true;
                            return item;
                        });

                        response.data.countries = arrOfCountries;
                        response.data.tags = arrOfCountries || [];
                    } else {
                        response.data.tags = response.data.countries || [];
                    }
                }
                break;
            }
            case 'account_type': {
                response = yield call(() => {
                    return API.filters.getProfileTypes(payload);
                });
                if (response.data) {
                    response.data.tags = response.data.profileTypes || [];
                }
                break;
            }
            case '_subject': {
                response = yield call(() => {
                    return API.filters.getSubjects(payload);
                });
                if (response.data) {
                    response.data.tags = response.data.subjects || [];
                }
                break;
            }

            default: {
                response = yield call(() => {
                    return API.filters.fetchFilterOptions(payload);
                });
            }
        }

        if (response.success) {
            // why and when we need the search?? I think we not using it and commenting for now
            /* if (payload.search && payload.search.name) {
                yield put({
                    type: GET_FILTER_NEW_OPTIONS_SUCCESS,
                    payload: {
                        options: {
                            [payload.fixed_type]: response.data,
                            isLoading: false
                        }
                    }
                });
            } else {*/
            yield put({
                type: GET_FILTER_OPTIONS_SUCCESS,
                payload: {
                    options: {
                        [payload.fixed_type]: response.data,
                        isLoading: false,
                        initial: payload.initial,
                    },
                },
            });
        } else {
            yield put({
                type: GET_FILTER_OPTIONS_FAIL,
                payload: {
                    options: {
                        isLoading: false,
                    },
                },
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: GET_FILTER_OPTIONS_FAIL,
            payload: {
                options: {
                    isLoading: true,
                },
            },
        });
    }
}
