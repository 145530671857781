export const STUDENT_INFO = 'STUDENT_INFO';
export const STUDENT_INFO_SUCCESS = 'STUDENT_INFO_SUCCESS';
export const STUDENT_INFO_FAIL = 'STUDENT_INFO_FAIL';

export const UNIVERSITY_DATA = 'UNIVERSITY_DATA';

export const STUDENT_POST_LIKED = 'STUDENT_POST_LIKED';
export const STUDENT_POST_LIKED_SUCCESS = 'STUDENT_POST_LIKED_SUCCESS';

export const STUDENT_FAQ_ANSWER_LIKED = 'STUDENT_FAQ_ANSWER_LIKED';
export const STUDENT_FAQ_ANSWER_LIKED_SUCCESS = 'STUDENT_FAQ_ANSWER_LIKED_SUCCESS';
