function getNavigatorInstance() {
    if (typeof window !== 'undefined') {
        if (window.navigator || navigator) {
            return window.navigator || navigator;
        }
    }
    return null;
}
function isMobileUA() {
    var toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    return toMatch.some(function (toMatchItem) {
        return navigator.userAgent.match(toMatchItem);
    });
}
function isMobileType() {
    var navigatorInstance = getNavigatorInstance();
    if (navigatorInstance && navigatorInstance.userAgentData) {
        return navigatorInstance.userAgentData.mobile;
    }
    return false;
}
export var isMobile = isMobileType() || isMobileUA();
