import LiveStreamModel from '../../models/LiveStreamModel';
import OnDemandStreamModel from '../../models/OnDemandStreamModel';
import {
    ADD_LIVE_STREAM_PROSPECT,
    ARCHIVE_LIVE_STREAM,
    CHANGE_CHATS_TAB,
    CHAT_INFO,
    GET_LIVE_STREAM_PROSPECTS_SUCCESS,
    GET_LIVE_STREAM_SUCCESS,
    GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS,
    RESET_LIVE_STREAM,
    SET_ACTIVE_TAB,
    UPDATE_LIVE_STREAM_STATUS,
    LIVE_STREAM,
} from '../actions/currentStreamActions';

import { SHOW_OPTIONAL_QUESTIONS } from '../actions/messagesActions';

const eventStatusUpdate = (item, payload) => ({
    ...item,
    is_chat_active: typeof payload.eventStatus !== 'undefined' ? payload.isChatActive : item.is_chat_active,
    status: typeof payload.eventStatus !== 'undefined' ? payload.eventStatus : item.status,
    statusName:
        typeof payload.eventStatus !== 'undefined' ? payload.eventStatus.replace(/([A-Z])/g, ' $1') : item.statusName,
    is_stream_active: typeof payload.isStreamActive !== 'undefined' ? payload.isStreamActive : item.is_stream_active,
});

const initialState = {
    data: null,
    chatData: {},
    activeTab: 'liveChat',
    chatsType: null,
    tempActiveChat: null, // only for case when we are going back from settings page
    hasAccepted: false,
    userMessagesExist: false,
    showOptionalQuestions: true,
    universityQuestionsAnswered: false,
    inviteUsersIsOpened: false,
    isFirstLoading: true,
    prospects: [],
    university: null,
    isArchived: false,
    viewerRoomUrl: '',
    hostRoomUrl: '',
    streamIframlessModal: {
        isOpened: false,
    },
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case GET_LIVE_STREAM_SUCCESS: {
            const { status, hostRoomUrl, viewerRoomUrl } = payload.data;
            return {
                ...state,
                data:
                    status === 'onDemand' || status === 'completed'
                        ? OnDemandStreamModel(payload.data)
                        : LiveStreamModel(payload.data),
                universityQuestionsAnswered: payload.universityQuestionsAnswered,
                hasAccepted: payload.universityQuestionsAnswered,
                viewerRoomUrl: payload.blockStreamRefresh ? state.viewerRoomUrl : viewerRoomUrl,
                hostRoomUrl: payload.blockStreamRefresh ? state.hostRoomUrl : hostRoomUrl,
            };
        }

        case CHAT_INFO: {
            return {
                ...state,
                isFirstLoading: !!payload.firstLoad,
            };
        }

        case CHANGE_CHATS_TAB: {
            return {
                ...state,
                chatData: {},
                chatsType: payload.chats_type,
                tempActiveChat: !payload.removeTempActiveChat ? state.tempActiveChat : null,
                ambassador: {},
                university: null,
                hasAccepted: false,
                userMessagesExist: false,
                isFirstLoading: payload.isFirstLoading ? payload.isFirstLoading : state.isFirstLoading,
            };
        }

        case GET_LIVE_STREAM_PROSPECTS_SUCCESS: {
            return {
                ...state,
                prospects: payload.items,
            };
        }

        case ADD_LIVE_STREAM_PROSPECT: {
            return {
                ...state,
                prospects: state.prospects.some((prospect) => prospect.id !== payload.prospect.id)
                    ? [...state.prospects, payload.prospect]
                    : state.prospects,
            };
        }

        case GET_NEXT_LIVE_STREAM_PROSPECTS_SUCCESS: {
            return {
                ...state,
                prospects: [...state.prospects, ...payload.items],
            };
        }

        case SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: payload.activeTab,
            };
        }

        case UPDATE_LIVE_STREAM_STATUS: {
            return {
                ...state,

                data: eventStatusUpdate(state.data, payload),
            };
        }

        case ARCHIVE_LIVE_STREAM: {
            return {
                ...state,
                isArchived: payload.isArchived,
            };
        }

        case RESET_LIVE_STREAM: {
            return {
                ...initialState,
            };
        }

        case SHOW_OPTIONAL_QUESTIONS: {
            return {
                ...state,
                showOptionalQuestions: payload.showOptionalQuestions,
                universityQuestionsAnswered: !!payload.universityQuestionsAnswered,
            };
        }

        case LIVE_STREAM.OPEN_STREAM_IFRAMLESS_MODAL: {
            return {
                ...state,
                streamIframlessModal: {
                    ...state.streamIframlessModal,
                    isOpened: true,
                },
            };
        }

        case LIVE_STREAM.CLOSE_STREAM_IFRAMLESS_MODAL: {
            return {
                ...state,
                streamIframlessModal: initialState.streamIframlessModal,
            };
        }

        default: {
            return state;
        }
    }
}
