const NAMESPACE_PREFIX = 'ACCOUNT';

export const REGISTRATION = 'REGISTRATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';

export const START_REGISTRATION = 'START_REGISTRATION';
export const OPEN_REGISTRATION_POPUP = 'OPEN_REGISTRATION_POPUP';
export const CLOSE_REGISTRATION_POPUP = 'CLOSE_REGISTRATION_POPUP';

export const NEXT_REGISTRATION_STEP = 'NEXT_REGISTRATION_STEP';

export const OPEN_LOGIN_POPUP = 'OPEN_LOGIN_POPUP';
export const CLOSE_LOGIN_POPUP = 'CLOSE_LOGIN_POPUP';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SSO_LOGIN = 'SSO_LOGIN';

export const SSO_LOGIN_AUTH = 'SSO_LOGIN_AUTH';
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS';
export const SSO_LOGIN_FAILED = 'SSO_LOGIN_FAILED';

export const TIK_TOK_CALLBACK = 'TIK_TOK_CALLBACK';

export const TIK_TOK_REGISTRATION = 'TIK_TOK_REGISTRATION';

export const TIK_TOK_LOGIN = 'TIK_TOK_LOGIN';
export const TIK_TOK_LOGIN_SUCCESS = 'TIK_TOK_LOGIN_SUCCESS';
export const TIK_TOK_LOGIN_FAILED = 'TIK_TOK_LOGIN_FAILED';

export const SOCIAL_MEDIA_LOGIN = 'SOCIAL_MEDIA_LOGIN';
export const SOCIAL_MEDIA_LOGIN_SUCCESS = 'SOCIAL_MEDIA_LOGIN_SUCCESS';
export const SOCIAL_MEDIA_LOGIN_FAILED = 'SOCIAL_MEDIA_LOGIN_FAILED';

export const SOCIAL_MEDIA_REGISTRATION = 'SOCIAL_MEDIA_REGISTRATION';
export const SOCIAL_MEDIA_REGISTRATION_SUCCESS = 'SOCIAL_MEDIA_REGISTRATION_SUCCESS';
export const SOCIAL_MEDIA_REGISTRATION_FAILED = 'SOCIAL_MEDIA_REGISTRATION_FAILED';

export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const ACCOUNT_INFO_SUCCESS = 'ACCOUNT_INFO_SUCCESS';
export const ACCOUNT_INFO_FAILED = 'ACCOUNT_INFO_FAILED';

export const AUTH_BY_CODE = 'AUTH_BY_CODE';
export const AUTH_BY_EXTERNAL_SPEAKER_CODE = 'AUTH_BY_EXTERNAL_SPEAKER_CODE';

export const UNIVERSITY_QUESTIONS = 'UNIVERSITY_QUESTIONS';
export const UNIVERSITY_QUESTIONS_SUCCESS = 'UNIVERSITY_QUESTIONS_SUCCESS';
export const UNIVERSITY_QUESTIONS_FAILED = 'UNIVERSITY_QUESTIONS_FAILED';

export const SAVE_UNIVERSITY_QUESTIONS = 'SAVE_UNIVERSITY_QUESTIONS';
export const SAVE_UNIVERSITY_QUESTIONS_SUCCESS = 'SAVE_UNIVERSITY_QUESTIONS_SUCCESS';
export const SAVE_UNIVERSITY_QUESTIONS_FAILED = 'SAVE_UNIVERSITY_QUESTIONS_FAILED';

export const MANDATORY_QUESTIONS = 'MANDATORY_QUESTIONS';
export const MANDATORY_QUESTIONS_SUCCESS = 'MANDATORY_QUESTIONS_SUCCESS';
export const MANDATORY_QUESTIONS_FAILED = 'MANDATORY_QUESTIONS_FAILED';

export const SAVE_MANDATORY_QUESTIONS = 'SAVE_MANDATORY_QUESTIONS';
export const SAVE_MANDATORY_QUESTIONS_SUCCESS = 'SAVE_MANDATORY_QUESTIONS_SUCCESS';
export const SAVE_MANDATORY_QUESTIONS_FAILED = 'SAVE_MANDATORY_QUESTIONS_FAILED';

export const SAVE_UNIVERSITY_USER_UTM_DATA = 'SAVE_UNIVERSITY_USER_UTM_DATA';

export const UNIVERSITY_QUESTIONS_DATA = 'UNIVERSITY_QUESTIONS_DATA';
export const UNIVERSITY_QUESTIONS_DATA_SUCCESS = 'UNIVERSITY_QUESTIONS_DATA_SUCCESS';

export const GET_USER_COUNTRY = 'GET_USER_COUNTRY';
export const GET_USER_COUNTRY_SUCCESS = 'GET_USER_COUNTRY_SUCCESS';
export const GET_USER_COUNTRY_FAILED = 'GET_USER_COUNTRY_FAILED';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const GET_NATIONALITIES = 'GET_NATIONALITIES';
export const GET_NATIONALITIES_SUCCESS = 'GET_NATIONALITIES_SUCCESS';
export const GET_NATIONALITIES_FAILED = 'GET_NATIONALITIES_FAILED';

export const ACCOUNT_CHANGE_AVATAR = 'ACCOUNT_CHANGE_AVATAR';
export const ACCOUNT_CHANGE_AVATAR_SUCCESS = 'ACCOUNT_CHANGE_AVATAR_SUCCESS';

export const CHANGE_MAILING = 'CHANGE_MAILING';

export const SET_FIRST_MESSAGE = 'SET_FIRST_MESSAGE';
export const REMOVE_FIRST_MESSAGE = 'REMOVE_FIRST_MESSAGE';

export const OPEN_NOTIFICATION_METHOD_POPUP = 'OPEN_NOTIFICATION_METHOD_POPUP';
export const CLOSE_NOTIFICATION_METHOD_POPUP = 'CLOSE_NOTIFICATION_METHOD_POPUP';
export const NOTIFICATION_METHOD_POPUP_STEP = 'NOTIFICATION_METHOD_POPUP_STEP';

export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT';

export const START_RECOVERY_PASSWORD = 'START_RECOVERY_PASSWORD';
export const END_RECOVERY_PASSWORD = 'END_RECOVERY_PASSWORD';

export const SET_CHAT_PERMISSION = 'SET_CHAT_PERMISSION';

export const MOB_APP_AUTH = 'MOB_APP_AUTH';
export const EXTERNAL_SPEAKER_AUTH = 'EXTERNAL_SPEAKER_AUTH';
export const SET_SLUG_FROM_URL = 'SET_SLUG_FROM_URL';

export const ACCOUNT = {
    SET_MAINTENANCE_MODE: `${NAMESPACE_PREFIX}.SET_MAINTENANCE_MODE`,
    GET_UNIVERSITY_SSO_DATA: `${NAMESPACE_PREFIX}.GET_UNIVERSITY_SSO_DATA`,
    OPEN_ACCEPT_TERMS_POPUP: `${NAMESPACE_PREFIX}.OPEN_ACCEPT_TERMS_POPUP`,
    CLOSE_ACCEPT_TERMS_POPUP: `${NAMESPACE_PREFIX}.CLOSE_ACCEPT_TERMS_POPUP`,
    RESET_QUESTIONS: `${NAMESPACE_PREFIX}.RESET_QUESTIONS`,
    FIRE_ON_ACCEPTED_TERMS: `${NAMESPACE_PREFIX}.FIRE_ON_ACCEPTED_TERMS`,
    SAVE_ACCEPTED_TERMS: `${NAMESPACE_PREFIX}.SAVE_ACCEPTED_TERMS`,
    GET_EXPERIENCE_BASED_QUESTIONS: `${NAMESPACE_PREFIX}.GET_EXPERIENCE_BASED_QUESTIONS`,
    GET_EXPERIENCE_BASED_QUESTIONS_SUCCESS: `${NAMESPACE_PREFIX}.GET_EXPERIENCE_BASED_QUESTIONS_SUCCESS`,
    OPEN_EXPERIENCE_BASED_QUESTIONS_POPUP: `${NAMESPACE_PREFIX}.OPEN_EXPERIENCE_BASED_QUESTIONS_POPUP`,
    CLOSE_EXPERIENCE_BASED_QUESTIONS_POPUP: `${NAMESPACE_PREFIX}.CLOSE_EXPERIENCE_BASED_QUESTIONS_POPUP`,
    OPEN_OTP_VERIFICATION_POPUP: `${NAMESPACE_PREFIX}.OPEN_OTP_VERIFICATION_POPUP`,
    CLOSE_OTP_VERIFICATION_POPUP: `${NAMESPACE_PREFIX}.CLOSE_OTP_VERIFICATION_POPUP`,
    SAVE_EXPERIENCE_BASED_QUESTION: `${NAMESPACE_PREFIX}.SAVE_EXPERIENCE_BASED_QUESTION`,
    SKIP_EXPERIENCE_BASED_QUESTION: `${NAMESPACE_PREFIX}.SKIP_EXPERIENCE_BASED_QUESTION`,
    SET_REGISTRATION_DATA: `${NAMESPACE_PREFIX}.SET_REGISTRATION_DATA`,
    OPEN_MOBILE_APP_PROMOTION_POPUP: `${NAMESPACE_PREFIX}.OPEN_MOBILE_APP_PROMOTION_POPUP`,
    CLOSE_MOBILE_APP_PROMOTION_POPUP: `${NAMESPACE_PREFIX}.CLOSE_MOBILE_APP_PROMOTION_POPUP`,
};

export const getAccountInfo = (payload = {}, meta = {}) => ({
    type: ACCOUNT_INFO,
    payload: payload || {},
    meta: meta || {},
});

export const getUniversitySsoData = (email, universityId, onSuccess = () => {}, onFailed = () => {}) => ({
    type: ACCOUNT.GET_UNIVERSITY_SSO_DATA,
    payload: { email, universityId },
    meta: { onSuccess, onFailed },
});

export const getExperienceBasedQuestions = () => ({
    type: ACCOUNT.GET_EXPERIENCE_BASED_QUESTIONS,
});

export const startRegistration = (data = {}) => ({
    type: UNIVERSITY_QUESTIONS,
    payload: {
        universityId: data.university ? data.university.id : null,
        data,
        onlyMandatory: true,
    },
});

export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: {
        token,
        isLoading: false,
    },
});
