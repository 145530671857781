import { httpServices } from 'src/system/HttpServices/HttpService';
import pusher from '../../system/services/PusherWrapper';
export default {
    send: function (data) {
        return httpServices.post({
            path: "/dialogs/".concat(data.dialogId, "/messages/prospect/send"),
            data: {
                type: data.type,
                text: data.text,
                parentMessageId: data.parentMessageId,
                socketId: pusher.connection.socket_id,
                privateCode: data.privateCode,
            },
            requestType: 'interaction',
        });
    },
};
