export const CLOSE_ACTION_MODAL = 'CLOSE_ACTION_MODAL';
export const OPEN_ACTION_MODAL = 'OPEN_ACTION_MODAL';

export const CLOSE_ACTION_MODAL_CHILD = 'CLOSE_ACTION_MODAL_CHILD';
export const OPEN_ACTION_MODAL_CHILD = 'OPEN_ACTION_MODAL_CHILD';

export const SET_DATA_REGISTER_MODAL = 'SET_DATA_REGISTER_MODAL';
export const SET_MULTI_DATA_REGISTER_MODAL = 'SET_MULTI_DATA_REGISTER_MODAL';

export const REMOVE_DATA_REGISTER_MODAL = 'REMOVE_DATA_REGISTER_MODAL';

export const NEW_MESSAGES_RECEIVED = 'NEW_MESSAGES_RECEIVED';
export const HIDE_NEW_MESSAGES = 'HIDE_NEW_MESSAGES';
