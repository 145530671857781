var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LOAD_NEXT_POSTS, LOAD_NEXT_POSTS_SUCCESS, POSTS_INFO, POSTS_INFO_FAIL, POSTS_INFO_SUCCESS, POSTS_LIKED_SUCCESS, REFRESH_POSTS_DATA, } from '../actions/postsActions';
var elementsToLoad = window.innerWidth > 1300
    ? 8
    : window.innerWidth > 900 && window.innerWidth <= 1300
        ? 9
        : window.innerWidth > 650 && window.innerWidth <= 900
            ? 6
            : 4;
var initialState = {
    items: null,
    ids: [],
    limit: elementsToLoad,
    offset: 0,
    total: 0,
    isLoading: true,
    nextLoading: false,
    refreshPostsData: true,
};
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type;
    var payload = action.payload;
    switch (type) {
        case POSTS_INFO:
            payload.limit = state.limit;
            payload.offset = 0;
            return state;
        case POSTS_INFO_SUCCESS: {
            var postsIds = [];
            if (payload.posts && payload.posts.length) {
                for (var _i = 0, _a = payload.posts; _i < _a.length; _i++) {
                    var post = _a[_i];
                    postsIds.push(post.id);
                }
            }
            return __assign(__assign({}, state), { items: payload.posts, ids: postsIds, isLoading: false, nextLoading: false, offset: payload.offset, total: payload.total });
        }
        case POSTS_INFO_FAIL:
            return __assign(__assign({}, state), { isLoading: false, nextLoading: false });
        case LOAD_NEXT_POSTS:
            payload.ids = __spreadArray([], state.ids, true);
            payload.limit = state.limit;
            payload.offset = state.offset + state.limit;
            return __assign(__assign({}, state), { nextLoading: true });
        case LOAD_NEXT_POSTS_SUCCESS: {
            var ids = [];
            if (payload.posts && payload.posts.length) {
                for (var _b = 0, _c = payload.posts; _b < _c.length; _b++) {
                    var post = _c[_b];
                    ids.push(post.id);
                }
            }
            return __assign(__assign({}, state), { items: state.items ? state.items.concat(payload.posts) : payload.posts, ids: __spreadArray(__spreadArray([], state.ids, true), ids, true), offset: payload.offset, total: payload.total, isLoading: false, nextLoading: false });
        }
        case POSTS_LIKED_SUCCESS: {
            var posts = state.items ? __spreadArray([], state.items, true) : null;
            if (posts) {
                for (var post in posts) {
                    if (posts[post] && posts[post].id === payload.postId) {
                        var like_count = posts[post].like_count;
                        posts[post].like_count = payload.unlike
                            ? like_count > 0
                                ? like_count - 1
                                : 0
                            : like_count + 1;
                    }
                }
            }
            return __assign(__assign({}, state), { items: posts });
        }
        case REFRESH_POSTS_DATA:
            return __assign(__assign({}, state), { refreshPostsData: payload.refresh });
        default:
            return state;
    }
}
