export const GET_FILTER_OPTIONS_REQUEST = 'GET_FILTER_OPTIONS_REQUEST';
export const GET_FILTER_OPTIONS_SUCCESS = 'GET_FILTER_OPTIONS_SUCCESS';
export const GET_FILTER_NEW_OPTIONS_SUCCESS = 'GET_FILTER_NEW_OPTIONS_SUCCESS';
export const GET_FILTER_OPTIONS_FAIL = 'GET_FILTER_OPTIONS_FAIL';

export const GET_FILTER_OPTIONS_REQUEST_LOADING = 'GET_FILTER_OPTIONS_REQUEST_LOADING';

export const SET_FILTER_TERM = 'SET_FILTER_TERM';
export const MATCH_ME_FILTERS = 'MATCH_ME_FILTERS';
export const RETURN_PREV_FILTERS = 'RETURN_PREV_FILTERS';
export const REMOVE_FILTER_TERM = 'REMOVE_FILTER_TERM';
export const CLEAR_FILTER_TERMS = 'CLEAR_FILTER_TERMS';

export const fetchFilterOptions = (options) => ({
    type: GET_FILTER_OPTIONS_REQUEST,
    payload: options,
});

export const setFilterTerm = (term, matchMe) => ({
    type: SET_FILTER_TERM,
    payload: { item: term, matchMe },
});

export const removeFilterTerm = (term, matchMe) => ({
    type: REMOVE_FILTER_TERM,
    payload: {
        name: term,
        matchMe,
    },
});

export const clearFilterTerms = () => ({
    type: CLEAR_FILTER_TERMS,
});
