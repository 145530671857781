import Account from './account';
import Students from './chatToUs';
import Common from './common';
import CommunityAccount from './community/account';
import communityChat from './community/chat';
import communitySpaces from './community/spaces';
import communityUsers from './community/users';
import Dialogs from './dialogs';
import Faqs from './faq';
import Filters from './filters';
import GroupChats from './groupChats';
import LiveStreams from './liveStreams';
import Main from './main';
import Messages from './messages';
import MessagesNew from './messagesNew';

import application from './mobile';
import Posts from './post';
import Users from './users';
import Widget from './widget';

export default {
    groupChats: GroupChats,
    students: Students,
    main: Main,
    faqs: Faqs,
    posts: Posts,
    widget: Widget,
    filters: Filters,
    users: Users,
    dialogs: Dialogs,
    account: Account,
    messages: Messages,
    messagesNew: MessagesNew,
    LiveStreams: LiveStreams,
    community: {
        account: CommunityAccount,
        chat: communityChat,
        users: communityUsers,
        spaces: communitySpaces,
    },
    common: Common,
    application,
};
