import {
    LOAD_NEXT_STUDENTS,
    LOAD_NEXT_STUDENTS_SUCCESS,
    REFRESH_STUDENTS_DATA,
    STUDENTS_INFO,
    STUDENTS_INFO_FAIL,
    STUDENTS_INFO_SUCCESS,
} from '../actions/studentsActions';

const elementsToLoad =
    window.innerWidth > 1300
        ? 8
        : window.innerWidth > 900 && window.innerWidth <= 1300
          ? 9
          : window.innerWidth > 650 && window.innerWidth <= 900
            ? 6
            : 4;

const initialState = {
    items: null,
    ids: [],
    limit: elementsToLoad,
    offset: 0,
    isLoading: true,
    nextLoading: false,
    refreshStudentsData: true,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case STUDENTS_INFO:
            payload.limit = state.limit;
            payload.offset = 0;
            return state;

        case STUDENTS_INFO_SUCCESS:
            const studentsIds = [];
            if (payload.students && payload.students.length) {
                for (const student of payload.students) {
                    studentsIds.push(student.id);
                }
            }
            return {
                ...state,
                items: payload.students,
                ids: studentsIds,
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };

        case STUDENTS_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                nextLoading: false,
            };

        case LOAD_NEXT_STUDENTS:
            payload.ids = [...state.ids];
            payload.limit = state.limit;
            payload.offset = state.limit + state.offset;
            return {
                ...state,
                nextLoading: true,
            };

        case LOAD_NEXT_STUDENTS_SUCCESS:
            const ids = [];
            if (payload.students && payload.students.length) {
                for (const student of payload.students) {
                    ids.push(student.id);
                }
            }
            return {
                ...state,
                items: [...state.items, ...payload.students],
                ids: [...state.ids, ...ids],
                offset: payload.offset,
                total: payload.total,
                isLoading: false,
                nextLoading: false,
            };

        case REFRESH_STUDENTS_DATA:
            return {
                ...state,
                refreshStudentsData: payload,
            };

        default:
            return state;
    }
}
