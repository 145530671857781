export const GROUP_DIALOGS_INFO = 'GROUP_DIALOGS_INFO';
export const PROSPECT_DIALOGS_INFO = 'PROSPECT_DIALOGS_INFO';
export const AMBASSADOR_DIALOGS_INFO = 'AMBASSADOR_DIALOGS_INFO';
export const DIALOGS_INFO_SUCCESS = 'DIALOGS_INFO_SUCCESS';
export const DIALOGS_INFO_FAILED = 'DIALOGS_INFO_FAILED';

export const GET_SINGLE_GROUP_DIALOG_INFO = 'GET_SINGLE_GROUP_DIALOG_INFO';
export const GET_SINGLE_PROSPECT_DIALOG_INFO = 'GET_SINGLE_PROSPECT_DIALOG_INFO';
export const GET_SINGLE_AMBASSADOR_DIALOG_INFO = 'GET_SINGLE_AMBASSADOR_DIALOG_INFO';
export const GET_SINGLE_DIALOG_INFO_SUCCESS = 'GET_SINGLE_DIALOG_INFO_SUCCESS';

export const GET_DIALOGS_IDS = 'GET_DIALOGS_IDS';
export const GET_DIALOGS_IDS_SUCCESS = 'GET_DIALOGS_IDS_SUCCESS';
export const ADD_NEW_DIALOG_TO_IDS = 'ADD_NEW_DIALOG_TO_IDS';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CLOSE_DIALOG_SUCCESS = 'CLOSE_DIALOG_SUCCESS';
export const CLOSE_DIALOG_FAILED = 'CLOSE_DIALOG_FAILED';

export const CLOSE_AMBASSADOR_DIALOG = 'CLOSE_AMBASSADOR_DIALOG';

export const FLAG_DIALOG = 'FLAG_DIALOG';
export const FLAG_DIALOG_SUCCESS = 'FLAG_DIALOG_SUCCESS';
export const FLAG_DIALOG_FAILED = 'FLAG_DIALOG_FAILED';

export const FIND_OR_CREATE_DIALOG = 'FIND_OR_CREATE_DIALOG';
export const FIND_OR_CREATE_DIALOG_FAILED = 'FIND_OR_CREATE_DIALOG_FAILED';
export const FIND_OR_CREATE_DIALOG_SUCCESS = 'FIND_OR_CREATE_DIALOG_SUCCESS';

export const AFTER_DIALOG_REPORT = 'AFTER_DIALOG_REPORT';
export const AFTER_DIALOG_REPORT_FAILED = 'AFTER_DIALOG_REPORT_FAILED';
export const AFTER_DIALOG_REPORT_SUCCESS = 'AFTER_DIALOG_REPORT_SUCCESS';

export const CLEAR_DIALOGS_STORE = 'CLEAR_DIALOGS_STORE';

export const INVITE_USERS = 'INVITE_USERS';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const INVITE_USERS_FAILED = 'INVITE_USERS_FAILED';

export const RESET_DIALOG_NEW_MESSAGES = 'RESET_DIALOG_NEW_MESSAGES';

export const SEND_FEEDBACK = 'SEND_FEEDBACK';

export const LEAVE_CHAT = 'LEAVE_CHAT';
export const LEAVE_CHAT_SUCCESS = 'LEAVE_CHAT_SUCCESS';
export const DELETE_DIALOG = 'DELETE_DIALOG';
