import {
    MAIN_INFO,
    MAIN_INFO_FAIL,
    MAIN_INFO_SUCCESS,
    MAIN_INFO_LOADED,
    RESET_MAIN_INFO,
    SHOW_DATA,
} from '../actions/mainActions';
import { ADD_LIVE_STREAM_PROSPECT_ID } from '../actions/liveStreamsActions';

const initialState = {
    items: null,
    isLoading: true,
    appLoaded: false,
    liveStreams: {
        items: null,
        isLoading: true,
    },
    groupChats: {
        items: null,
        isLoading: true,
    },
    forumChats: {
        items: null,
        isLoading: true,
    },
    students: {
        items: null,
        isLoading: true,
    },
    posts: {
        items: null,
        isLoading: true,
    },
    faqs: {
        items: null,
        isLoading: true,
    },
    showFaqs: false,
    showPosts: false,
    showUsers: false,
    showGroupChats: false,
    showLiveStreams: false,
    showForum: false,
    onlyOneScreen: null,
    navigateTo: false,
};

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch (type) {
        case MAIN_INFO:
            return {
                ...state,
                appLoaded: false,
            };

        case MAIN_INFO_SUCCESS:
            let onlyOneScreen = state.onlyOneScreen;
            let disabledBtns = 0;
            let navigateTo = '';

            let showFaqs = state.showFaqs;
            if (payload.faqs && payload.faqs.length > 0) {
                showFaqs = true;
                disabledBtns++;
                navigateTo = '/faq';
            }

            let showPosts = state.showPosts;
            if (payload.posts && payload.posts.length > 0) {
                showPosts = true;
                disabledBtns++;
                navigateTo = '/posts';
            }

            let showUsers = state.showUsers;
            if (payload.users && payload.users.length > 0) {
                showUsers = true;
                disabledBtns++;
                navigateTo = '/chat-to-us';
            }

            let showGroupChats = state.showGroupChats;
            if (payload.groupChats && payload.groupChats.length > 0) {
                showGroupChats = true;
                disabledBtns++;
                navigateTo = '';
            }

            let showLiveStreams = state.showLiveStreams;
            if (payload.liveStreams && payload.liveStreams.length > 0) {
                showLiveStreams = true;
                disabledBtns++;
                navigateTo = '';
            }

            let showForum = state.showForum;
            if (payload.forum && payload.forum.length > 0) {
                showForum = true;
                disabledBtns++;
                navigateTo = '';
            }

            if (disabledBtns == 1) onlyOneScreen = true;
            if (!onlyOneScreen) navigateTo = false;

            return {
                ...state,
                showFaqs,
                showPosts,
                showUsers,
                showGroupChats,
                showLiveStreams,
                showForum,
                onlyOneScreen,
                navigateTo,
                liveStreams: {
                    items: payload.liveStreams,
                    isLoading: false,
                },
                groupChats: {
                    items: payload.groupChats,
                    isLoading: false,
                },
                forumChats: {
                    items: payload.forum,
                    isLoading: false,
                },
                students: {
                    items: payload.users,
                    isLoading: false,
                },
                posts: {
                    items: payload.posts.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    }),
                    isLoading: false,
                },
                faqs: {
                    items: payload.faqs,
                    isLoading: false,
                },
                appLoaded: true,
                appLoadedWithNoError: true,
            };

        case RESET_MAIN_INFO:
            return {
                ...state,
                showFaqs: false,
                showPosts: false,
                showUsers: false,
                showGroupChats: false,
                showLiveStreams: false,
                onlyOneScreen: false,
                navigateTo: false,
                liveStreams: {
                    items: [],
                    isLoading: false,
                },
                groupChats: {
                    items: [],
                    isLoading: false,
                },
                students: {
                    items: [],
                    isLoading: false,
                },
                posts: {
                    items: [],
                    isLoading: false,
                },
                faqs: {
                    items: [],
                    isLoading: false,
                },
                appLoadedWithNoError: false,
            };

        case MAIN_INFO_LOADED:
            return {
                ...state,
                appLoaded: true,
            };

        case MAIN_INFO_FAIL:
            return {
                ...state,
                appLoaded: true,
            };
        case SHOW_DATA:
            return {
                ...state,
                [payload.typeToShow]: true,
            };
        case ADD_LIVE_STREAM_PROSPECT_ID:
            const { liveStreamId, prospectId } = payload;
            return {
                ...state,
                liveStreams: {
                    items: state.liveStreams.items
                        ? state.liveStreams.items.map((item) => {
                              if (
                                  item.id === liveStreamId &&
                                  !item.registeredProspectIds.some((item) => item === prospectId)
                              ) {
                                  item.registeredProspectIds = [...item.registeredProspectIds, prospectId];
                              }
                              return item;
                          })
                        : null,
                },
            };
        default:
            return state;
    }
}
