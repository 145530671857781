export var getUserFingerprint = function () {
    var accountFingerprint = window.localStorage.getItem('accountFingerprint');
    var data = '';
    if (accountFingerprint) {
        data = accountFingerprint;
    }
    else {
        data = window.btoa(window.navigator.userAgent + Date.now());
        window.localStorage.setItem('accountFingerprint', data);
    }
    return data;
};
