import Axios from 'axios';
import { localStorageWrapper } from '../../helpers/storageFactory';
import config from '../config';
import Handler from './handler';

import { getUserFingerprint } from 'src/helpers/fingerprint';
import { getTokenType } from 'src/helpers/token';

import store from 'src/redux/store';

import { isCommunity } from 'src/helpers/is-community';
import { ACCOUNT } from 'src/redux/actions/accountActions';
import { WIDGET_LOAD } from 'src/redux/actions/widgetActions';

const defaults = {
    debug: true,
    clear_api_host: config.api_host,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

function omitEmpty(obj) {
    return Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
}

/**
 * @template T
 *
 * @param {boolean} [withCredentials]
 * @param {T} [data]
 * @param {FormData} [formData]
 * @returns {import('axios').AxiosRequestConfig<T>}
 */
export const getConfigData = (withCredentials, data, formData) => {
    const configData = {
        headers: { ...defaults.headers },
        withCredentials: !!withCredentials,
    };

    const tokenType = getTokenType();

    if (data) {
        // GET
        configData.headers['Content-Type'] = 'text/plain';
        configData.params = omitEmpty(data);
    }
    if (formData) {
        configData.headers['Content-Type'] = 'multipart/form-data';
    }

    const accessToken = localStorageWrapper.getItem(tokenType);

    if (accessToken) {
        configData.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
    }
    return configData;
};

export const getAccessToken = () => {
    const tokenType = getTokenType();
    const token = localStorageWrapper.getItem(tokenType);
    return token ? JSON.parse(token) : null;
};

export const refreshToken = async () => {
    const token = getAccessToken();
    const tokenType = getTokenType();

    if (token) {
        const response = await Axios.post(
            `${defaults.clear_api_host}/${isCommunity() ? 'community' : 'interactionSite'}/auth/refreshTokens`,
            { token, fingerprint: isCommunity() ? getUserFingerprint() : undefined },
            getConfigData(true)
        );

        if (response.data && response.data.data && response.data.data.token) {
            const newToken = response.data.data.token;
            localStorageWrapper.setItem(tokenType, JSON.stringify(newToken));
            return newToken;
        }
    }

    return null;
};

export const getOnThen = (response, handleError) => {
    if (!response.data.status && response.data.error) {
        if (handleError) {
            Handler.errorHandler(response);
        }
        return {
            success: false,
        };
    } else {
        return response.data;
    }
};

export const getOnCatch = (err, handleError, rethrow = false) => {
    console.log(err);
    if (handleError) {
        if (!Axios.isCancel(err)) {
            Handler.errorHandler(err);
            if (err.response && err.response.status === 503) {
                window.isMaintenanceMode = true;
            }
            if (rethrow) {
                throw err;
            }
            return err.response && err.response.data
                ? {
                      errorData: err.response.data,
                      success: false,
                  }
                : {
                      success: false,
                  };
        }
        if (rethrow) {
            throw err;
        }
        return {
            success: false,
        };
    } else {
        if (err.response.status === 503) {
            store.dispatch({
                type: ACCOUNT.SET_MAINTENANCE_MODE,
            });
            store.dispatch({
                type: WIDGET_LOAD,
            });
        }
        if (rethrow) {
            throw err;
        }
        return {
            cancelled: !!Axios.isCancel(err),
            success: false,
        };
    }
};

const types = {
    widget: '/widget',
    interaction: '/interactionSite',
    common: '/common',
    public: '/public',
    community: '/community',
    application: '/application',
};

/**
 *
 * @param {keyof typeof types} type
 * @returns {'/widget' | '/interactionSite' | '/common' | '/public' | '/community' | '/application'}
 */
export const getTypePrefix = (type) => {
    return types[type] || '/widget';
};
